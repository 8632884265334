import { connect } from 'react-redux';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Accordion from '../../../utils/accordion';
import MenuItem from '@material-ui/core/MenuItem';
import { bindActionCreators, Dispatch } from 'redux';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createNotification } from '../../../../store/notification/actions';

interface FilterProps {
    style?: any;
    OnFilter?: any;
    jobStatuses?: Array<string>;
    createNotification?: typeof createNotification;
}

interface FilterActionProps {
    OnFilter?: any;
    createNotification?: typeof createNotification;
}

const noEmpty = (value: any) => value !== undefined && value !== null;

const Validators: any = {
    jobStatuses: [noEmpty],
};

export class Filter extends Component<
    FilterProps,
    FilterProps,
    FilterActionProps
> {
    constructor(props: FilterProps) {
        super(props);

        this.state = { jobStatuses: [] };
    }
    OnChange = (event: any) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };

    OnFilter = () => {
        let filters: any = {};

        for (let key of Object.keys(this.state)) {
            if (Validators[key]) {
                let valid = true;

                for (let validation of Validators[key]) {
                    valid = validation((this.state as any)[key]);
                }

                if (!valid) {
                    continue;
                }

                filters[key] = (this.state as any)[key];
            }
        }

        this.props.OnFilter?.(filters);
    };

    render() {
        return (
            <Grid container style={this.props.style}>
                <Grid item xs={12}>
                    <Accordion
                        id="item-filter"
                        style={{ marginRight: '1px solid rgba(0, 0, 0, .125)' }}
                        summary={
                            <Typography variant="subtitle2">Filters</Typography>
                        }
                        details={
                            <Grid
                                container
                                spacing={1}
                                justify="space-between"
                                alignContent="flex-end"
                                style={{
                                    padding: '0 1rem 1rem 1rem',
                                }}
                            >
                                <Grid item xs={12} md={6} lg={4}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="jobStatuses_labal">
                                            Job Status
                                        </InputLabel>
                                        <Select
                                            multiple
                                            id="jobStatuses"
                                            name="jobStatuses"
                                            onChange={this.OnChange}
                                            labelId="jobStatuses_labal"
                                            value={this.state.jobStatuses}
                                        >
                                            <MenuItem value="COMPLETE">
                                                Complete
                                            </MenuItem>
                                            <MenuItem value="STATUS_UPDATE">
                                                Status Update
                                            </MenuItem>
                                            <MenuItem value="PROGRESSING">
                                                Progressing
                                            </MenuItem>
                                            <MenuItem value="ERROR">
                                                Error
                                            </MenuItem>
                                            <MenuItem value="SUBMITTED">
                                                Submitted
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        textAlign: 'right',
                                        marginTop: '1rem',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={this.OnFilter}
                                    >
                                        Filter
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state: any, props: any): FilterProps {
    return {
        OnFilter: props.OnFilter,
    };
}

function mapDispatchToProps(dispatch: Dispatch): FilterActionProps {
    return bindActionCreators({ createNotification }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
