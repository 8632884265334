import { connect } from "react-redux"
import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import DateFnsUtils from "@date-io/date-fns"
import { FixedSizeList } from "react-window"
import { AutoSizer } from "react-virtualized"
import { get } from "../../../../plugins/api"
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"
import Accordion from "../../../utils/accordion"
import Checkbox from "@material-ui/core/Checkbox"
import ListItem from "@material-ui/core/ListItem"
import { bindActionCreators, Dispatch } from "redux"
import DoneAllIcon from "@material-ui/icons/DoneAll"
import Typography from "@material-ui/core/Typography"
import InputLabel from "@material-ui/core/InputLabel"
import ClearAllIcon from "@material-ui/icons/ClearAll"
import { IconButton, Tooltip } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse/Collapse"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import CircularProgress from "@material-ui/core/CircularProgress"
import { createNotification } from "../../../../store/notification/actions"
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction"
import { User } from "../../../../store/user/reducer"

interface FilterProps {
	style?: any
	user?: User
	OnFilter?: any
	title?: string
	loading?: boolean
	description?: string
	selected?: any
	toggledStates?: any
	publishedAfter?: Date
	publishedBefore?: Date
	categories?: Array<any>
	markers?: Array<any>
	resource_status?: Array<number>
	selectedCategories?: Array<any>
	selectedMarkers?: Array<any>
	createNotification?: typeof createNotification
}

interface FilterActionProps {
	OnFilter?: any
	createNotification?: typeof createNotification
}

const noEmpty = (value: any) => value !== undefined && value !== null
const noEmptyString = (value: string) => noEmpty(value) && value !== ""

const noEmptyArray = (value: Array<any>) => noEmpty(value) && value.length !== 0

const Validators: any = {
	title: [noEmptyString],
	publishedAfter: [noEmpty],
	publishedBefore: [noEmpty],
	description: [noEmptyString],
	resource_status: [noEmptyArray],
	selectedMarkers: [noEmptyArray],
	selectedCategories: [noEmptyArray],
}

class Row extends React.Component<any, any, any> {
	shouldComponentUpdate() {
		return true
	}

	render() {
		return (
			<ListItem
				dense={this.props.denseMode}
				button
				onClick={() => {
					this.props.onClick(this.props.marker, true)
				}}
				style={this.props.style}
				key={this.props.marker.marker}
			>
				<Checkbox
					value={this.props.marker.marker}
					checked={
						this.props.data?.includes(this.props.marker.marker) ??
						false
					}
				/>
				<ListItemText primary={this.props.marker.name} />
			</ListItem>
		)
	}
}

export class Filter extends Component<
	FilterProps,
	FilterProps,
	FilterActionProps
> {
	constructor(props: FilterProps) {
		super(props)

		this.state = {
			toggledStates: {},
			selectedMarkers: [],
			selectedCategories: [],
		}
	}

	componentDidMount() {
		if (
			sessionStorage.getItem("categories") &&
			sessionStorage.getItem("markers")
		) {
			this.setState({
				...this.state,
				loading: false,
				categories: JSON.parse(
					sessionStorage.getItem("categories") ?? JSON.stringify([])
				),
				markers: JSON.parse(
					sessionStorage.getItem("markers") ?? JSON.stringify([])
				),
			})
		} else {
			Promise.all([
				get("/v1/categories"),
				get("/v1/feed/aggregation_channel_markers"),
			])
				.then(([categories, markers]: [Array<any>, Array<any>]) => {
					sessionStorage.setItem(
						"categories",
						JSON.stringify(categories)
					)

					sessionStorage.setItem("markers", JSON.stringify(markers))

					this.setState({
						...this.state,
						markers,
						categories,
						loading: false,
					})
				})
				.catch((e) => {
					this.props.createNotification?.({
						message: e.message ?? e,
						severity: "error",
					})
					// localStorage.setItem('redirect', window.location.pathname);
					// window.location.href = '/v1/auth/google';
				})
		}
	}

	EditSubCategories = (
		categoriess: Array<any>,
		parentId: number,
		add: boolean = true
	) => {
		let selected = this.state.selectedCategories

		for (let category of categoriess) {
			if (add) {
				if (selected?.indexOf(category.id) === -1) {
					selected.push(category.id)
				}

				if (selected?.indexOf(parentId) === -1) {
					selected.push(parentId)
				}
			} else {
				if (selected?.indexOf(category.id) !== -1) {
					selected?.splice(selected?.indexOf(category.id), 1)
				}

				if (selected?.indexOf(parentId) !== -1) {
					selected?.splice(selected?.indexOf(parentId), 1)
				}
			}
		}

		this.setState({
			selectedCategories: selected,
		})
	}

	ToggleOption = (option: any, marker: boolean = false) => {
		if (
			!(marker
				? this.state.selectedMarkers
				: this.state.selectedCategories)
		) {
			return
		}

		let selected = marker
			? this.state.selectedMarkers
			: this.state.selectedCategories

		let key = marker ? "marker" : "id"

		if (selected?.indexOf(option[key]) === -1) {
			selected.push(option[key])
		} else {
			selected?.splice(selected?.indexOf(option[key]), 1)
		}

		if (option.categories) {
			for (let subCategory of option.categories) {
				if (selected?.indexOf(subCategory[key]) === -1) {
					selected.push(subCategory[key])
				} else {
					selected?.splice(selected?.indexOf(subCategory[key]), 1)
				}
			}
		}

		this.setState({
			...this.state,
			[marker ? "selectedMarker" : "selectedCategories"]: selected,
		})
	}

	ToggleOptionMenu = (id: number) => {
		let toggledStates = this.state.toggledStates

		if (!toggledStates[id]) {
			toggledStates[id] = true
		} else {
			toggledStates[id] = !toggledStates[id]
		}

		this.setState({
			toggledStates: toggledStates,
		})
	}

	GetCategoryList = (
		categories: Array<any> | null = null,
		paddingMultiplier: number = 0
	) => {
		if (!this.state.categories) {
			return null
		}

		return (categories ?? this.state.categories).map((category: any) => {
			if (!category) {
				return undefined
			}

			return (
				<React.Fragment key={category.id}>
					<ListItem
						dense={this.props.user?.denseMode}
						button
						onClick={() => {
							this.ToggleOption(category)
						}}
						style={{
							paddingLeft: `${paddingMultiplier * 1}rem`,
						}}
					>
						<Checkbox
							checked={
								this.state.selectedCategories?.indexOf(
									category.id
								) !== -1
							}
						/>
						<ListItemText primary={category.title} />
						<span />
						{category.categories && category.categories.length > 0 && (
							<ListItemSecondaryAction>
								<Tooltip
									title={
										<Typography variant="subtitle2">
											Select all
										</Typography>
									}
								>
									<IconButton
										onClick={() => {
											this.EditSubCategories(
												category.categories,
												category.id
											)
										}}
									>
										<DoneAllIcon />
									</IconButton>
								</Tooltip>
								<Tooltip
									title={
										<Typography variant="subtitle2">
											Clear all
										</Typography>
									}
								>
									<IconButton
										onClick={() => {
											this.EditSubCategories(
												category.categories,
												category.id,
												false
											)
										}}
									>
										<ClearAllIcon />
									</IconButton>
								</Tooltip>
								<Tooltip
									title={`Show ${
										this.state.toggledStates &&
										this.state.toggledStates[category.id]
											? "less"
											: "more"
									}`}
								>
									<IconButton
										onClick={() => {
											this.ToggleOptionMenu(category.id)
										}}
									>
										{this.state.toggledStates &&
										this.state.toggledStates[
											category.id
										] ? (
											<ExpandLess />
										) : (
											<ExpandMore />
										)}
									</IconButton>
								</Tooltip>
							</ListItemSecondaryAction>
						)}
					</ListItem>
					{category.categories && category.categories.length > 0 && (
						<Collapse
							in={
								this.state.toggledStates &&
								this.state.toggledStates[category.id]
							}
							timeout="auto"
							unmountOnExit
						>
							<List dense={this.props.user?.denseMode}>
								{this.GetCategoryList(
									category.categories,
									paddingMultiplier + 1
								)}
							</List>
						</Collapse>
					)}
				</React.Fragment>
			)
		})
	}

	GetMarkerList = (
		markers: Array<any> | null = null,
		paddingMultiplier: number = 1
	) => {
		if (!this.state.markers) {
			return null
		}

		return (markers ?? this.state.markers).map((marker: any) => {
			if (!marker) {
				return undefined
			}

			let value = `marker__${marker.marker}`
			return (
				<ListItem
					dense={this.props.user?.denseMode}
					key={marker.marker}
					button
					style={{
						paddingTop: "0.125rem",
						paddingBottom: "0.125rem",
						paddingLeft: `${paddingMultiplier * 1}rem`,
					}}
				>
					<Checkbox
						onClick={() => {
							this.OnChange({
								target: {
									name: "selected",
									value: value,
								},
							})
						}}
						checked={this.state.selected === value}
					/>
					<ListItemText primary={marker.name} />
				</ListItem>
			)
		})
	}

	OnChange = (event: any) => {
		this.setState({
			...this.state,
			[event.target.name]: event.target.value,
		})
	}

	OnFilter = () => {
		let filters: any = {}

		for (let key of Object.keys(this.state)) {
			if (Validators[key]) {
				let valid = false

				for (let validation of Validators[key]) {
					valid = validation((this.state as any)[key])
				}

				if (!valid) {
					continue
				}

				filters[key] = (this.state as any)[key]
			}
		}

		this.props.OnFilter?.(filters)
	}

	render() {
		return (
			<Grid container style={this.props.style}>
				<Grid item xs={12}>
					<Accordion
						id="item-filter"
						summary={
							<Typography variant="subtitle2">Filters</Typography>
						}
						details={
							<Grid
								style={{
									padding: "0 1rem 1rem 1rem",
								}}
								container
								spacing={1}
								justify="space-between"
								alignContent="flex-end"
							>
								<Grid item xs={12} md={6} lg={4}>
									<MuiPickersUtilsProvider
										utils={DateFnsUtils}
									>
										<DateTimePicker
											fullWidth
											clearable
											id="published_after"
											label="Published After"
											value={
												this.state.publishedAfter ??
												null
											}
											onChange={(date) => {
												this.OnChange({
													target: {
														name: "publishedAfter",
														value: date,
													},
												})
											}}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MuiPickersUtilsProvider
										utils={DateFnsUtils}
									>
										<DateTimePicker
											fullWidth
											clearable
											id="published_before"
											label="Published Before"
											value={
												this.state.publishedBefore ??
												null
											}
											onChange={(date) => {
												this.OnChange({
													target: {
														name: "publishedBefore",
														value: date,
													},
												})
											}}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<FormControl style={{ width: "100%" }}>
										<InputLabel id="categories_labal">
											Categories
										</InputLabel>

										<Select
											multiple
											id="categories"
											name="selectedCategories"
											labelId="categories_labal"
											value={
												this.state.selectedCategories
											}
											renderValue={(selected: any) => {
												return (selected.length
													? selected
													: []
												)
													.map((id: number) => {
														if (
															!this.state
																.categories
														) {
															return undefined
														}

														let categoryObject = this.state.categories?.find(
															(category) =>
																category.id ===
																id
														)

														if (!categoryObject) {
															for (let category of this
																.state
																.categories) {
																categoryObject = category.categories?.find(
																	(
																		subCategory: any
																	) => {
																		return (
																			subCategory.id ===
																			id
																		)
																	}
																)

																if (
																	categoryObject
																) {
																	break
																}
															}
														}

														if (!categoryObject) {
															return undefined
														}

														return categoryObject.title
													})
													.join(",")
											}}
										>
											<List
												dense={
													this.props.user?.denseMode
												}
											>
												{this.GetCategoryList() ?? (
													<ListItem>
														<CircularProgress />
													</ListItem>
												)}
											</List>
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<FormControl style={{ width: "100%" }}>
										<InputLabel id="markers_labal">
											Markers
										</InputLabel>

										<Select
											multiple
											id="markers"
											renderValue={(selected: any) => {
												return selected
													.map(
														(value: any) =>
															this.state.markers?.find(
																(marker) =>
																	marker.marker ===
																	value
															).name
													)
													.join(",")
											}}
											name="selectedMarkers"
											labelId="markers_labal"
											value={this.state.selectedMarkers}
										>
											<AutoSizer
												style={{
													height: "100%",
													minHeight: "500px",
													width: "100%",
												}}
											>
												{({ height, width }) => {
													return (
														<FixedSizeList
															height={height}
															width={width}
															itemSize={46}
															itemData={
																this.state
																	.selectedMarkers
															}
															itemCount={
																this.state
																	.markers
																	?.length ??
																0
															}
														>
															{(props: any) => (
																<Row
																	{...props}
																	denseMode={
																		this
																			.props
																			.user
																			?.denseMode
																	}
																	marker={
																		this
																			.state
																			.markers?.[
																			props
																				.index
																		]
																	}
																	onClick={
																		this
																			.ToggleOption
																	}
																/>
															)}
														</FixedSizeList>
													)
												}}
											</AutoSizer>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={12}
									style={{
										textAlign: "right",
										marginTop: "1rem",
									}}
								>
									<Button
										variant="outlined"
										onClick={this.OnFilter}
									>
										Filter
									</Button>
								</Grid>
							</Grid>
						}
					/>
				</Grid>
			</Grid>
		)
	}
}

function mapStateToProps(state: any, props: any): FilterProps {
	return {
		OnFilter: props.OnFilter,
		user: state.user,
	}
}

function mapDispatchToProps(dispatch: Dispatch): FilterActionProps {
	return bindActionCreators({ createNotification }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
