import { Action } from '..';
import { types as REPORT_TYPES } from './actions';

export interface DateColor {
    color: string;
    daysAgo: number;
}

export interface Report {
    loading: boolean;
    items: Array<any>;
    watchCount: any;
    notificationCount: any;
    categories: Array<any>;
    colors: Array<DateColor>;
    jobs: Array<any>;
}

const initalState: Report = JSON.parse(
    localStorage.getItem('reports') ??
        JSON.stringify({
            categories: [],
            items: [],
            jobs: [],
            watchCount: { labels: [], data: [] },
            notificationCount: { labels: [], datasets: [] },
            loading: false,
            colors: [
                { daysAgo: 30, color: 'rgba(255, 0, 0, 0.5)' },
                { daysAgo: 14, color: 'rgba(243, 168, 59, 0.5)' },
                { daysAgo: 7, color: 'rgba(255, 254, 84, 0.5)' },
                { daysAgo: 4, color: 'rgba(0, 255, 0, 0.5)' },
            ],
        })
);

interface IAction extends Action {
    type: any;
    payload: any;
}

export default function reducer(state = initalState, action: IAction) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case REPORT_TYPES.UPDATE_COLOR:
            newState.colors[
                action.payload.index
            ].color = `rgba(${action.payload.color.r}, ${action.payload.color.g}, ${action.payload.color.b}, ${action.payload.color.a})`;

            break;
        case REPORT_TYPES.TOGGLE_LOADING:
            newState = {
                ...state,
                loading: !state.loading,
            };

            break;
        case REPORT_TYPES.GET_JOB_REPORT:
            newState = {
                ...state,
                jobs: action.payload,
            };

            break;
        case REPORT_TYPES.GET_NOTIFICATION_REPORT:
            newState = {
                ...state,
                notificationCount: action.payload,
            };

            break;
        case REPORT_TYPES.GET_CATEGORY_REPORT:
            newState = {
                ...state,
                categories: action.payload,
            };

            break;
        case REPORT_TYPES.GET_ITEM_REPORT:
            newState = {
                ...state,
                items: action.payload,
            };

            break;
        case REPORT_TYPES.GET_WATCH_COUNT_REPORT:
            newState = {
                ...state,
                watchCount: action.payload,
            };

            break;
        default:
            return state;
    }

    return newState;
}
