import {
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from "@material-ui/core"
import { connect } from "react-redux"
import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import DateFnsUtils from "@date-io/date-fns"
import Button from "@material-ui/core/Button"
import Accordion from "../../../utils/accordion"
import Checkbox from "@material-ui/core/Checkbox"
import ListItem from "@material-ui/core/ListItem"
import { bindActionCreators, Dispatch } from "redux"
import Typography from "@material-ui/core/Typography"
import { User } from "../../../../store/user/reducer"
import FormControl from "@material-ui/core/FormControl"
import ListItemText from "@material-ui/core/ListItemText"
import { createNotification } from "../../../../store/notification/actions"
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"

interface FilterProps {
	style?: any
	user?: User
	OnFilter?: any
	sentAfter?: Date
	sendBefore?: Date
	interval?: string
	loading?: boolean
	createNotification?: typeof createNotification
}

interface FilterActionProps {
	OnFilter?: any
	createNotification?: typeof createNotification
}

const noEmpty = (value: any) => value !== undefined && value !== null
const noEmptyString = (value: string) => noEmpty(value) && value !== ""
const noEmptyArray = (value: Array<any>) => noEmpty(value) && value.length !== 0

const Validators: any = {
	sentAfter: [noEmpty],
	sendBefore: [noEmpty],
	interval: [noEmptyString],
}

class Row extends React.Component<any, any, any> {
	shouldComponentUpdate() {
		return true
	}

	render() {
		return (
			<ListItem
				dense={this.props.denseMode}
				button
				onClick={() => {
					this.props.onClick(this.props.marker, true)
				}}
				style={this.props.style}
				key={this.props.marker.marker}
			>
				<Checkbox
					value={this.props.marker.marker}
					checked={
						this.props.data?.includes(this.props.marker.marker) ??
						false
					}
				/>
				<ListItemText primary={this.props.marker.name} />
			</ListItem>
		)
	}
}

export class Filter extends Component<
	FilterProps,
	FilterProps,
	FilterActionProps
> {
	constructor(props: FilterProps) {
		super(props)

		this.state = {
			interval: "Daily",
		}
	}

	OnChange = (event: any) => {
		this.setState({
			...this.state,
			[event.target.name]: event.target.value,
		})
	}

	OnFilter = () => {
		let filters: any = {}

		for (let key of Object.keys(this.state)) {
			if (Validators[key]) {
				let valid = false

				for (let validation of Validators[key]) {
					valid = validation((this.state as any)[key])
				}

				if (!valid) {
					continue
				}

				filters[key] = (this.state as any)[key]
			}
		}

		this.props.OnFilter?.(filters)
	}

	render() {
		return (
			<Grid container style={this.props.style}>
				<Grid item xs={12}>
					<Accordion
						id="item-filter"
						summary={
							<Typography variant="subtitle2">Filters</Typography>
						}
						details={
							<Grid
								style={{
									padding: "0 1rem 1rem 1rem",
								}}
								container
								spacing={1}
								justify="flex-start"
								alignContent="flex-start"
							>
								<Grid item xs={12} md={6} lg={4}>
									<MuiPickersUtilsProvider
										utils={DateFnsUtils}
									>
										<DateTimePicker
											fullWidth
											clearable
											id="sentAfter"
											label="Sent After"
											value={this.state.sentAfter ?? null}
											onChange={(date) => {
												this.OnChange({
													target: {
														name: "sentAfter",
														value: date,
													},
												})
											}}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MuiPickersUtilsProvider
										utils={DateFnsUtils}
									>
										<DateTimePicker
											fullWidth
											clearable
											id="sentBefore"
											label="Sent Before"
											value={
												this.state.sendBefore ?? null
											}
											onChange={(date) => {
												this.OnChange({
													target: {
														name: "sendBefore",
														value: date,
													},
												})
											}}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid
									item
									xs={12}
									style={{ marginTop: "1rem" }}
								>
									<FormControl style={{ float: "left" }}>
										<FormLabel
											style={{ textAlign: "left" }}
										>
											Report interval
										</FormLabel>
										<RadioGroup
											row
											onChange={(_event, value) =>
												this.OnChange({
													target: {
														name: "interval",
														value,
													},
												})
											}
											name="interval"
											aria-label="Interval"
											value={this.state.interval}
										>
											<FormControlLabel
												label="Daily"
												value={"Daily"}
												control={<Radio name="Daily" />}
											/>
											<FormControlLabel
												label="Hourly"
												value={"Hourly"}
												control={
													<Radio name="Hourly" />
												}
											/>
											<FormControlLabel
												label="Minutely"
												value={"Minutely"}
												control={
													<Radio name="Minutely" />
												}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={12}
									style={{
										textAlign: "right",
										marginTop: "1rem",
									}}
								>
									<Button
										variant="outlined"
										onClick={this.OnFilter}
									>
										Filter
									</Button>
								</Grid>
							</Grid>
						}
					/>
				</Grid>
			</Grid>
		)
	}
}

function mapStateToProps(state: any, props: any): FilterProps {
	return {
		OnFilter: props.OnFilter,
		user: state.user,
	}
}

function mapDispatchToProps(dispatch: Dispatch): FilterActionProps {
	return bindActionCreators({ createNotification }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
