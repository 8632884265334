import { RootState } from ".."
import { Dispatch } from "redux"
import { Notify } from "./reducer"
import { ThunkDispatch } from "redux-thunk"
import { createNotification } from "../notification/actions"

export enum types {
	RESET = "RESET",
	ON_CHANGE = "ON_CHANGE",
	TOGGLE_ITEM = "TOGGLE_ITEM",
	TOGGLE_SENT = "TOGGLE_SENT",
	TOGGLE_LOADING = "TOGGLE_LOADING",
	TOGGLE_TESTING = "TOGGLE_TESTING",
}

const { post } = require("../../plugins/api")

export const toggleItem = (itemId: number) => (dispatch: Dispatch) =>
	dispatch({
		type: types.TOGGLE_ITEM,
		payload: itemId,
	})

export const toggleTesting = (test: string | boolean) => (dispatch: Dispatch) =>
	dispatch({
		type: types.TOGGLE_TESTING,
		payload: test,
	})

export const sendNotification = (notify: Notify) => async (
	dispatch: ThunkDispatch<RootState, Notify, any>
) => {
	try {
		dispatch({
			type: types.TOGGLE_LOADING,
		})
		console.log(notify)
		await post("/v1/notify", {
			title: notify.title,
			testRun: notify.test,
			videos: notify.selectedItems,
			description: notify.description,
			topics: notify.topics,
			image: notify.image ?? undefined,
		})

		setTimeout(() => {
			dispatch({
				type: types.TOGGLE_LOADING,
			})

			dispatch({
				type: types.TOGGLE_SENT,
			})
		}, 1000)
	} catch (error) {
		console.error(error)
		dispatch(
			createNotification({
				message: "Something went wrong, please try again later.",
				severity: "error",
			})
		)
	}
}

export const OnChange = (event: any) => async (
	dispatch: ThunkDispatch<RootState, Notify, any>
) => {
	try {
		dispatch({
			type: types.ON_CHANGE,
			payload: event,
		})
	} catch (error) {
		console.error(error)
		dispatch(
			createNotification({
				message: "Something went wrong, please try again later.",
				severity: "error",
			})
		)
	}
}

export const ResetNotify = () => async (
	dispatch: ThunkDispatch<RootState, Notify, any>
) => {
	dispatch({
		type: types.RESET,
	})
}
