import './style.scss';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { User } from '../../store/user/reducer';
import { bindActionCreators, Dispatch } from 'redux';
import {
    loadUser,
    logout,
    login,
    toggleDenseMode,
} from '../../store/user/actions';
import { Notification } from '../../store/notification/reducer';
import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { RootState } from '../../store';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import Tabs from '@material-ui/core/Tabs';
import { Log, Logs } from '../../store/logs';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Environment, setEnvironment } from '../../store/environment';

import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface HeaderProps {
    ref?: any;
    user?: User;
    logs?: Logs;
    tab?: string;
    open?: boolean;
    login?: typeof login;
    logout?: typeof logout;
    environment?: Environment;
    loadUser?: typeof loadUser;
    notifications?: Notification;
    setEnvironment?: typeof setEnvironment;
    toggleDenseMode?: typeof toggleDenseMode;
}

interface HeaderActionProps {
    login?: typeof login;
    logout?: typeof logout;
    loadUser?: typeof loadUser;
    setEnvironment?: typeof setEnvironment;
    toggleDenseMode?: typeof toggleDenseMode;
}

export class Header extends Component<
    HeaderProps,
    HeaderProps,
    HeaderActionProps
> {
    constructor(props: HeaderProps) {
        super(props);

        this.state = {
            open: false,
        };
    }

    ToggleMenu = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    OnChange = (_event: any, tab: string | null = null) => {
        if (!tab) {
            return;
        }

        this.setState({
            ...this.state,
            tab,
        });
    };

    render() {
        return (
            <AppBar
                position="sticky"
                className="App-Header"
                style={{
                    background:
                        'linear-gradient(295.86deg, #2AEAFF -43.14%, #39CEFF 20.81%, #4DA9FE 105.71%)',
                }}
            >
                <Toolbar>
                    {this.props.user && (
                        <Grid container justify="flex-start">
                            <Grid item xs={12} md={4} lg={3} xl={2}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel id="environment-label">
                                        Environment
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        id="environment"
                                        labelId="environment-label"
                                        onChange={(event) => {
                                            this.props.setEnvironment?.(
                                                event.target.value as string
                                            );
                                        }}
                                        value={this.props.environment ?? 'dev'}
                                    >
                                        <MenuItem value="dev">
                                            Development
                                        </MenuItem>
                                        <MenuItem value="staging">
                                            Staging
                                        </MenuItem>
                                        <MenuItem value="prod">
                                            Production
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <span style={{ flexGrow: 1 }} />
                            <IconButton
                                ref={(ref) => {
                                    if (!this.state.ref)
                                        this.setState({
                                            ...this.state,
                                            ref: ref,
                                        });
                                }}
                                onClick={this.ToggleMenu}
                            >
                                <ListAltIcon />
                            </IconButton>
                            {(this.props.user.denseMode && (
                                <IconButton
                                    ref={(ref) => {
                                        if (!this.state.ref)
                                            this.setState({
                                                ...this.state,
                                                ref: ref,
                                            });
                                    }}
                                    onClick={() =>
                                        this.props.toggleDenseMode?.()
                                    }
                                >
                                    <ZoomInIcon />
                                </IconButton>
                            )) || (
                                <IconButton
                                    ref={(ref) => {
                                        if (!this.state.ref)
                                            this.setState({
                                                ...this.state,
                                                ref: ref,
                                            });
                                    }}
                                    onClick={() =>
                                        this.props.toggleDenseMode?.()
                                    }
                                >
                                    <ZoomOutIcon />
                                </IconButton>
                            )}

                            {this.props.logs && this.props.logs.length !== 0 && (
                                <Menu
                                    keepMounted
                                    style={{ padding: 0 }}
                                    onClose={this.ToggleMenu}
                                    open={this.state.open ?? false}
                                    anchorEl={this.state.ref ?? null}
                                >
                                    <Tabs
                                        textColor="secondary"
                                        variant="fullWidth"
                                        style={{
                                            display: 'flex',
                                            borderBottom:
                                                '1px solid rgba(224, 224, 224, 1)',
                                        }}
                                        value={
                                            this.state.tab ??
                                            this.props.logs?.[0]?.name
                                        }
                                        onChange={this.OnChange}
                                        indicatorColor="secondary"
                                    >
                                        {this.props.logs?.map((log: Log) => (
                                            <Tab
                                                value={log.name}
                                                label={log.name}
                                            />
                                        ))}
                                    </Tabs>
                                    <List dense={this.props.user.denseMode}>
                                        {this.props.logs
                                            ?.find(
                                                (log: Log) =>
                                                    log.name ===
                                                    (this.state.tab ??
                                                        this.props.logs?.[0]
                                                            ?.name)
                                            )
                                            ?.logs.map((log: any) => (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        {log.severity ===
                                                        'error' ? (
                                                            <ErrorIcon
                                                                style={{
                                                                    color:
                                                                        '#d32f2f',
                                                                }}
                                                            />
                                                        ) : log.severity ===
                                                          'info' ? (
                                                            <InfoIcon
                                                                style={{
                                                                    color:
                                                                        '#2196f3',
                                                                }}
                                                            />
                                                        ) : (
                                                            <CheckCircleIcon
                                                                style={{
                                                                    color:
                                                                        '#4caf50',
                                                                }}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography
                                                            variant="subtitle1"
                                                            className="log-message"
                                                        >
                                                            {log.message}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            ))}
                                    </List>
                                </Menu>
                            )}
                        </Grid>
                    )}
                </Toolbar>
            </AppBar>
        );
    }
}

function mapStateToProps(state: RootState): HeaderProps {
    return {
        logs: state.logs,
        user: state.user,
        environment: state.environment,
        notifications: state.notification,
    };
}

function mapDispatchToProps(dispatch: Dispatch): HeaderActionProps {
    return bindActionCreators(
        { loadUser, login, logout, setEnvironment, toggleDenseMode },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
