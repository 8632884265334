import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import { AutoSizer } from "react-virtualized"
import { FixedSizeList } from "react-window"

class Row extends React.Component<any, any, any> {
	shouldComponentUpdate() {
		return true
	}

	render() {
		if (!this.props.category) {
			return null
		}

		return (
			<ListItem
				{...this.props}
				button
				role="listitem"
				key={this.props.category.id}
				onClick={this.props.onClick}
			>
				<ListItemIcon>
					<Checkbox
						checked={this.props.checked}
						tabIndex={-1}
						disableRipple
					/>
				</ListItemIcon>
				<ListItemText primary={this.props.label} />
			</ListItem>
		)
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		margin: "auto",
	},
	paper: {
		width: 400,
		height: 460,
		overflow: "auto",
	},
	button: {
		margin: theme.spacing(0.5, 0),
	},
}))

function not(a: any[], b: any[]) {
	return a.filter((value: any) => b.map((t) => t.id).indexOf(value.id) === -1)
}

function intersection(a: any[], b: any[]) {
	return a.filter((value: any) => b.map((t) => t.id).indexOf(value.id) !== -1)
}

export default function TransferList({
	name = undefined,
	value,
	options,
	onChange,
	getOptionLabel,
}: any) {
	const classes = useStyles()

	const [left, setLeft] = React.useState<Array<any>>(options)
	const [right, setRight] = React.useState<Array<any>>(value)
	const [checked, setChecked] = React.useState<Array<any>>([])
	const [leftChecked, setLeftChecked] = React.useState<Array<any>>([])
	const [rightChecked, setRightChecked] = React.useState<Array<any>>([])

	React.useEffect(() => {
		setLeftChecked(intersection(checked, left))
		setRightChecked(intersection(checked, right))
	}, [checked])

	React.useEffect(() => {
		setLeft(not(options, value))
		setRight(value)
	}, [options, value])

	const handleToggle = (value: any) => () => {
		const currentIndex = checked.map((c) => c.id).indexOf(value.id)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
	}

	const handleAllRight = () => {
		const _right = right.concat(left)
		setRight(_right)
		setLeft([])

		OnChange(_right)
	}

	const handleCheckedRight = () => {
		const _right = right.concat(leftChecked)
		setRight(_right)
		setLeft(not(left, leftChecked))
		setChecked(not(checked, leftChecked))

		OnChange(_right)
	}

	const handleCheckedLeft = () => {
		const _right = not(right, rightChecked)
		setLeft(left.concat(rightChecked))
		setRight(_right)
		setChecked(not(checked, rightChecked))

		OnChange(_right)
	}

	const handleAllLeft = () => {
		const _right: any = []
		setLeft(left.concat(right))
		setRight(_right)

		OnChange(_right)
	}

	const OnChange = (value: any) =>
		onChange?.({
			target: {
				name,
				value,
			},
		})

	const customList = (items: any[]) => (
		<Paper className={classes.paper}>
			<AutoSizer
				style={{
					width: "100%",
					height: "100%",
				}}
			>
				{({ height, width }: any) => {
					return (
						<FixedSizeList
							width={width}
							itemSize={58}
							itemData={items}
							height={height}
							itemCount={items.length}
						>
							{(props: any) => {
								return (
									<Row
										{...props}
										category={items[props.index]}
										label={getOptionLabel(
											items[props.index]
										)}
										checked={
											checked.indexOf(
												items[props.index]
											) !== -1
										}
										onClick={handleToggle(
											items[props.index]
										)}
									/>
								)
							}}
						</FixedSizeList>
					)
				}}
			</AutoSizer>
			{/* <List dense component="div" role="list">
				{items.map((value: any) => {
					const labelId = `transfer-list-item-${value.id}-label`

					return (
						<ListItem
							key={value.id}
							role="listitem"
							button
							onClick={handleToggle(value)}
						>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{ "aria-labelledby": labelId }}
								/>
							</ListItemIcon>
							<ListItemText
								id={labelId}
								primary={getOptionLabel(value)}
							/>
						</ListItem>
					)
				})}
				<ListItem />
			</List> */}
		</Paper>
	)

	return (
		<Grid
			container
			spacing={2}
			justify="center"
			alignItems="center"
			className={classes.root}
		>
			<Grid item>{customList(left)}</Grid>
			<Grid item>
				<Grid container direction="column" alignItems="center">
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={handleAllRight}
						disabled={left.length === 0}
						aria-label="move all right"
					>
						≫
					</Button>
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={handleCheckedRight}
						disabled={leftChecked.length === 0}
						aria-label="move selected right"
					>
						&gt;
					</Button>
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={handleCheckedLeft}
						disabled={rightChecked.length === 0}
						aria-label="move selected left"
					>
						&lt;
					</Button>
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={handleAllLeft}
						disabled={right.length === 0}
						aria-label="move all left"
					>
						≪
					</Button>
				</Grid>
			</Grid>
			<Grid item>{customList(right)}</Grid>
		</Grid>
	)
}
