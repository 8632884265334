import Filter from './filter';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { bindActionCreators, Dispatch } from 'redux';
import TableContainer from '@material-ui/core/TableContainer';
import { getItemReport } from '../../../store/report/actions';

const blacklist = [
    'created_by',
    'get_id',
    'query',
    'query_class',
    'is_active',
    'is_authenticated',
    'is_anonymous',
];

interface ItemProps {
    style?: any;
    report?: any;
    sort?: {
        field: string;
        direction: 'desc' | 'asc';
    };
    getItemReport?: typeof getItemReport;
}

interface ItemActionProps {
    getItemReport?: typeof getItemReport;
}

export class Item extends Component<ItemProps, ItemProps, ItemActionProps> {
    constructor(props: ItemProps) {
        super(props);

        this.state = {
            sort: {
                field: '',
                direction: 'asc',
            },
        };
    }

    componentDidMount() {
        this.LoadData();
    }

    LoadData = (filters: any = null) => {
        this.props.getItemReport?.(filters);
    };

    Sort = (newField: string) => {
        let field: string | undefined = '';
        let direction: 'desc' | 'asc' | undefined =
            this.state.sort?.direction || 'asc';

        if (this.state.sort?.field !== newField) {
            field = newField;
            direction = 'asc';
        } else if (this.state.sort?.direction === 'desc') {
            field = '';
        } else {
            field = this.state.sort.field;
            direction = this.state.sort?.direction === 'asc' ? 'desc' : 'asc';
        }

        this.setState({
            ...this.state,
            sort: {
                field,
                direction,
            },
        });
    };

    GetItemReport = () => {
        if (!this.props.report || this.props.report.length === 0) {
            return null;
        }

        let columns = Object.keys(this.props.report[0]).filter(
            (key) => blacklist.indexOf(key) === -1
        );

        let rows = this.props.report?.map((item: any) => {
            return (
                <TableRow key={Math.random() * new Date().getTime() + 'top'}>
                    {columns.map((key: string) => {
                        return (
                            <TableCell
                                key={
                                    Math.random() * new Date().getTime() + 'sub'
                                }
                            >
                                {typeof item[key] === 'string'
                                    ? item[key]
                                    : JSON.stringify(item[key])}
                            </TableCell>
                        );
                    })}
                </TableRow>
            );
        });

        return (
            <TableContainer
                style={{
                    margin: '0.5rem',
                    padding: '0.25rem',
                    backgroundColor: 'white',
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => {
                                return (
                                    <TableCell
                                        key={
                                            Math.random() *
                                                new Date().getTime() +
                                            'sub'
                                        }
                                    >
                                        {column}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    OnFilter = (filters: any) => {
        this.LoadData(filters);
    };

    render() {
        return (
            <Grid container style={this.props.style}>
                <Filter OnFilter={(filters: any) => this.OnFilter(filters)} />
                {this.GetItemReport()}
            </Grid>
        );
    }
}

function mapStateToProps(state: any): ItemProps {
    return {
        report: state.reports.items,
    };
}

function mapDispatchToProps(dispatch: Dispatch): ItemActionProps {
    return bindActionCreators({ getItemReport }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Item);
