import { types as ADMIN_TYPES } from './actions';

const get = () => {
    return localStorage.getItem('permissions');
};

const set = (value: any) => {
    localStorage.setItem('permissions', JSON.stringify(value));
};

const initStateStringified = JSON.stringify({
    roles: [],
    users: [],
    tab: 'Roles',
    editIndex: null,
    editing: false,
    userGroups: [],
    resourceGroups: [],
});

const initState = () => {
    return JSON.parse(get() ?? initStateStringified);
};

interface Index {
    [key: string]: any;
    created_at: Date | null;
    updated_at: Date | null;
    deleted_at: Date | null;
}

interface Base extends Index {
    id: number | null;
    name: string | null;
    description: string | null;
}

export interface User extends Index {
    id: number;
}

export interface Role extends Base {
    role: 'read' | 'write' | 'update' | 'delete' | null;
}

export interface UserGroup extends Base {
    resourceGroups: Array<ResourceGroup>;
}

export interface ResourceGroup extends Base {
    roles: Array<Role>;
}

export interface Permissions {
    tab: string;
    editing: boolean;
    [key: string]: any;
    roles: Array<Role>;
    users: Array<User>;
    userGroups: Array<UserGroup>;
    editIndex: number | undefined;
    resourceGroups: Array<ResourceGroup>;
}

export default (state = initState(), action: any) => {
    let newState = state;

    switch (action.type) {
        case ADMIN_TYPES.SET_TAB:
            newState = {
                ...state,
                tab: action.payload,
            };
            break;
        case ADMIN_TYPES.SET_EDIT_INDEX:
            newState = {
                ...state,
                editIndex: action.payload,
            };
            break;
        case ADMIN_TYPES.TOGGLE_EDITING:
            newState = {
                ...state,
                editing: !state.editing,
            };
            break;
        case ADMIN_TYPES.GET_ADMIN_ITEMS:
            newState = action.payload;

            break;
        case ADMIN_TYPES.GET_USERS:
            newState = {
                ...state,
                users: action.payload,
            };

            break;
        case ADMIN_TYPES.GET_ROLES:
            newState = {
                ...state,
                roles: action.payload,
            };

            break;
        case ADMIN_TYPES.ADD_ROLE:
            newState = {
                ...state,
                roles: [...state.roles, action.payload],
            };

            break;
        case ADMIN_TYPES.ADD_RESOURCE_GROUP:
            newState = {
                ...state,
                resourceGroups: [...state.resourceGroups, action.payload],
            };

            break;
        case ADMIN_TYPES.ADD_USER_GROUP:
            newState = {
                ...state,
                userGroups: [...state.userGroups, action.payload],
            };

            break;
        case ADMIN_TYPES.GET_USER_GROUPS:
            newState = {
                ...state,
                userGroups: action.payload,
            };

            break;
        case ADMIN_TYPES.GET_RESOURCE_GROUPS:
            newState = {
                ...state,
                resourceGroups: action.payload,
            };

            break;
        case ADMIN_TYPES.TOGGLE_RESOURCE_GROUP_ROLE:
            newState = {
                ...state,
                resourceGroups: state.resourceGroups.map(
                    (resourceGroup: ResourceGroup) => {
                        if (resourceGroup.id !== action.payload.id) {
                            return resourceGroup;
                        } else {
                            resourceGroup.roles = action.payload.roles;

                            return resourceGroup;
                        }
                    }
                ),
            };

            break;
        default:
            return state;
    }

    set(newState);

    return newState;
};
