import { connect } from 'react-redux';
import React, { Component } from 'react';
import MDialog from '@material-ui/core/Dialog';
import { bindActionCreators, Dispatch } from 'redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

interface DialogProps {
    open?: boolean;
    onClose?: any;
    fullWidth?: boolean | undefined;
    actions?: JSX.Element | Array<JSX.Element>;
    title: JSX.Element | Array<JSX.Element> | string;
    content: JSX.Element | Array<JSX.Element> | string;
}

interface DialogActionProps {}

export class Dialog extends Component<
    DialogProps,
    DialogProps,
    DialogActionProps
> {
    shouldComponentUpdate() {
        return true;
    }
    render() {
        return (
            <MDialog
                fullScreen={false}
                open={this.props.open ?? false}
                fullWidth={this.props.fullWidth}
                onClose={() => this.props.onClose?.()}
            >
                {this.props.title && (
                    <DialogTitle>{this.props.title}</DialogTitle>
                )}
                {this.props.content && (
                    <DialogContent>{this.props.content}</DialogContent>
                )}
                {this.props.actions && (
                    <DialogActions>{this.props.actions}</DialogActions>
                )}
            </MDialog>
        );
    }
}

function mapStateToProps(state: any, props: DialogProps): any {
    return {};
}

function mapDispatchToProps(dispatch: Dispatch): DialogActionProps {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
