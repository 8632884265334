import './style.scss';
import {
    setLoading,
    getArticles,
    createArticle,
    updateArticle,
    deleteArticle,
    rearrangeArticles,
} from '../../store/articles/actions';
import { RootState } from '../../store';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Table from '@material-ui/core/Table';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Backdrop from '@material-ui/core/Backdrop';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Article } from '../../store/articles/reducer';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useParams, useHistory } from 'react-router-dom';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DateTimePicker } from '@material-ui/pickers/DateTimePicker';
import { setContent, toggleDialog } from '../../store/dialog/actions';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';

export interface ArticlesProps {}
export interface ArticlesActionProps {}

const DEFAULT_THUMBNAILS = [
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/wfmj.png',
        label: 'WFMJ',
    },
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/2news.png',
        label: '2NEWS',
    },
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/EINPRESSWIRE.png',
        label: 'EINPRESSWIRE',
    },
    {
        link: 'https://snibblecorp.com/assets/svgs/placeholder.svg',
        label: 'Snibble Placeholder',
    },
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/ipwatchdog.png',
        label: 'IPWatchdog',
    },
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/smartbrief.png',
        label: 'SmartBrief',
    },
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/yougov.png',
        label: 'YouGov',
    },
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/appadvice.png',
        label: 'AppAdvice',
    },
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/david-pullara.png',
        label: 'David Pullara',
    },
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/newsbreak.png',
        label: 'News Break',
    },
    {
        link:
            'https://s3.amazonaws.com/snibblecorp.article.thumbnails/prod/justuseapp.png',
        label: 'JustUseApp',
    },
];

export default function Articles(_props: ArticlesProps) {
    const [date, setDate] = useState<Date | null>(null);
    const [link, setLink] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null);
    const [tab, setTab] = useState<string | null>('articles');
    const [id, setId] = useState<number | undefined>(undefined);
    const [provider, setProvider] = useState<string | null>(null);
    const [thumbnail, setThumbnail] = useState<string | any | null>(null);
    const [thumbnailFileSrc, setThumbnailFileSrc] = useState<string | null>(
        null
    );
    const [thumbnailFile, setThumbnailFile] = useState<File | undefined>(
        undefined
    );

    const urlParams: {
        tab: string | undefined;
        id: string | undefined;
    } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user, articles, isLoading } = useSelector((state: RootState) => {
        return {
            user: state.user,
            isLoading: state.articles.loading,
            articles: state.articles.articles,
        };
    });

    function ClearArticleObject() {
        setId(undefined);
        setLink('');
        setTitle('');
        setDate(null);
        setProvider('');
        setThumbnail('');
    }

    function AddArticle() {
        return (
            <Grid
                item
                xs={12}
                container
                justify="flex-start"
                style={{ height: 'fit-content' }}
            >
                <Grid item xs={12} container justify="space-evenly" spacing={3}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            label="Title"
                            value={title ?? ''}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            label="Provider"
                            value={provider ?? ''}
                            onChange={(event) =>
                                setProvider(event.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            label="Link"
                            value={link ?? ''}
                            onChange={(event) => setLink(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                fullWidth
                                clearable
                                value={date}
                                label="Published On"
                                helperText={
                                    <Typography
                                        component="span"
                                        variant="subtitle2"
                                    >
                                        NOTE: I have to be clicked
                                    </Typography>
                                }
                                onChange={(date) => setDate(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={8} container justify="space-between">
                        <Grid item xs={12} md={9}>
                            <Autocomplete
                                freeSolo
                                fullWidth
                                value={thumbnail}
                                onChange={(_event, value: any) =>
                                    setThumbnail(value)
                                }
                                getOptionSelected={(option, value) =>
                                    option.link === value.link
                                }
                                options={DEFAULT_THUMBNAILS ?? []}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField {...params} label="Thumbnail" />
                                )}
                            />
                            <Typography
                                component="span"
                                variant="subtitle2"
                                color="textSecondary"
                                style={{ float: 'left' }}
                            >
                                NOTE: Uploading a file will override this value
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <img
                                width="100%"
                                alt="Thumbnail preview"
                                src={
                                    thumbnailFileSrc ??
                                    thumbnail?.link ??
                                    thumbnail ??
                                    undefined
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Button variant="contained" component="label">
                            Upload File
                            <input
                                type="file"
                                hidden
                                onChange={(event) => {
                                    if (!event.target.files?.[0]) {
                                        return;
                                    }

                                    const reader = new FileReader();

                                    reader.onload = function (e: any) {
                                        setThumbnailFileSrc(e.target.result);
                                    };

                                    reader.readAsDataURL(
                                        event.target.files?.[0]
                                    );
                                    setThumbnailFile(event.target.files?.[0]);
                                }}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Button
                            style={{
                                margin: 'auto 1rem',
                            }}
                            color="primary"
                            variant="contained"
                            onClick={CreateArticle}
                            disabled={!date || !link || !title || !provider}
                        >
                            {id ? 'Save' : 'Add'}
                        </Button>

                        <Button
                            style={{
                                margin: 'auto 1rem',
                            }}
                            variant="contained"
                            onClick={() => {
                                ClearArticleObject();
                                history.push('/articles');
                                setTab('articles');
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    async function CreateArticle() {
        if (!link || !date || !title || !provider) {
            return;
        }

        const data = new FormData();

        if (id) {
            data.append('id', id.toString());
        }

        data.append('link', link);
        data.append('title', title);
        data.append('provider', provider);

        if (thumbnailFile) {
            data.append('file', thumbnailFile);
        } else if (thumbnail) {
            data.append('thumbnail', thumbnail?.link ?? thumbnail);
        } else {
            data.append(
                'thumbnail',
                'https://snibblecorp.com/assets/svgs/placeholder.svg'
            );
        }

        if (date.toISOString) {
            data.append('date', date.toISOString());
        } else {
            data.append('date', (date as unknown) as string);
        }

        data.append('order', '0');

        if (id) {
            await dispatch(updateArticle(data));
        } else {
            await dispatch(createArticle(data));
        }

        ClearArticleObject();
        history.push('/articles');
        setTab('articles');
    }

    async function DeleteArticle(id: string | number) {
        await dispatch(
            setContent(
                <Grid container justify="space-around">
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >
                            Are you sure you want to delete this article?
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => dispatch(deleteArticle(id))}
                        >
                            Yes
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => dispatch(toggleDialog())}
                        >
                            No
                        </Button>
                    </Grid>
                </Grid>
            )
        );
        await dispatch(toggleDialog());
    }

    function GetArticles() {
        return (
            <Table size={user?.denseMode ? 'small' : undefined}>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Provider</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell style={{ width: '10%' }}>
                            Thumbnail
                        </TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {articles?.map((article: Article, index: number) => {
                        return (
                            <TableRow key={JSON.stringify(article)}>
                                <TableCell>
                                    {new Date(article.date).toDateString()}
                                </TableCell>
                                <TableCell>{article.provider}</TableCell>
                                <TableCell>
                                    <a
                                        target="_blank"
                                        href={article.link}
                                        rel="noopener noreferrer"
                                    >
                                        {article.title}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <img
                                        height={
                                            user?.denseMode ? '64px' : '128px'
                                        }
                                        alt={article.title}
                                        src={article.thumbnail}
                                    />
                                </TableCell>
                                <TableCell>
                                    <ButtonGroup>
                                        <Button
                                            color="secondary"
                                            size={
                                                user?.denseMode
                                                    ? 'small'
                                                    : undefined
                                            }
                                            onClick={() =>
                                                DeleteArticle(article.id)
                                            }
                                        >
                                            <DeleteIcon
                                                fontSize={
                                                    user?.denseMode
                                                        ? 'small'
                                                        : undefined
                                                }
                                            />
                                        </Button>
                                        <Button
                                            color="primary"
                                            size={
                                                user?.denseMode
                                                    ? 'small'
                                                    : undefined
                                            }
                                            onClick={() => {
                                                history.push(
                                                    `/articles/edit-article/${article.id}`
                                                );
                                                SetEditing(article);
                                            }}
                                        >
                                            <EditIcon
                                                fontSize={
                                                    user?.denseMode
                                                        ? 'small'
                                                        : undefined
                                                }
                                            />
                                        </Button>
                                        <Button
                                            disabled={
                                                index === articles.length - 1
                                            }
                                            size={
                                                user?.denseMode
                                                    ? 'small'
                                                    : undefined
                                            }
                                            onClick={() =>
                                                RearrangeArticles(index, true)
                                            }
                                        >
                                            <ArrowDownwardIcon
                                                fontSize={
                                                    user?.denseMode
                                                        ? 'small'
                                                        : undefined
                                                }
                                            />
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            size={
                                                user?.denseMode
                                                    ? 'small'
                                                    : undefined
                                            }
                                            onClick={() =>
                                                RearrangeArticles(index)
                                            }
                                        >
                                            <ArrowUpwardIcon
                                                fontSize={
                                                    user?.denseMode
                                                        ? 'small'
                                                        : undefined
                                                }
                                            />
                                        </Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    function GetTab() {
        switch (tab) {
            case 'articles':
                return GetArticles();
            case 'add-article':
            case 'edit-article':
                return AddArticle();
        }
    }

    async function RearrangeArticles(
        index: number,
        goingDown: boolean = false
    ) {
        if (!articles) {
            return;
        }

        const start = new Date().getTime();

        await dispatch(setLoading(true));

        await dispatch(rearrangeArticles(index, goingDown));

        await Promise.all([
            dispatch(updateArticle(articles[index], false)),
            dispatch(
                updateArticle(articles[(goingDown ? 1 : -1) + index], false)
            ),
        ]);

        setTimeout(
            () => dispatch(setLoading(false)),
            1000 - (new Date().getTime() - start) < 0
                ? 0
                : 1000 - (new Date().getTime() - start)
        );
    }

    function SetEditing(article: Article) {
        setId(article.id);
        setLink(article.link);
        setDate(article.date);
        setTitle(article.title);
        setProvider(article.provider);
        setThumbnail(article.thumbnail);

        setTab('edit-article');
    }

    useEffect(() => {
        if (!isLoading) {
            if (!articles) {
                dispatch(getArticles());
            }

            if (urlParams.tab) {
                let article = null;
                if (['articles', 'add-article'].includes(urlParams.tab)) {
                    setTab(urlParams.tab);
                } else if (
                    urlParams.tab === 'edit-article' &&
                    urlParams.id &&
                    (article = articles?.find(
                        (article) =>
                            article.id.toString() === urlParams.id?.toString()
                    ))
                ) {
                    SetEditing(article);
                }
            }
        }
    }, [articles]);

    return (
        <Grid
            item
            xs={12}
            container
            justify="flex-start"
            alignContent="flex-start"
        >
            {isLoading && (
                <Backdrop open={isLoading} style={{ zIndex: 12001 }}>
                    <CircularProgress color="primary" size="5rem" />
                </Backdrop>
            )}
            <Tabs
                value={tab}
                variant="fullWidth"
                textColor="secondary"
                indicatorColor="secondary"
                style={{ height: 'fit-content' }}
                onChange={(_event: any, tab: string) => {
                    if (!tab) {
                        return;
                    }

                    if (id) {
                        ClearArticleObject();
                    }

                    history.push(
                        tab === 'articles' ? '/articles' : `/articles/${tab}`
                    );

                    setTab(tab);
                }}
            >
                <Tab value="articles" label="Articles" />
                <Tab value="add-article" label="Add Article" />
                <Tab
                    value="edit-article"
                    label="Edit Article"
                    style={{ pointerEvents: id ? 'all' : 'none' }}
                />
            </Tabs>
            {GetTab()}
        </Grid>
    );
}

