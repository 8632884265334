import { RootState } from '..';
import { Accordion } from './reducer';
import { ThunkDispatch } from 'redux-thunk';

export enum types {
    ADD_ACCORDION = 'ADD_ACCORDION',
    TOGGLE_ACCORDION = 'TOGGLE_ACCORDION',
}

export const toggleAccordion = (id: string | undefined) => (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    dispatch({
        type: types.TOGGLE_ACCORDION,
        payload: id,
    });
};

export const addAccordion = (accordion: Accordion) => (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    dispatch({
        type: types.ADD_ACCORDION,
        payload: accordion,
    });
};
