import { Dispatch } from 'redux';
import { Notification } from './reducer';

export enum types {
    ADD_NOTIFICATION = 'ADD_NOTIFICATION',
    GET_NOTIFICATION = 'GET_NOTIFICATION',
    UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION',
    DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
}

const defaultSetting: Notification = {
    icon: '',
    open: true,
    message: '',
    anchor: {
        vertical: 'top',
        horizontal: 'right',
    },
    copyData: null,
    autoHide: 3000,
    severity: undefined,
};

export const createNotification = (notification: Notification | any, copyData = null) => (
    dispatch: Dispatch
) => {
    let notificationObject = {
        ...defaultSetting,
        ...notification,
    };

    if (!notificationObject.message) {
        return;
    }

    if (copyData) {
        notificationObject.copyData = copyData;
    }

    dispatch({
        type: types.ADD_NOTIFICATION,
        payload: notificationObject,
    });
};

export const closeNotification = () => (dispatch: Dispatch) => {
    dispatch({
        type: types.DELETE_NOTIFICATION,
    });
};
