import { connect } from "react-redux"
import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import DateFnsUtils from "@date-io/date-fns"
import { get } from "../../../../plugins/api"
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"
import Accordion from "../../../utils/accordion"
import Checkbox from "@material-ui/core/Checkbox"
import ListItem from "@material-ui/core/ListItem"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { bindActionCreators, Dispatch } from "redux"
import DoneAllIcon from "@material-ui/icons/DoneAll"
import Typography from "@material-ui/core/Typography"
import InputLabel from "@material-ui/core/InputLabel"
import ClearAllIcon from "@material-ui/icons/ClearAll"
import { IconButton, Tooltip } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse/Collapse"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CircularProgress from "@material-ui/core/CircularProgress"
import { createNotification } from "../../../../store/notification/actions"
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { User } from "../../../../store/user/reducer"
import { RootState } from "../../../../store"

interface FilterProps {
	style?: any
	user?: User
	OnFilter?: any
	title?: string
	description?: string
	toggledStates?: any
	publishedAfter?: Date
	publishedBefore?: Date
	hideLocalFeed?: boolean
	channels?: Array<any>
	categories?: Array<any>
	onlyNotifyItems?: boolean
	selectedChannels?: Array<any>
	onlyWatchListItems?: boolean
	resource_status?: Array<number>
	selectedCategories?: Array<number>
	feedItemIds?: Array<number | string>
	createNotification?: typeof createNotification
}

interface FilterActionProps {
	OnFilter?: any
	createNotification?: typeof createNotification
}

const noEmpty = (value: any) => value !== undefined && value !== null
const noEmptyString = (value: string) => noEmpty(value) && value !== ""

const noEmptyArray = (value: Array<any>) => noEmpty(value) && value.length !== 0

const Validators: any = {
	hideLocalFeed: [],
	onlyNotifyItems: [],
	onlyWatchListItems: [],
	title: [noEmptyString],
	feedItemIds: [noEmpty],
	publishedAfter: [noEmpty],
	publishedBefore: [noEmpty],
	description: [noEmptyString],
	resource_status: [noEmptyArray],
	selectedChannels: [noEmptyArray],
	selectedCategories: [noEmptyArray],
}

export class Filter extends Component<
	FilterProps,
	FilterProps,
	FilterActionProps
> {
	constructor(props: FilterProps) {
		super(props)

		this.state = {
			title: "",
			description: "",
			toggledStates: {},
			resource_status: [],
			selectedChannels: [],
			selectedCategories: [],
			onlyNotifyItems: false,
			onlyWatchListItems: false,
			hideLocalFeed: props.hideLocalFeed ?? false,
		}
	}

	componentDidMount() {
		get("/v1/categories?reduce=true")
			.then((categories: Array<any>) => {
				this.setState({
					...this.state,
					categories,
				})
			})
			.catch((e) => {
				this.props.createNotification?.({
					message: e.message ?? e,
					severity: "error",
				})
			})

		get("/v1/feed/aggregation_channel_markers?reduce=true").then(
			(channels: Array<any>) => {
				this.setState({
					...this.state,
					channels,
				})
			}
		)
	}

	EditSubCategories = (
		categoriess: Array<any>,
		parentId: number,
		add: boolean = true
	) => {
		let selected = this.state.selectedCategories

		for (let category of categoriess) {
			if (add) {
				if (selected?.indexOf(category.id) === -1) {
					selected.push(category.id)
				}

				if (selected?.indexOf(parentId) === -1) {
					selected.push(parentId)
				}
			} else {
				if (selected?.indexOf(category.id) !== -1) {
					selected?.splice(selected?.indexOf(category.id), 1)
				}

				if (selected?.indexOf(parentId) !== -1) {
					selected?.splice(selected?.indexOf(parentId), 1)
				}
			}
		}

		this.setState({
			selectedCategories: selected,
		})
	}

	ToggleOption = (category: any) => {
		if (!this.state.selectedCategories) {
			return
		}

		let selectedCategories = this.state.selectedCategories

		if (selectedCategories?.indexOf(category.id) === -1) {
			selectedCategories.push(category.id)
		} else {
			selectedCategories?.splice(
				selectedCategories?.indexOf(category.id),
				1
			)
		}

		for (let subCategory of category.categories) {
			if (selectedCategories?.indexOf(subCategory.id) === -1) {
				selectedCategories.push(subCategory.id)
			} else {
				selectedCategories?.splice(
					selectedCategories?.indexOf(subCategory.id),
					1
				)
			}
		}

		this.setState({
			selectedCategories,
		})
	}

	ToggleOptionMenu = (id: number) => {
		let toggledStates = this.state.toggledStates

		if (!toggledStates[id]) {
			toggledStates[id] = true
		} else {
			toggledStates[id] = !toggledStates[id]
		}

		this.setState({
			toggledStates: toggledStates,
		})
	}

	GetCategoryList = (
		categories: Array<any> | null = null,
		paddingMultiplier: number = 0
	) => {
		if (!this.state.categories) {
			return null
		}

		return (categories ?? this.state.categories).map((category: any) => {
			if (!category) {
				return undefined
			}

			return (
				<React.Fragment key={category.id}>
					<ListItem
						dense={this.props.user?.denseMode}
						button
						onClick={() => {
							this.ToggleOption(category)
						}}
						style={{
							paddingLeft: `${paddingMultiplier * 1}rem`,
						}}
					>
						<Checkbox
							checked={
								this.state.selectedCategories?.indexOf(
									category.id
								) !== -1
							}
						/>
						<ListItemText primary={category.title} />
						<span />
						{category.categories && category.categories.length > 0 && (
							<ListItemSecondaryAction>
								<Tooltip
									title={
										<Typography variant="subtitle2">
											Select all
										</Typography>
									}
								>
									<IconButton
										onClick={() => {
											this.EditSubCategories(
												category.categories,
												category.id
											)
										}}
									>
										<DoneAllIcon />
									</IconButton>
								</Tooltip>
								<Tooltip
									title={
										<Typography variant="subtitle2">
											Clear all
										</Typography>
									}
								>
									<IconButton
										onClick={() => {
											this.EditSubCategories(
												category.categories,
												category.id,
												false
											)
										}}
									>
										<ClearAllIcon />
									</IconButton>
								</Tooltip>
								<Tooltip
									title={`Show ${
										this.state.toggledStates &&
										this.state.toggledStates[category.id]
											? "less"
											: "more"
									}`}
								>
									<IconButton
										onClick={() => {
											this.ToggleOptionMenu(category.id)
										}}
									>
										{this.state.toggledStates &&
										this.state.toggledStates[
											category.id
										] ? (
											<ExpandLessIcon />
										) : (
											<ExpandMoreIcon />
										)}
									</IconButton>
								</Tooltip>
							</ListItemSecondaryAction>
						)}
					</ListItem>
					{category.categories && category.categories.length > 0 && (
						<Collapse
							in={
								this.state.toggledStates &&
								this.state.toggledStates[category.id]
							}
							timeout="auto"
							unmountOnExit
						>
							<List dense={this.props.user?.denseMode}>
								{this.GetCategoryList(
									category.categories,
									paddingMultiplier + 1
								)}
							</List>
						</Collapse>
					)}
				</React.Fragment>
			)
		})
	}

	GetChannelList = () => {
		if (!this.state.channels) {
			return null
		}

		return Object.keys(this.state.channels)
			.sort((a: string, b: string) =>
				this.state.channels?.[a as any]?.localeCompare(
					this.state.channels?.[b as any]
				)
			)
			.map((key: any) => {
				if (!key) {
					return undefined
				}

				return (
					<ListItem
						key={`${key}:${this.state.channels?.[key]}`}
						dense={this.props.user?.denseMode}
						button
						onClick={() => {
							if (this.state.selectedChannels?.includes(key)) {
								this.setState({
									...this.state,
									selectedChannels: this.state.selectedChannels.filter(
										(selectedKey) => selectedKey !== key
									),
								})
							} else {
								if (this.state.selectedChannels !== undefined) {
									this.setState({
										...this.state,
										selectedChannels: [
											...this.state.selectedChannels,
											key,
										],
									})
								} else {
									this.setState({
										...this.state,
										selectedChannels: [key],
									})
								}
							}
						}}
					>
						<Checkbox
							checked={this.state.selectedChannels?.includes(key)}
						/>
						<ListItemText
							primary={`${key.split(":")[0]}: ${
								this.state.channels?.[key]
							}`}
						/>
					</ListItem>
				)
			})
	}

	OnChange = (event: any) => {
		this.setState({
			...this.state,
			[event.target.name]: event.target.value,
		})
	}

	OnFilter = () => {
		let filters: any = {}

		for (let key of Object.keys(this.state)) {
			if (Validators[key]) {
				let valid = true

				for (let validation of Validators[key]) {
					valid = validation((this.state as any)[key])
				}

				if (!valid) {
					continue
				}

				filters[key] = (this.state as any)[key]
			}
		}

		this.props.OnFilter?.(filters)
	}

	render() {
		return (
			<Grid container style={this.props.style}>
				<Grid item xs={12}>
					<Accordion
						id="item-filter"
						style={{ marginRight: "1px solid rgba(0, 0, 0, .125)" }}
						summary={
							<Typography variant="subtitle2">Filters</Typography>
						}
						details={
							<Grid
								container
								spacing={1}
								justify="space-between"
								alignContent="flex-end"
								style={{
									padding: "0 1rem 1rem 1rem",
								}}
							>
								<Grid
									item
									xs={12}
									container
									spacing={1}
									justify="flex-start"
								>
									<Grid item xs={12} md={6} lg={4}>
										<TextField
											fullWidth
											label="IDs"
											name="feedItemIds"
											onChange={(event: any) => {
												this.OnChange({
													target: {
														name: event.target.name,
														value:
															event.target.value.indexOf(
																","
															) !== -1
																? event.target.value.split(
																		","
																  )
																: [
																		event
																			.target
																			.value,
																  ],
													},
												})
											}}
											value={
												typeof this.state
													.feedItemIds !== "string"
													? this.state.feedItemIds?.join(
															","
													  )
													: this.state.feedItemIds
											}
										/>
									</Grid>
								</Grid>

								<Grid
									item
									xs={12}
									container
									spacing={1}
									justify="flex-start"
								>
									<Grid item xs={12} md={6} lg={4}>
										<TextField
											fullWidth
											label="Title"
											name="title"
											onChange={this.OnChange}
											value={this.state.title}
										/>
									</Grid>
									<Grid item xs={12} md={6} lg={4}>
										<TextField
											fullWidth
											label="Description"
											name="description"
											onChange={this.OnChange}
											value={this.state.description}
										/>
									</Grid>
									<Grid item xs={12} md={6} lg={4}>
										<FormControl style={{ width: "100%" }}>
											<InputLabel id="categories_labal">
												Categories
											</InputLabel>

											<Select
												multiple
												id="categories"
												name="selectedCategories"
												labelId="categories_labal"
												value={
													this.state
														.selectedCategories
												}
												renderValue={(
													selected: any
												) => {
													return selected
														.map((id: number) => {
															if (
																!this.state
																	.categories
															) {
																return undefined
															}

															let categoryObject = this.state.categories?.find(
																(category) =>
																	category.id ===
																	id
															)

															if (
																!categoryObject
															) {
																for (let category of this
																	.state
																	.categories) {
																	categoryObject = category.categories?.find(
																		(
																			subCategory: any
																		) => {
																			return (
																				subCategory.id ===
																				id
																			)
																		}
																	)

																	if (
																		categoryObject
																	) {
																		break
																	}
																}
															}

															if (
																!categoryObject
															) {
																return undefined
															}

															return categoryObject.title
														})
														.join(",")
												}}
											>
												<List
													dense={
														this.props.user
															?.denseMode
													}
												>
													{this.GetCategoryList() ?? (
														<ListItem>
															<CircularProgress />
														</ListItem>
													)}
												</List>
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6} lg={4}>
										<FormControl style={{ width: "100%" }}>
											<InputLabel id="channels_label">
												Channels
											</InputLabel>

											<Select
												multiple
												id="channels"
												name="selectedCategories"
												labelId="channels_label"
												value={
													this.state.selectedChannels
												}
												renderValue={(
													selected: any
												) => {
													return selected
														.map((key: number) => {
															if (
																this.state
																	.channels &&
																this.state
																	.channels[
																	key
																]
															) {
																return this
																	.state
																	.channels[
																	key
																]
															}
															return key
														})
														.join(",")
												}}
											>
												<List
													dense={
														this.props.user
															?.denseMode
													}
												>
													{this.GetChannelList() ?? (
														<ListItem>
															<CircularProgress />
														</ListItem>
													)}
												</List>
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6} lg={4}>
										<MuiPickersUtilsProvider
											utils={DateFnsUtils}
										>
											<DateTimePicker
												fullWidth
												clearable
												id="published_after"
												label="Published After"
												value={
													this.state.publishedAfter ??
													null
												}
												onChange={(date) => {
													this.OnChange({
														target: {
															name:
																"publishedAfter",
															value: date,
														},
													})
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
									<Grid item xs={12} md={6} lg={4}>
										<MuiPickersUtilsProvider
											utils={DateFnsUtils}
										>
											<DateTimePicker
												fullWidth
												clearable
												id="published_before"
												label="Published Before"
												value={
													this.state
														.publishedBefore ?? null
												}
												onChange={(date) => {
													this.OnChange({
														target: {
															name:
																"publishedBefore",
															value: date,
														},
													})
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									style={{
										textAlign: "left",
									}}
								>
									<FormControlLabel
										color="primary"
										name="onlyNotifyItems"
										label="Only on notify list"
										control={
											<Checkbox
												color="primary"
												name="onlyNotifyItems"
												aria-label="Only on notify list"
												onChange={(event) => {
													this.OnChange({
														target: {
															name:
																event.target
																	.name,
															value: !this.state
																.onlyNotifyItems,
														},
													})
												}}
												checked={
													this.state.onlyNotifyItems
												}
											/>
										}
									/>
									<FormControlLabel
										color="primary"
										name="onlyWatchListItems"
										label="Only on watch list"
										control={
											<Checkbox
												color="primary"
												name="onlyWatchListItems"
												aria-label="Only on watch list"
												onChange={(event) => {
													this.OnChange({
														target: {
															name:
																event.target
																	.name,
															value: !this.state
																.onlyWatchListItems,
														},
													})
												}}
												checked={
													this.state
														.onlyWatchListItems
												}
											/>
										}
									/>
									<FormControlLabel
										color="primary"
										name="hideLocalFeed"
										label="Hide Local Feed"
										control={
											<Checkbox
												color="primary"
												name="hideLocalFeed"
												aria-label="Hide Local Feed"
												onChange={(event) => {
													this.OnChange({
														target: {
															name:
																event.target
																	.name,
															value: !this.state
																.hideLocalFeed,
														},
													})
												}}
												checked={
													this.state.hideLocalFeed
												}
											/>
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									style={{
										textAlign: "right",
										marginTop: "1rem",
									}}
								>
									<Button
										variant="outlined"
										onClick={this.OnFilter}
									>
										Filter
									</Button>
								</Grid>
							</Grid>
						}
					/>
				</Grid>
			</Grid>
		)
	}
}

function mapStateToProps(state: RootState, props: any): FilterProps {
	return {
		OnFilter: props.OnFilter,
		user: state.user,
	}
}

function mapDispatchToProps(dispatch: Dispatch): FilterActionProps {
	return bindActionCreators({ createNotification }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)

