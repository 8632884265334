import { RootState } from ".."
import { ThunkDispatch } from "redux-thunk"
import { del, get, post } from "../../plugins/api"
import { types as DialogTypes } from "../dialog/actions"
import { createNotification } from "../notification/actions"

export enum types {
	GET_PROVIDERS = "GET_PROVIDERS",
	MOVE_PROVIDER = "MOVE_PROVIDER",
	CREATE_PROVIDER = "CREATE_PROVIDER",
	DELETE_PROVIDER = "DELETE_PROVIDER",
	UPDATE_PROVIDER = "UPDATE_PROVIDER",
	TOGGLE_LOADING = "TOGGLE_LOADING",
}

export const getProviders = () => async (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	try {
		dispatch({
			type: types.TOGGLE_LOADING,
			payload: true,
		})

		const providers = await get("/v1/providers")

		setTimeout(() => {
			dispatch({
				type: types.TOGGLE_LOADING,
				payload: false,
			})

			dispatch({
				type: types.GET_PROVIDERS,
				payload: providers,
			})
		}, 1000)
	} catch (error) {
		console.error(error)

		dispatch({
			type: types.TOGGLE_LOADING,
			payload: false,
		})

		dispatch(
			createNotification({
				message: "Something went wrong, please try again later.",
				severity: "error",
			})
		)
	}
}

export const createProvider = (provider: any) => async (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	try {
		dispatch({
			type: types.TOGGLE_LOADING,
			payload: true,
		})

		provider = await post("/v1/providers", provider)

		setTimeout(() => {
			dispatch({
				type: types.TOGGLE_LOADING,
				payload: false,
			})

			dispatch({
				type: types.CREATE_PROVIDER,
				payload: provider,
			})
		}, 1000)
	} catch (error) {
		console.error(error)

		dispatch({
			type: types.TOGGLE_LOADING,
			payload: false,
		})
		dispatch(
			createNotification({
				message: "Something went wrong, please try again later.",
				severity: "error",
			})
		)
	}
}

export const deleteProvider = (id: string | number) => async (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	try {
		dispatch({
			type: types.TOGGLE_LOADING,
			payload: true,
		})

		await del(`/v1/providers/${id}`)
		setTimeout(() => {
			dispatch({
				type: types.TOGGLE_LOADING,
				payload: false,
			})

			dispatch({
				type: types.DELETE_PROVIDER,
				payload: id,
			})
		}, 1000)
	} catch (error) {
		console.error(error)

		dispatch({
			type: types.TOGGLE_LOADING,
			payload: false,
		})
		dispatch(
			createNotification({
				message: error,
				severity: "error",
			})
		)
	}
}

export const generateChannels = (
	id: string | number | undefined = undefined
) => async (dispatch: ThunkDispatch<RootState, any, any>) => {
	try {
		dispatch({
			type: types.TOGGLE_LOADING,
			payload: true,
		})

		await get(`/v1/providers/generateChannels${id ? `/${id}` : ""}`)
		setTimeout(() => {
			dispatch({
				type: types.TOGGLE_LOADING,
				payload: false,
			})
		}, 1000)
	} catch (error) {
		console.error(error)

		dispatch({
			type: types.TOGGLE_LOADING,
			payload: false,
		})
		dispatch(
			createNotification({
				message: error,
				severity: "error",
			})
		)
	}

	dispatch({
		type: DialogTypes.TOGGLE_DIALOG,
	})
}

export const rearrangeProviders = (
	index: number,
	goingDown: boolean = false
) => (dispatch: ThunkDispatch<RootState, any, any>) => {
	try {
		dispatch({
			type: types.MOVE_PROVIDER,
			payload: {
				index,
				goingDown,
			},
		})
	} catch (error) {
		console.error(error)
		dispatch({
			type: types.TOGGLE_LOADING,
			payload: false,
		})
		dispatch(
			createNotification({
				message: error,
				severity: "error",
			})
		)
	}
}

export const updateProvider = (data: any, toggle: boolean = true) => async (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	try {
		if (toggle) {
			dispatch({
				type: types.TOGGLE_LOADING,
				payload: true,
			})
		}

		const provider = await post(
			`/v1/providers/${data.provider.id ?? data.id}`,
			data
		)

		setTimeout(() => {
			if (toggle) {
				dispatch({
					type: types.TOGGLE_LOADING,
					payload: false,
				})
			}

			dispatch({
				type: types.UPDATE_PROVIDER,
				payload: provider,
			})
		}, 1000)
	} catch (error) {
		console.error(error)

		if (toggle) {
			dispatch({
				type: types.TOGGLE_LOADING,
				payload: true,
			})
		}

		dispatch(
			createNotification({
				message: "Something went wrong, please try again later.",
				severity: "error",
			})
		)
	}
}

export const setLoading = (isLoading: boolean = false) => async (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	try {
		dispatch({
			type: types.TOGGLE_LOADING,
			payload: isLoading,
		})
	} catch (error) {
		console.error(error)

		dispatch(
			createNotification({
				message: error,
				severity: "error",
			})
		)
	}
}
