import { Action } from ".."
import { types as NOTIFY } from "./actions"

export interface Notify {
	title: string
	test: boolean | string
	sent: boolean
	image?: string
	loading: boolean
	description: string
	topics: Array<string>
	selectedItems: Array<number>
}

const initalState: Notify = {
	title: "",
	topics: ["Snibble_Recommends"],
	test: "true",
	sent: false,
	loading: false,
	description: "",
	selectedItems: [],
}

interface IAction extends Action {
	type: any
	payload: any
}

export default function reducer(state = initalState, action: IAction) {
	switch (action.type) {
		case NOTIFY.TOGGLE_ITEM:
			if (state.selectedItems.includes(action.payload)) {
				return {
					...state,
					selectedItems: state.selectedItems.filter(
						(item) => item !== action.payload
					),
				}
			} else {
				return {
					...state,
					selectedItems: [...state.selectedItems, action.payload],
				}
			}
		case NOTIFY.ON_CHANGE:
			// if (action.payload.target.name === "topics") {
			// 	action.payload.target.value = [action.payload.target.value]
			// }

			return {
				...state,
				[action.payload.target.name]:
					JSON.stringify(
						(state as any)[action.payload.target.name]
					) === JSON.stringify(action.payload.target.value)
						? null
						: action.payload.target.value,
			}
		case NOTIFY.TOGGLE_SENT:
			return {
				...state,
				sent: !state.sent,
			}

		case NOTIFY.TOGGLE_TESTING:
			console.log(action)
			return {
				...state,
				test: action.payload,
			}
		case NOTIFY.TOGGLE_LOADING:
			return {
				...state,
				loading: !state.loading,
			}
		case NOTIFY.RESET:
			console.log("asd")

			return {
				...state,
				title: "",
				sent: false,
				description: "",
				selectedItems: [],
			}
		default:
			return state
	}
}
