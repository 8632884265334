import { Action } from ".."
import { Moment } from "moment"
import { types as FEED_TYPES } from "./actions"

interface Base {
	[key: string]: any
}

export interface Item extends Base {
	id: number
	feed_provider_url_id: number
	uuid: string
	guid: string
	title: string
	description: string
	aggregation_channel_marker: string
	url: string
	url_thumbnail: string
	video_resolution: string
	duration: number
	media_credit_id: number
	published_at: Date
	record_status: number
	created: Date
	created_by: number
	last_update_date: Date
	last_update_by: number
	ip_created: string
	ip_updated: string
}

export interface Provider extends Base {
	id: number
	title: string
	description: string
	media_credit_logo_filename: string
	aggregation_user_parameters: string
	sort_index: number
	record_status: number
	created: Date
	created_by: number
	last_update_date: Date
	last_update_by: number
	ip_created: string
	ip_updated: string
	urls: Array<ProviderUrl>
}

export interface ProviderUrl extends Base {
	id: number
	feed_provider_id: number
	url: string
	token: string
	aggregation_user_parameters: string
	use_aws_mediaconvert: number
	url_required: number
	last_crawled: Date
	record_status: number
	created: Date
	created_by: number
	last_update_date: Date
	last_update_by: number
	ip_created: string
	ip_updated: string
}

export interface Feed extends Base {
	loadedAt: Moment | null
	loading: boolean
	pageCount: number
	items: Array<Item>
	providers: Array<Provider>
}

const initalState: Feed = {
	items: [],
	pageCount: 0,
	providers: [],
	loading: false,
	loadedAt: null,
}

interface IAction extends Action {
	type: any
	payload: any
}

export default function reducer(state = initalState, action: IAction) {
	switch (action.type) {
		case FEED_TYPES.SET_LOADING:
			return {
				...state,
				loading: action.payload,
			}
		case FEED_TYPES.TOGGLE_LOADING:
			return {
				...state,
				loading: !state.loading,
			}
		case FEED_TYPES.GET_ITEMS:
			return {
				...state,
				loadedAt: new Date(),
				items: action.payload.results,
				pageCount: Math.ceil(action.payload.count),
			}
		case FEED_TYPES.GET_PROVIDERS:
			return {
				...state,
				providers: action.payload,
			}
		case FEED_TYPES.UPDATE_ITEMS:
			return {
				...state,
				items: state.items.map((item) => {
					item[action.payload.field] = action.payload.value
					return item
				}),
			}
		case FEED_TYPES.TOGGLE_VISIBILITY:
			return {
				...state,
				items: state.items.filter((item) => {
					return item.id !== action.payload
				}),
			}
		default:
			return state
	}
}
