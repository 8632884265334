import { connect } from 'react-redux';
import Dialog from '../../utils/dialog';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import {
    toggleEditing,
    setEditIndex,
    getPermissions,
    saveUser,
    toggleUserRole,
} from '../../../store/permissions/actions';
import Table from '@material-ui/core/Table';
import TypeAhead from '../../utils/typeahead';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import { bindActionCreators, Dispatch } from 'redux';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Permissions, Role, User } from '../../../store/permissions/reducer';

interface UsersProps {
    permissions?: Permissions;
    newUser?: any;
    toggleEditing?: typeof toggleEditing;
    saveUser?: typeof saveUser;
    setEditIndex?: typeof setEditIndex;
    toggleUserRole?: typeof toggleUserRole;
}

interface UsersActionProps {
    toggleEditing?: typeof toggleEditing;
    saveUser?: typeof saveUser;
    setEditIndex?: typeof setEditIndex;
    toggleUserRole?: typeof toggleUserRole;
}

export class Users extends Component<UsersProps, UsersProps, UsersActionProps> {
    constructor(props: UsersProps) {
        super(props);

        this.state = {
            newUser: {
                id: null,
                name: '',
                description: '',
                roles: [],
                created_at: null,
                updated_at: null,
                deleted_at: null,
                deleted_by: null,
            },
        };
    }

    GetEditButtons = (index: number) => {
        return (
            <>
                <IconButton
                    onClick={() => {
                        this.OnEdit(index);
                    }}
                >
                    <EditIcon />
                </IconButton>
                <IconButton>
                    <DeleteIcon />
                </IconButton>
            </>
        );
    };

    GetFormatedDate = (_id: number, value: Date | string | number) => {
        try {
            return new Date(value).toLocaleString();
        } catch (error) {
            console.error(error);
            return value;
        }
    };

    GetUserForm = (index: number | null = null) => {
        return (
            <Grid container justify="space-evenly" spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        onChange={this.OnChange}
                        value={
                            (this.props.permissions?.editIndex &&
                                this.props.permissions?.users[
                                    this.props.permissions.editIndex
                                ].name) ??
                            this.state.newUser?.name
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        fullWidth
                        name="description"
                        label="Description"
                        onChange={this.OnChange}
                        value={
                            (this.props.permissions?.editIndex &&
                                this.props.permissions?.users[
                                    this.props.permissions.editIndex
                                ].description) ??
                            this.state.newUser?.description
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TypeAhead
                        name="roles"
                        label="Roles"
                        labelKey="name"
                        OnChange={this.OnChange}
                        defaultValue={
                            (this.props.permissions?.editIndex &&
                                this.props.permissions?.users[
                                    this.props.permissions.editIndex
                                ].roles) ??
                            this.state.newUser.roles
                        }
                        options={this.props.permissions?.resources ?? []}
                    />
                </Grid>
            </Grid>
        );
    };

    GetUserRoles = (user: User) => {
        return user.roles.map((role: Role) => {
            return (
                <Tooltip
                    key={Math.random()}
                    title={
                        <Typography variant="subtitle2">
                            {role.description}
                        </Typography>
                    }
                >
                    <Chip
                        color="primary"
                        variant="outlined"
                        label={role.name}
                        style={{
                            margin: '0.25rem',
                        }}
                    />
                </Tooltip>
            );
        });
    };

    GetUsers = () => {
        let headers = [
            { key: 'id', label: 'ID' },
            { key: 'name', label: 'Name' },
            { key: 'description', label: 'Description' },
            {
                key: 'roles',
                label: 'Users',
                pipe: this.GetUserRoles,
            },
            {
                key: 'created_at',
                label: 'Date Created',
                pipe: this.GetFormatedDate,
            },
            {
                key: 'updated_at',
                label: 'Last Updated At',
                pipe: this.GetFormatedDate,
            },
            {
                label: '',
                content: this.GetEditButtons,
            },
        ];

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((header: any) => {
                            return (
                                <TableCell key={header.label ?? header}>
                                    {header.label ?? header}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.permissions?.users.map(
                        (user: User, index: number) => {
                            if (!user) {
                                return undefined;
                            }

                            return (
                                <TableRow key={Math.random()}>
                                    {headers.map((header: any) => {
                                        if (!header.label && header.content) {
                                            return (
                                                <TableCell
                                                    key={
                                                        Math.random() +
                                                        (header.key ?? header)
                                                    }
                                                >
                                                    {header.content(index)}
                                                </TableCell>
                                            );
                                        }

                                        if (!user[header.key ?? header]) {
                                            return (
                                                <TableCell
                                                    key={
                                                        Math.random() +
                                                        (header.key ?? header)
                                                    }
                                                ></TableCell>
                                            );
                                        }

                                        return (
                                            <TableCell
                                                key={
                                                    Math.random() +
                                                    (header.key ?? header)
                                                }
                                            >
                                                {(header.pipe &&
                                                    header.pipe(
                                                        user,
                                                        user[
                                                            header.key ?? header
                                                        ]
                                                    )) ??
                                                    user[header.key ?? header]}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        }
                    )}
                </TableBody>
            </Table>
        );
    };

    OnCancel = () => {
        this.setState(
            {
                newUser: {
                    id: null,
                    name: '',
                    description: '',
                    roles: [],
                    created_at: null,
                    updated_at: null,
                    deleted_at: null,
                    deleted_by: null,
                },
            },
            () => {
                this.props.toggleEditing?.();
                this.props.setEditIndex?.(null);
            }
        );
    };

    OnChange = (event: any) => {
        this.setState({
            ...this.state,
            newUser: {
                ...this.state.newUser,
                [event.target.name]: event.target.value,
            },
        });
    };

    OnEdit = (index: number) => {
        this.props.setEditIndex?.(index);
        this.props.toggleEditing?.();
    };

    OnSave = () => {
        // this.props.addUser?.(this.state.newUser);
        this.OnCancel();
    };

    render() {
        return (
            <>
                <Dialog
                    fullWidth
                    open={this.props.permissions?.editing}
                    title="New User "
                    content={this.GetUserForm()}
                    actions={
                        <>
                            <Button
                                size="small"
                                color="default"
                                variant="contained"
                                onClick={this.OnCancel}
                                startIcon={<CancelIcon />}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={this.OnSave}
                                startIcon={<EditIcon />}
                            >
                                Save
                            </Button>
                        </>
                    }
                />
                {this.GetUsers()}
            </>
        );
    }
}

function mapStateToProps(state: any): UsersProps {
    return { permissions: state.permissions };
}

function mapDispatchToProps(dispatch: Dispatch): UsersActionProps {
    return bindActionCreators(
        {
            saveUser,
            setEditIndex,
            toggleEditing,
            getPermissions,
            toggleUserRole,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
