import { ThunkDispatch } from 'redux-thunk';
import { Action, RootState } from '..';

export enum types {
    SET_ENVIRONMENT = 'SET_ENVIRONMENT',
}

export enum Environment {
    'dev' = 'dev',
    'staging' = 'staging',
    'prod' = 'prod',
}

const initialState: Environment | string =
    localStorage.getItem('environment') ?? Environment.prod;

export const setEnvironment = (environment: string) => async (
    dispatch: ThunkDispatch<RootState, Environment, any>
) => {
    await dispatch({
        type: types.SET_ENVIRONMENT,
        payload: environment,
    });
    
    window.location.reload();
};

export default function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case types.SET_ENVIRONMENT:
            localStorage.setItem('environment', action.payload);

            return action.payload;
        default:
            return state;
    }
}
