import { RootState } from '..';
import { Drawer } from './reducer';
import { ThunkDispatch } from 'redux-thunk';

export enum types {
    ADD_DRAWER = 'ADD_DRAWER',
    TOGGLE_DRAWER = 'TOGGLE_DRAWER',
}

export const toggleDrawer = (id: string | undefined) => (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    dispatch({
        type: types.TOGGLE_DRAWER,
        payload: id,
    });
};

export const addDrawer = (drawer: Drawer) => (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    dispatch({
        type: types.ADD_DRAWER,
        payload: drawer,
    });
};
