import { RootState } from '..';
import { get, post } from '../../plugins/api';
import { Permissions, ResourceGroup, Role, User, UserGroup } from './reducer';
import { ThunkDispatch } from 'redux-thunk';
import { createNotification } from '../notification/actions';

export enum types {
    SET_TAB = 'SET_TAB',
    ADD_ROLE = 'ADD_ROLE',
    GET_USERS = 'GET_USERS',
    GET_ROLES = 'GET_ROLES',
    TOGGLE_ROLE = 'TOGGLE_ROLE',
    TOGGLE_EDITING = 'TOGGLE_EDITING',
    SET_EDIT_INDEX = 'SET_EDIT_INDEX',
    ADD_USER_GROUP = 'ADD_USER_GROUP',
    GET_ADMIN_ITEMS = 'GET_ADMIN_ITEMS',
    GET_USER_GROUPS = 'GET_USER_GROUPS',
    ADD_RESOURCE_GROUP = 'ADD_RESOURCE_GROUP',
    GET_RESOURCE_GROUPS = 'GET_RESOURCE_GROUPS',
    TOGGLE_USER_ROLE = 'TOGGLE_USER_ROLE',
    TOGGLE_USER_GROUP_ROLE = 'TOGGLE_USER_GROUP_ROLE',
    TOGGLE_RESOURCE_GROUP_ROLE = 'TOGGLE_RESOURCE_GROUP_ROLE',
}

export const addRole = (newRole: Role) => async (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    dispatch({
        type: types.ADD_ROLE,
        payload: newRole,
    });
};

export const setEditIndex = (index: number | null) => async (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    dispatch({
        type: types.SET_EDIT_INDEX,
        payload: index,
    });
};

export const setTab = (tab: string) => (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    dispatch({
        type: types.SET_TAB,
        payload: tab,
    });
};

export const addResourceGroup = (newResourceGroup: ResourceGroup) => async (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    dispatch({
        type: types.ADD_RESOURCE_GROUP,
        payload: newResourceGroup,
    });
};

export const addUserGroup = (newUserGroup: UserGroup) => async (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    dispatch({
        type: types.ADD_USER_GROUP,
        payload: newUserGroup,
    });
};

export const getPermissions = () => async (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    Promise.all([
        get('/v1/admin/roles')
            .then((items) => {
                dispatch({
                    type: types.GET_ROLES,
                    payload: items,
                });
            })
            .catch((message) => {
                dispatch(
                    createNotification({
                        message,
                        severity: 'error',
                    })
                );
                localStorage.setItem('redirect', window.location.pathname);
                    window.location.href = '/v1/auth/google';
            }),
        get('/v1/admin/resource-groups')
            .then((items) => {
                dispatch({
                    type: types.GET_RESOURCE_GROUPS,
                    payload: items.map((item: any) => {
                        return {
                            ...item,
                            roles: item.roles.map((role: string) =>
                                JSON.parse(role)
                            ),
                        };
                    }),
                });
            })
            .catch((message) => {
                dispatch(
                    createNotification({
                        message,
                        severity: 'error',
                    })
                );
                localStorage.setItem('redirect', window.location.pathname);
                    window.location.href = '/v1/auth/google';
            }),
        get('/v1/admin/users')
            .then((items) => {
                dispatch({
                    type: types.GET_USERS,
                    payload: items.map((item: any) => {
                        return {
                            ...item,
                            roles: item.roles.map((role: string) =>
                                JSON.parse(role)
                            ),
                        };
                    }),
                });
            })
            .catch((message) => {
                dispatch(
                    createNotification({
                        message,
                        severity: 'error',
                    })
                );
            }),
        get('/v1/admin/user-groups')
            .then((items) => {
                dispatch({
                    type: types.GET_USER_GROUPS,
                    payload: items.map((item: any) => {
                        return {
                            ...item,
                            roles: item.roles.map((role: string) =>
                                JSON.parse(role)
                            ),
                        };
                    }),
                });
            })
            .catch((message) => {
                dispatch(
                    createNotification({
                        message,
                        severity: 'error',
                    })
                );
                localStorage.setItem('redirect', window.location.pathname);
                    window.location.href = '/v1/auth/google';
            }),
    ]);
};

export const saveResourceGroup = (resourceGroup: ResourceGroup) => async (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    post(`/v1/admin/resource-groups/${resourceGroup.id}`, resourceGroup)
        .then((_result) => {
            dispatch(
                createNotification({
                    message: 'Resouce group was updated successfully.',
                    severity: 'success',
                })
            );
        })
        .catch((message) => {
            dispatch(
                createNotification({
                    message,
                    severity: 'error',
                })
            );
        });
};

export const saveUserGroup = (userGroup: UserGroup) => async (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    post(`/v1/admin/user-groups/${userGroup.id}`, userGroup)
        .then((_result) => {
            dispatch(
                createNotification({
                    message: 'User group was updated successfully.',
                    severity: 'success',
                })
            );
        })
        .catch((message) => {
            dispatch(
                createNotification({
                    message,
                    severity: 'error',
                })
            );
        });
};

export const saveUser = (user: User) => async (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    post(`/v1/admin/users/${user.id}`, user)
        .then((_result) => {
            dispatch(
                createNotification({
                    message: 'User was updated successfully.',
                    severity: 'success',
                })
            );
        })
        .catch((message) => {
            dispatch(
                createNotification({
                    message,
                    severity: 'error',
                })
            );
        });
};

export const toggleEditing = () => (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    dispatch({
        type: types.TOGGLE_EDITING,
    });
};

export const toggleResourceGroupRole = (id: number, roles: any) => (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    dispatch({
        type: types.TOGGLE_RESOURCE_GROUP_ROLE,
        payload: {
            id,
            roles,
        },
    });
};

export const toggleUserGroupRole = (id: number, roles: any) => (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    dispatch({
        type: types.TOGGLE_USER_GROUP_ROLE,
        payload: {
            id,
            roles,
        },
    });
};

export const toggleUserRole = (id: number, roles: any) => (
    dispatch: ThunkDispatch<RootState, Permissions, any>
) => {
    dispatch({
        type: types.TOGGLE_USER_ROLE,
        payload: {
            id,
            roles,
        },
    });
};
