import "./style.scss"
import {
	setLoading,
	getProviders,
	createProvider,
	deleteProvider,
	updateProvider,
	generateChannels,
	rearrangeProviders,
} from "../../store/providers/actions"
import "react-virtualized/styles.css"
import { RootState } from "../../store"
import Tab from "@material-ui/core/Tab"
import Grid from "@material-ui/core/Grid"
import Tabs from "@material-ui/core/Tabs"
import Table from "@material-ui/core/Table"
import Button from "@material-ui/core/Button"
import EditIcon from "@material-ui/icons/Edit"
import Backdrop from "@material-ui/core/Backdrop"
import TableRow from "@material-ui/core/TableRow"
import DeleteIcon from "@material-ui/icons/Delete"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TextField from "@material-ui/core/TextField"
import { AutoSizer, List } from "react-virtualized"
import Typography from "@material-ui/core/Typography"
import { useDispatch, useSelector } from "react-redux"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import { useParams, useHistory } from "react-router-dom"
import {
	Checkbox,
	FormControlLabel,
	ListItem,
	ListItemText,
} from "@material-ui/core"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import { Channel, Provider } from "../../store/providers/reducer"
import CircularProgress from "@material-ui/core/CircularProgress"
import { setContent, toggleDialog } from "../../store/dialog/actions"
import React, { CSSProperties, useEffect, useRef, useState } from "react"
import TvIcon from "@material-ui/icons/Tv"

export interface ProvidersProps {}
export interface ProvidersActionProps {}

interface AggregationUserParameter {
	label: string
	value: string
}

class Row extends React.Component<any, any, any> {
	shouldComponentUpdate() {
		return true
	}

	render() {
		console.log(this.props.style)
		if (this.props.children) {
			return (
				<div
					style={{
						...this.props.style,
						margin: "3rem auto",
					}}
				>
					<EditChannel
						onChange={this.props.onChange}
						onDelete={this.props.onDelete}
						Channel={this.props.channel}
					/>
					<span id="channelScrollTo" />
				</div>
			)
		} else {
			return (
				<EditChannel
					style={{
						...this.props.style,
						margin: "3rem auto",
					}}
					onChange={this.props.onChange}
					onDelete={this.props.onDelete}
					Channel={this.props.channel}
				/>
			)
		}
	}
}

function EditAggregationUserParameter({
	onChange,
	onDelete,
	AggregationUserParameter,
}: {
	onChange: any
	onDelete: any
	AggregationUserParameter: AggregationUserParameter
}) {
	const [
		aggregationUserParameter,
		setAggregationUserParameter,
	] = useState<AggregationUserParameter>({
		label: "",
		value: "",
	})

	useEffect(() => {
		setAggregationUserParameter(AggregationUserParameter)
	}, [])

	useEffect(() => {
		onChange(aggregationUserParameter)
	}, [aggregationUserParameter])

	return (
		<Grid
			item
			xs={12}
			container
			justify="space-between"
			style={{ marginTop: "1rem" }}
		>
			<Grid item xs={12} md={5}>
				<TextField
					fullWidth
					label="Label"
					value={aggregationUserParameter.label}
					onChange={(event) =>
						setAggregationUserParameter({
							label: event.target.value,
							value: aggregationUserParameter.value,
						})
					}
				/>
			</Grid>
			<Grid item xs={12} md={5}>
				<TextField
					fullWidth
					label="Value"
					value={aggregationUserParameter.value}
					onChange={(event) =>
						setAggregationUserParameter({
							label: aggregationUserParameter.label,
							value: event.target.value,
						})
					}
				/>
			</Grid>
			<Grid
				item
				md={1}
				xs={12}
				container
				justify="center"
				alignItems="center"
				alignContent="center"
			>
				<Button onClick={onDelete} color="secondary" variant="outlined">
					<DeleteIcon />
				</Button>
			</Grid>
		</Grid>
	)
}

function EditChannel({
	onChange,
	onDelete,
	Channel = { name: "", marker: "", follow: true },
	style,
}: {
	onChange: any
	onDelete: any
	Channel: Channel
	style?: CSSProperties
}) {
	const [channel, setChannel] = useState<Channel>(Channel)

	useEffect(() => {
		onChange(channel)
	}, [channel])

	return (
		<Grid item xs={12} container justify="space-between" style={style}>
			<Grid item xs={12} md={4}>
				<TextField
					fullWidth
					label="Marker"
					value={channel?.marker ?? ""}
					onChange={(event) =>
						setChannel({
							...channel,
							marker: event.target.value,
						})
					}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextField
					fullWidth
					label="Channel Name"
					value={channel?.name ?? ""}
					onChange={(event) =>
						setChannel({
							...channel,
							name: event.target.value,
						})
					}
				/>
			</Grid>
			<Grid item xs={12} md={1}>
				<TextField
					fullWidth
					type="number"
					label="Max Age"
					value={channel?.maxDaysOld ?? 0}
					onChange={(event) =>
						setChannel({
							...channel,
							maxDaysOld: parseInt(event.target.value ?? 0),
						})
					}
				/>
			</Grid>
			<Grid
				item
				md={2}
				xs={12}
				container
				justify="space-evenly"
				alignItems="center"
				alignContent="center"
			>
				<FormControlLabel
					control={
						<Checkbox
							onChange={() =>
								setChannel({
									...channel,
									follow: !channel.follow,
								})
							}
							value={channel.follow}
							style={{ paddingTop: 0 }}
						/>
					}
					label="Follow"
					labelPlacement="top"
				/>

				<Button onClick={onDelete} color="secondary" variant="outlined">
					<DeleteIcon />
				</Button>
			</Grid>
		</Grid>
	)
}

export default function Providers(_props: ProvidersProps) {
	const listRef = useRef(null)

	const [title, setTitle] = useState<string | null>(null)
	const [
		aggregationUserParameters,
		setAggregationUserParameters,
	] = useState<Array<AggregationUserParameter> | null>(null)
	const [tab, setTab] = useState<string | null>("providers")
	const [id, setId] = useState<number | undefined>(undefined)
	const [sortIndex, setSortIndex] = useState<number | null>(null)
	const [mediaCreditLogoName, setMediaCreditLogoName] = useState<
		string | null
	>(null)
	const [description, setDescription] = useState<string | null>(null)
	const [recordStatus, setRecordStatus] = useState<number | null>(null)
	const [channels, setChannels] = useState<Array<Channel> | null>(null)
	const [scrollToIndex, setScrollToIndex] = useState<number | undefined>(
		undefined
	)

	const urlParams: {
		id: string | undefined
		tab: string | undefined
	} = useParams()

	const history = useHistory()
	const dispatch = useDispatch()

	const { user, providers, isLoading } = useSelector((state: RootState) => {
		return {
			user: state.user,
			isLoading: state.providers.loading,
			providers: state.providers.providers,
		}
	})

	function ClearProviderObject() {
		setTitle(null)
		setId(undefined)
		setChannels(null)
		setDescription(null)
		setAggregationUserParameters(null)
	}

	function AddProvider() {
		return (
			<Grid
				item
				xs={12}
				container
				justify="flex-start"
				style={{ height: "fit-content" }}
			>
				<Grid item xs={12} container justify="space-evenly" spacing={3}>
					<Grid item xs={12} md={8}>
						<TextField
							fullWidth
							label="Title"
							value={title ?? ""}
							onChange={(event) => setTitle(event.target.value)}
						/>
					</Grid>

					<Grid item xs={12} md={8}>
						<TextField
							fullWidth
							label="Description"
							value={description ?? ""}
							onChange={(event) =>
								setDescription(event.target.value)
							}
						/>
					</Grid>

					<Grid item xs={12} md={8} container spacing={2}>
						<Grid item xs={12} md={4}>
							<TextField
								fullWidth
								label="Media Credit Logo Name"
								value={mediaCreditLogoName ?? ""}
								onChange={(event) =>
									setMediaCreditLogoName(event.target.value)
								}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField
								fullWidth
								type="number"
								label="Sort index"
								value={sortIndex ?? 0}
								onChange={(event) =>
									setSortIndex(
										parseInt(event.target.value ?? 0)
									)
								}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField
								fullWidth
								type="number"
								label="Record Status"
								value={recordStatus ?? 0}
								onChange={(event) =>
									setRecordStatus(
										parseInt(event.target.value ?? 0)
									)
								}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12} md={8}>
						<Grid item xs={12}>
							<Typography variant="body1">
								Aggregation User Parameters
							</Typography>
						</Grid>
						{aggregationUserParameters?.map(
							(aggregationUserParameter, index) => (
								<EditAggregationUserParameter
									onChange={(event: any) =>
										setAggregationUserParameters((prev) => {
											prev?.splice(index, 1, event)
											return prev
										})
									}
									onDelete={() => {
										setAggregationUserParameters((prev) => {
											prev?.splice(index, 1)
											console.log(prev)
											return [...(prev ?? [])]
										})
									}}
									AggregationUserParameter={
										aggregationUserParameter
									}
								/>
							)
						)}
						<Button
							style={{
								marginTop: "1rem",
							}}
							onClick={() =>
								setAggregationUserParameters((prev) => [
									...(prev ?? []),
									{ label: "", value: "" },
								])
							}
						>
							Add Parameter
						</Button>
					</Grid>

					<Grid item xs={12} md={8}>
						<Grid item xs={12}>
							<Typography variant="h4">Channels</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								width: "100%",
								height: "100%",
								maxHeight: "500px",
								overflowY: "scroll",
							}}
						>
							<AutoSizer
								style={{
									width: "100%",
									height: "100%",
								}}
							>
								{({ height, width }) => {
									return (
										<List
											ref={listRef}
											width={width}
											height={height}
											itemData={channels}
											scrollToIndex={scrollToIndex}
											onScroll={() =>
												setScrollToIndex(undefined)
											}
											rowHeight={
												(user?.denseMode && 55) || 72
											}
											rowCount={
												(channels?.length ?? 0) + 1
											}
											rowRenderer={(props) => {
												if (
													props.index >
													(channels?.length ?? 0) - 1
												) {
													return undefined
												}
												return (
													<Row
														{...props}
														key={
															channels?.[
																props.index
															]?.id ??
															channels?.[
																props.index
															]?.name
														}
														denseMode={
															user?.denseMode
														}
														channel={
															channels?.[
																props.index
															]
														}
														onChange={(
															event: any
														) =>
															setChannels(
																(prev) => {
																	prev?.splice(
																		props.index,
																		1,
																		event
																	)
																	return prev
																}
															)
														}
														onDelete={() => {
															setChannels(
																(prev) => {
																	prev?.splice(
																		props.index,
																		1
																	)
																	return [
																		...(prev ??
																			[]),
																	]
																}
															)
														}}
													/>
												)
											}}
										/>
									)
								}}
							</AutoSizer>
						</Grid>
						{/* {channels
							?.filter((channel) =>
								channelFilter.length > 0
									? channel?.name.includes(channelFilter) ||
									  channel?.marker.includes(channelFilter)
									: true
							)
							.map((channel, index) => (
								<EditChannel
									style={{
										margin: "3rem auto",
									}}
									onChange={(event: any) =>
										setChannels((prev) => {
											prev?.splice(index, 1, event)
											return prev
										})
									}
									onDelete={() => {
										setChannels((prev) => {
											prev?.splice(index, 1)
											return [...(prev ?? [])]
										})
									}}
									Channel={channel}
								/>
							))} */}
						<Button
							style={{
								marginTop: "1rem",
							}}
							onClick={() => {
								setChannels((prev) => {
									prev = [
										...(prev ?? []),
										{ marker: "", name: "", follow: true },
									]
									setScrollToIndex(prev.length)
									return prev
								})
							}}
						>
							Add Channel
						</Button>
					</Grid>

					<Grid item xs={12} md={8}>
						<Button
							style={{
								margin: "auto 1rem",
							}}
							color="primary"
							variant="contained"
							onClick={CreateProvider}
							disabled={!title}
						>
							{id ? "Save" : "Add"}
						</Button>

						<Button
							style={{
								margin: "auto 1rem",
							}}
							variant="contained"
							onClick={() => {
								ClearProviderObject()
								history.push("/providers")
								setTab("providers")
							}}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Grid>
		)
	}

	async function CreateProvider() {
		if (!title) {
			console.log(title)
			return
		}

		const data = {
			provider: {
				id: id ?? undefined,
				title,
				description,
				aggregation_user_parameters: JSON.stringify(
					aggregationUserParameters?.reduce(
						(
							accumulator: any,
							current: AggregationUserParameter
						) => {
							if (current.label && current.value) {
								accumulator[current.label] = current.value
							}

							return accumulator
						},
						{}
					)
				),
				sort_index: sortIndex,
				media_credit_logo_filename: mediaCreditLogoName,
				record_status: recordStatus,
			},
			channels,
		}

		if (id) {
			await dispatch(updateProvider(data))
		} else {
			await dispatch(createProvider(data))
		}

		ClearProviderObject()
		history.push("/providers")
		setTab("providers")
	}

	async function DeleteProvider(id: string | number) {
		await dispatch(
			setContent(
				<Grid container justify="space-around">
					<Grid item xs={12}>
						<Typography
							variant="h5"
							style={{
								textAlign: "center",
								marginBottom: "1rem",
							}}
						>
							Are you sure you want to delete this provider?
						</Typography>
					</Grid>
					<Grid
						item
						xs={4}
						style={{
							textAlign: "center",
						}}
					>
						<Button
							color="primary"
							variant="contained"
							onClick={() => dispatch(deleteProvider(id))}
						>
							Yes
						</Button>
					</Grid>
					<Grid
						item
						xs={4}
						style={{
							textAlign: "center",
						}}
					>
						<Button
							color="secondary"
							variant="contained"
							onClick={() => dispatch(toggleDialog())}
						>
							No
						</Button>
					</Grid>
				</Grid>
			)
		)
		await dispatch(toggleDialog())
	}

	async function GenerateChannels(id: string | number) {
		await dispatch(
			setContent(
				<Grid container justify="space-around">
					<Grid item xs={12}>
						<Typography
							variant="h5"
							style={{
								textAlign: "center",
								marginBottom: "1rem",
							}}
						>
							Do you want to generate the channels for only this
							provider?
						</Typography>
					</Grid>
					<Grid
						item
						xs={4}
						style={{
							textAlign: "center",
						}}
					>
						<Button
							color="primary"
							variant="contained"
							onClick={() => dispatch(generateChannels(id))}
						>
							Yes
						</Button>
					</Grid>
					<Grid
						item
						xs={4}
						style={{
							textAlign: "center",
						}}
					>
						<Button
							color="primary"
							variant="contained"
							onClick={() => dispatch(generateChannels())}
						>
							No
						</Button>
					</Grid>
					<Grid
						item
						xs={4}
						style={{
							textAlign: "center",
						}}
					>
						<Button
							color="secondary"
							variant="contained"
							onClick={() => dispatch(toggleDialog())}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			)
		)
		await dispatch(toggleDialog())
	}

	function GetProviders() {
		return (
			<Table size={user?.denseMode ? "small" : undefined}>
				<TableHead>
					<TableRow>
						<TableCell>Created</TableCell>
						<TableCell>Title</TableCell>
						<TableCell>Description</TableCell>
						<TableCell>Aggregation User Parameters</TableCell>
						<TableCell>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{providers?.map((provider: Provider, index: number) => {
						return (
							<TableRow key={JSON.stringify(provider)}>
								<TableCell>
									{new Date(provider.created).toDateString()}
								</TableCell>
								<TableCell>{provider.title}</TableCell>
								<TableCell>{provider.description}</TableCell>
								<TableCell>
									{provider.aggregation_user_parameters &&
										(
											Object.keys(
												provider.aggregation_user_parameters
											) ??
											provider.aggregation_user_parameters
										).map((key: string) => (
											<ListItem>
												<ListItemText>
													{key}:{" "}
													{
														provider
															.aggregation_user_parameters[
															key
														]
													}
												</ListItemText>
											</ListItem>
										))}
								</TableCell>
								<TableCell>
									<ButtonGroup>
										<Button
											color="secondary"
											size={
												user?.denseMode
													? "small"
													: undefined
											}
											onClick={() =>
												DeleteProvider(provider.id)
											}
										>
											<DeleteIcon
												fontSize={
													user?.denseMode
														? "small"
														: undefined
												}
											/>
										</Button>
										<Button
											color="primary"
											size={
												user?.denseMode
													? "small"
													: undefined
											}
											onClick={() => {
												history.push(
													`/providers/edit-provider/${provider.id}`
												)
												SetEditing(provider)
											}}
										>
											<EditIcon
												fontSize={
													user?.denseMode
														? "small"
														: undefined
												}
											/>
										</Button>
										<Button
											color="primary"
											size={
												user?.denseMode
													? "small"
													: undefined
											}
											onClick={() =>
												GenerateChannels(provider.id)
											}
										>
											<TvIcon
												fontSize={
													user?.denseMode
														? "small"
														: undefined
												}
											/>
										</Button>
										<Button
											disabled={
												index === providers.length - 1
											}
											size={
												user?.denseMode
													? "small"
													: undefined
											}
											onClick={() =>
												RearrangeProviders(index, true)
											}
										>
											<ArrowDownwardIcon
												fontSize={
													user?.denseMode
														? "small"
														: undefined
												}
											/>
										</Button>
										<Button
											disabled={index === 0}
											size={
												user?.denseMode
													? "small"
													: undefined
											}
											onClick={() =>
												RearrangeProviders(index)
											}
										>
											<ArrowUpwardIcon
												fontSize={
													user?.denseMode
														? "small"
														: undefined
												}
											/>
										</Button>
									</ButtonGroup>
								</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		)
	}

	function GetTab() {
		switch (tab) {
			case "providers":
				return GetProviders()
			case "add-provider":
			case "edit-provider":
				return AddProvider()
		}
	}

	async function RearrangeProviders(
		index: number,
		goingDown: boolean = false
	) {
		if (!providers) {
			return
		}

		const start = new Date().getTime()

		await dispatch(setLoading(true))

		await dispatch(rearrangeProviders(index, goingDown))

		await Promise.all([
			dispatch(updateProvider(providers[index], false)),
			dispatch(
				updateProvider(providers[(goingDown ? 1 : -1) + index], false)
			),
		])

		setTimeout(
			() => dispatch(setLoading(false)),
			1000 - (new Date().getTime() - start) < 0
				? 0
				: 1000 - (new Date().getTime() - start)
		)
	}

	function SetEditing(provider: Provider) {
		setId(provider.id)
		setTitle(provider.title)
		console.log(provider.channels)
		setChannels(provider.channels)
		setDescription(provider.description)
		setAggregationUserParameters(
			Object.keys(provider.aggregation_user_parameters ?? {}).map(
				(key) => ({
					label: key,
					value: provider.aggregation_user_parameters[key],
				})
			)
		)

		setTab("edit-provider")
	}

	useEffect(() => {
		if (!isLoading) {
			if (!providers) {
				dispatch(getProviders())
			} else {
				console.log(providers)
			}

			if (urlParams.tab) {
				let provider = null
				if (["providers", "add-provider"].includes(urlParams.tab)) {
					setTab(urlParams.tab)
				} else if (
					urlParams.tab === "edit-provider" &&
					urlParams.id &&
					(provider = providers?.find(
						(provider) =>
							provider.id.toString() === urlParams.id?.toString()
					))
				) {
					SetEditing(provider)
				}
			}
		}
	}, [providers])

	return (
		<Grid
			item
			xs={12}
			container
			justify="flex-start"
			alignContent="flex-start"
		>
			{isLoading && (
				<Backdrop open={isLoading} style={{ zIndex: 12001 }}>
					<CircularProgress color="primary" size="5rem" />
				</Backdrop>
			)}
			<Tabs
				value={tab}
				variant="fullWidth"
				textColor="secondary"
				indicatorColor="secondary"
				style={{ height: "fit-content" }}
				onChange={(_event: any, tab: string) => {
					if (!tab) {
						return
					}

					if (id) {
						ClearProviderObject()
					}

					history.push(
						tab === "providers" ? "/providers" : `/providers/${tab}`
					)

					setTab(tab)
				}}
			>
				<Tab value="providers" label="Providers" />
				<Tab value="add-provider" label="Add Provider" />
				<Tab
					value="edit-provider"
					label="Edit Provider"
					style={{ pointerEvents: id ? "all" : "none" }}
				/>
			</Tabs>
			{GetTab()}
		</Grid>
	)
}
