import React from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import { connect } from "react-redux"
import { RootState } from "../../store"
import Box from "@material-ui/core/Box"
import { get, post } from "../../plugins/api"
import { MenuItem } from "@material-ui/core"
import { bindActionCreators, Dispatch } from "redux"
import Select from "@material-ui/core/Select/Select"
import InputLabel from "@material-ui/core/InputLabel/InputLabel"
import FormControl from "@material-ui/core/FormControl/FormControl"
import LinearProgress from "@material-ui/core/LinearProgress"
import { createNotification } from "../../store/notification/actions"
import { LinearProgressProps } from "@material-ui/core/LinearProgress"

function LinearProgressWithLabel(
	props: LinearProgressProps & { value: number }
) {
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={1}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box minWidth={35}>
				<Typography
					variant="body2"
					color="textSecondary"
				>{`${props.value}%`}</Typography>
			</Box>
		</Box>
	)
}

interface Video {
	[x: string]: any
	uuid?: string
	guid?: string
	name?: string
	video?: string
	title?: string
	thumbnail?: string
	published?: string
	description?: string
	duration?: number | null
	feed_tags?: Array<string>
}

interface SourceProps {
	saved?: boolean
	loading?: boolean
	success?: boolean
	uploadDone?: boolean
	uploadProgress?: any
	videoPackages?: Array<Video>
	socket?: SocketIOClient.Socket
	environment?: "development/" | "staging/" | ""
	createNotification?: typeof createNotification
}

interface SourceActionProps {
	createNotification?: typeof createNotification
}

export class Source extends React.Component<
	SourceProps,
	SourceProps,
	SourceActionProps
> {
	constructor(props: SourceProps) {
		super(props)

		this.state = {
			uploadDone: false,
			uploadProgress: {},
			loading: false,
			videoPackages: [],
			environment: "",
			saved: false,
		}
	}

	async componentDidMount() {
		this.props.socket?.on("upload-progress", (uploadProgress: any) => {
			if (uploadProgress.isVideo) {
				this.setState({
					...this.state,
					uploadProgress: {
						...this.state.uploadProgress,
						[uploadProgress.uuid]: {
							...this.state.uploadProgress[uploadProgress.uuid],
							video: uploadProgress.progress,
						},
					},
				})
			}
		})

		this.props.socket?.on("upload-done", () => {
			this.setState({
				...this.state,
				uploadDone: true,
				uploadProgress: {},
			})
		})

		try {
			let videoPackages = await get("/v1/uploads/getTemporaryVideos")

			this.setState({
				...this.state,
				videoPackages,
			})
		} catch (e) {
			this.props.createNotification?.({
				message: e.message ?? e,
				severity: "error",
			})

			// localStorage.setItem('redirect', window.location.pathname);
			// window.location.href = '/v1/auth/google';
		}
	}

	OnChange = (event: any, videoName: string | undefined = undefined) => {
		if (videoName) {
			this.setState({
				...this.state,
				videoPackages: this.state.videoPackages?.map(
					(videoPackage: Video) => {
						if (videoPackage.uuid !== videoName) {
							return videoPackage
						}

						videoPackage[event.target.name] = event.target.value

						return videoPackage
					}
				),
			})
		} else {
			this.setState({
				...this.state,
				[event.target.name]: event.target.value,
			})
		}
	}

	OnSubmit = async () => {
		try {
			this.setState({
				...this.state,
				loading: true,
			})

			await post(
				"/v1/uploads/uploadTemporaryVideos",
				this.state.videoPackages
			)

			this.setState({
				...this.state,
				loading: false,
				success: true,
				saved: true,
			})
		} catch (e) {
			this.props.createNotification?.({
				message: e.message ?? e,
				severity: "error",
			})

			localStorage.setItem("redirect", window.location.pathname)
			window.location.href = "/v1/auth/google"
		}
	}

	render() {
		return (
			<Grid
				container
				alignContent="flex-start"
				justify="center"
				style={{
					height: "100%",
				}}
			>
				<Grid
					item
					xs={10}
					container
					style={{
						height: "100%",
					}}
				>
					{this.state.uploadProgress &&
						Object.keys(this.state.uploadProgress).map((uuid) => {
							return (
								<Grid item xs={12}>
									<Typography variant="subtitle2">
										{this.state.videoPackages?.find(
											(videoPackage) =>
												videoPackage.uuid === uuid
										)?.title ?? uuid}
									</Typography>
									<LinearProgressWithLabel
										value={
											this.state.uploadProgress[uuid]
												.video
										}
									/>
								</Grid>
							)
						})}
					{(this.state.uploadDone && this.state.saved && (
						<div
							style={{
								position: "sticky",
								top: 0,
								bottom: 0,
								left: 0,
								right: 0,
								height: "100%",
								width: "100%",
							}}
						>
							<Typography
								variant="h4"
								style={{
									top: "50%",
									position: "relative",
									transform: "translateY(-50%)",
								}}
							>
								Videos uploaded successfully
							</Typography>
						</div>
					)) ||
						(this.state.videoPackages &&
							this.state.videoPackages.length > 0 &&
							!this.state.success && (
								<>
									<Grid item xs={12}>
										<FormControl style={{ width: "100%" }}>
											<InputLabel id="environment-label">
												Environment
											</InputLabel>
											<Select
												fullWidth
												id="environment"
												name="environment"
												onChange={(event) =>
													this.OnChange(event)
												}
												labelId="environment-label"
												value={this.state.environment}
											>
												<MenuItem value="development/">
													Development
												</MenuItem>
												<MenuItem value="staging/">
													staging
												</MenuItem>
												<MenuItem value="">
													Production
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									{this.state.videoPackages.map(
										(videoPackage: Video) => {
											return (
												<Grid
													item
													xs={12}
													key={videoPackage.uuid}
													style={{
														margin: "1rem",
													}}
												>
													<Paper
														elevation={2}
														style={{
															padding: "1rem",
														}}
													>
														<TextField
															fullWidth
															name="title"
															label="Title"
															onChange={(event) =>
																this.OnChange(
																	event,
																	videoPackage.uuid
																)
															}
															value={
																videoPackage.title ||
																videoPackage.uuid
															}
														/>
														<TextField
															fullWidth
															multiline
															name="description"
															label="Description"
															onChange={(event) =>
																this.OnChange(
																	event,
																	videoPackage.uuid
																)
															}
															value={
																videoPackage.description
															}
														/>
														<TextField
															fullWidth
															name="feed_tags"
															label="Feed Tags"
															onChange={(event) =>
																this.OnChange(
																	event,
																	videoPackage.uuid
																)
															}
															value={
																videoPackage.feed_tags
															}
														/>
													</Paper>
												</Grid>
											)
										}
									)}
									<Grid item xs={12}>
										<Button
											onClick={this.OnSubmit}
											disabled={this.state.loading}
										>
											{(this.state.loading && (
												<CircularProgress />
											)) || (
												<Typography variant="subtitle2">
													Submit
												</Typography>
											)}
										</Button>
									</Grid>
								</>
							)) || (
							<div
								style={{
									position: "sticky",
									top: 0,
									bottom: 0,
									left: 0,
									right: 0,
									height: "100%",
									width: "100%",
								}}
							>
								<Typography
									variant="h4"
									style={{
										top: "50%",
										position: "relative",
										transform: "translateY(-50%)",
									}}
								>
									No videos ready for processing
								</Typography>
							</div>
						)}
				</Grid>
			</Grid>
		)
	}
}

function mapStateToProps(state: RootState): SourceProps {
	return {
		socket: state.socket,
	}
}

function mapDispatchToProps(dispatch: Dispatch): SourceActionProps {
	return bindActionCreators({ createNotification }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Source)
