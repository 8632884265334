import { RootState } from ".."
import Cookies from "js-cookie"
import { User } from "./reducer"
import { get } from "../../plugins/api"
import { ThunkDispatch } from "redux-thunk"
import { createNotification } from "../notification/actions"

export enum types {
	GET_USER = "GET_USER",
	LOAD_USER = "LOAD_USER",
	LOGOUT_USER = "LOGOUT_USER",
	UPDATE_USER = "UPDATE_USER",
	TOGGLE_DENSE_MODE = "TOGGLE_DENSE_MODE",
}

export const toggleDenseMode = () => async (
	dispatch: ThunkDispatch<RootState, User, any>
) => {
	dispatch({
		type: types.TOGGLE_DENSE_MODE,
	})
}

export const login = () => async (
	dispatch: ThunkDispatch<RootState, User, any>
) => {
	try {
		get("/v1/auth/google")
	} catch (message) {
		dispatch(
			createNotification({
				message,
				severity: "error",
			})
		)
	}
}

export const loadUser = () => async (
	dispatch: ThunkDispatch<RootState, User, any>
) => {
	let user = Cookies.get("user")

	if (user) {
		user = JSON.parse(user)

		dispatch({
			type: types.LOAD_USER,
			payload: user,
		})
	} else {
		// localStorage.setItem('redirect', window.location.pathname);
		// window.location.href = '/v1/auth/google';
	}
}

export const logout = () => async (
	dispatch: ThunkDispatch<RootState, User, any>
) => {
	Cookies.remove("user")
	Cookies.remove("session")
	Cookies.remove("remember_token")

	dispatch({
		type: types.LOGOUT_USER,
	})

	window.location.href = "/auth/logout"
}
