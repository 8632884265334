import Card from "../../utils/card"
import { connect } from "react-redux"
import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import { RootState } from "../../../store"
import AddIcon from "@material-ui/icons/Add"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import ReactHlsPlayer from "react-hls-player"
import Tooltip from "@material-ui/core/Tooltip"
import TimerIcon from "@material-ui/icons/Timer"
import ListItem from "@material-ui/core/ListItem"
import DeleteIcon from "@material-ui/icons/Delete"
import AddBoxIcon from "@material-ui/icons/AddBox"
import RemoveIcon from "@material-ui/icons/Remove"
import { User } from "../../../store/user/reducer"
import { bindActionCreators, Dispatch } from "redux"
import PublishIcon from "@material-ui/icons/Publish"
import Typography from "@material-ui/core/Typography"
import CategoryIcon from "@material-ui/icons/Category"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { toggleItem } from "../../../store/notify/actions"
import AspectRatioIcon from "@material-ui/icons/AspectRatio"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import CircularProgress from "@material-ui/core/CircularProgress"
import { setContent, toggleDialog } from "../../../store/dialog/actions"
import { getFeedItems, toggleVisibility } from "../../../store/feed/actions"
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive"

interface FeedItemProps {
	user?: User
	notify?: any
	feedItem: any
	markers?: Array<any>
	watchCountList?: Array<number>
	toggleItem?: typeof toggleItem
	setContent?: typeof setContent
	toggleDialog?: typeof toggleDialog
	getFeedItems?: typeof getFeedItems
	OnDelete?: (deletedItemId: number) => void
	toggleVisibility?: typeof toggleVisibility
}

interface FeedItemActionProps {
	OnDelete?: (deletedItemId: number) => void
	toggleItem?: typeof toggleItem
	setContent?: typeof setContent
	toggleDialog?: typeof toggleDialog
	getFeedItems?: typeof getFeedItems
	toggleVisibility?: typeof toggleVisibility
}

interface MappingMatrix {
	[x: string]: [any, any] | [any]
}

export class FeedItem extends Component<
	FeedItemProps,
	FeedItemProps,
	FeedItemActionProps
> {
	channelName = (value: any): string => {
		let name = value
		let marker = this.props.markers?.[value]

		if (marker) {
			value = value.split(":")
			if (value.length > 1) {
				name = `${value[0]}:${marker}  (${value[1]})`
			} else if (value.length === 1 && value[0] !== marker) {
				name = `${value[0]}:${marker}`
			} else {
				name = value[0]
			}
		} else {
			value = value.split(":")

			if (value.length > 1 && value[0] === value[1]) {
				name = value[0]
			}
		}

		return name
	}

	MappingMatrix: MappingMatrix = {
		watch_count: [
			<Tooltip
				title={<Typography variant="subtitle2">Watch Count</Typography>}
			>
				<VisibilityIcon />
			</Tooltip>,
		],
		duration: [
			<Tooltip
				title={
					<Typography variant="subtitle2">Video Duration</Typography>
				}
			>
				<TimerIcon />
			</Tooltip>,
			this.toHHMMSS,
		],
		video_resolution: [
			<Tooltip
				title={
					<Typography variant="subtitle2">
						Video Resolution
					</Typography>
				}
			>
				<AspectRatioIcon />
			</Tooltip>,
		],
		aggregation_channel_marker: [
			<Tooltip
				title={
					<Typography variant="subtitle2">
						Aggergation Channel Marker
					</Typography>
				}
			>
				<AccountCircleIcon />
			</Tooltip>,
			this.channelName,
		],
		categories: [
			<Tooltip
				title={
					<Typography variant="subtitle2">
						Video Categories
					</Typography>
				}
			>
				<CategoryIcon />
			</Tooltip>,
			this.channelName,
		],
		created: [
			<Tooltip
				title={<Typography variant="subtitle2">Created On</Typography>}
			>
				<AddIcon />
			</Tooltip>,
			this.dateFormat,
		],
		published_at: [
			<Tooltip
				title={
					<Typography variant="subtitle2">Published On</Typography>
				}
			>
				<PublishIcon />
			</Tooltip>,
			this.dateFormat,
		],
	}

	dateFormat(value: any): string | null {
		if (!value) {
			return value
		}

		value = value.split("T")
		value = [...value[0].split("-"), ...value[1].split(":")]

		let date = new Date(
			Date.UTC(value[0], value[1] - 1, value[2], value[3], value[4])
		)

		return date.toLocaleString()
	}

	toHHMMSS(value: any): string | null {
		if (!value) {
			return value
		}

		let sec_num = parseInt(value, 10)

		if (
			isNaN(sec_num) ||
			sec_num.toString().length !== value.toString().length
		) {
			return value
		}

		let hours: number | string = Math.floor(sec_num / 3600)

		sec_num -= hours * 3600

		let minutes: number | string = Math.floor(sec_num / 60)

		let seconds: number | string = sec_num - minutes * 60

		if (hours < 10) {
			hours = "0" + hours
		}

		if (minutes < 10) {
			minutes = "0" + minutes
		}

		if (seconds < 10) {
			seconds = "0" + seconds
		}

		return `${hours}:${minutes}:${seconds}`
	}

	constructor(props: FeedItemProps) {
		super(props)

		this.state = {
			feedItem: null,
			watchCountList: JSON.parse(
				sessionStorage.getItem("watchCountList") ?? "[]"
			),
		}
	}

	ToggleVisibility = (feedItemId: number, test: boolean = false) => {
		this.setState(
			{
				...this.state,
				[feedItemId]: true,
			},
			async () => {
				await this.props.toggleVisibility?.(feedItemId, test)

				this.setState({
					...this.state,
					[feedItemId]: undefined,
				})
			}
		)
	}

	ToggleWatchList = (feedItemId: number) => {
		if (this.state.watchCountList?.includes(feedItemId)) {
			this.setState(
				{
					...this.state,
					watchCountList: this.state.watchCountList.filter(
						(item) => item !== feedItemId
					),
				},
				() => {
					sessionStorage.setItem(
						"watchCountList",
						JSON.stringify(this.state.watchCountList)
					)
				}
			)
		} else {
			this.setState(
				{
					...this.state,
					watchCountList: [
						...(this.state.watchCountList ?? []),
						feedItemId,
					],
				},
				() => {
					sessionStorage.setItem(
						"watchCountList",
						JSON.stringify(this.state.watchCountList)
					)
				}
			)
		}
	}

	render() {
		return (
			<Card
				style={
					(this.props.feedItem.isRecommended && {
						border: "2px solid",
						borderRadius: "4px",
						borderImageSlice: 1,
						borderImageSource:
							"linear-gradient(295.86deg, #2AEAFF -43.14%, #39CEFF 20.81%, #4DA9FE 105.71%)",
					}) ??
					undefined
				}
				header={{
					avatar: <Avatar aria-label="recipe">R</Avatar>,
					title: `${this.props.feedItem.id}: ${this.props.feedItem.title}`,
					subheader: (
						<Typography
							component="p"
							variant="body2"
							color="textSecondary"
							style={{ height: "fit-content" }}
						>
							{this.MappingMatrix["published_at"][1](
								this.props.feedItem.published_at
							)}
						</Typography>
					),
					titleTextSettings: {
						component: "p",
						variant: "body1",
					},
				}}
				subheader={
					<Typography
						component="p"
						variant="subtitle2"
						color="textSecondary"
						style={{
							textAlign: "left",
						}}
					>
						{this.props.feedItem.description
							? this.props.feedItem.description.length < 200
								? this.props.feedItem.description
								: this.props.feedItem.description.substr(
										0,
										200
								  ) + "..."
							: ""}
					</Typography>
				}
				media={{
					title: this.props.feedItem.title,
					component: "img",
					style: {
						height: "auto",
						maxHeight: "360px",
					},
					src:
						!this.props.feedItem.url_thumbnail ||
						this.props.feedItem.url_thumbnail.indexOf("http:") !==
							-1
							? "https://www.snibblecorp.com/content/placeholder_thmb.png"
							: this.props.feedItem.url_thumbnail,
					onClick: async () => {
						if (this.props.feedItem.url) {
							fetch(
								this.props.feedItem.url.replace(
									"videocdn",
									"cors"
								)
							)
								.then(async () => {
									await this.props.setContent?.(
										<>
											<ReactHlsPlayer
												url={this.props.feedItem.url.replace(
													"videocdn",
													"cors"
												)}
												autoplay={false}
												controls={true}
												width="100%"
												height="auto"
											/>
											<Typography
												variant="subtitle2"
												style={{ marginTop: "1rem" }}
											>
												{
													this.props.feedItem
														.description
												}
											</Typography>
										</>
									)
								})
								.catch(async (e) => {
									console.error(e)
									await this.props.setContent?.(
										<>
											<img
												alt=""
												src={
													this.props.feedItem
														.url_thumbnail
												}
												width="100%"
											/>
											<Typography
												variant="subtitle2"
												style={{ marginTop: "1rem" }}
											>
												{
													this.props.feedItem
														.description
												}
											</Typography>
										</>
									)
								})
								.finally(async () => {
									await this.props.toggleDialog?.()
								})
						} else if (
							this.props.feedItem.aggregation_channel_marker.indexOf(
								"verizon:"
							) !== -1
						) {
							await this.props.setContent?.(
								<>
									<div id="verizionPlayer"></div>
									<Typography
										variant="subtitle2"
										style={{ marginTop: "1rem" }}
									>
										{this.props.feedItem.description}
									</Typography>
								</>
							)
							await this.props.toggleDialog?.()

							setTimeout(() => {
								;(window as any).vidible
									.player("verizionPlayer")
									.setup({
										bcid: "5e3996458b692d292f2762a3",
										pid: "5e399645e5711106345dd749",
										videos: [this.props.feedItem.guid],
									})
									.load()
							}, 250)
						} else if (
							this.props.feedItem.aggregation_channel_marker.indexOf(
								"dailymotion:"
							) !== -1
						) {
							await this.props.setContent?.(
								<>
									<div
										id="dailymotionPlayer"
										style={{
											height: "480px",
										}}
									>
										<iframe
											width="100%"
											height="100%"
											allowFullScreen
											frameBorder="0"
											allow="autoplay; fullscreen"
											src={`https://www.dailymotion.com/embed/video/${this.props.feedItem.guid}?autoplay=1&ui-highlight=fff`}
										></iframe>
									</div>
									<Typography
										variant="subtitle2"
										style={{ marginTop: "1rem" }}
									>
										{this.props.feedItem.description}
									</Typography>
								</>
							)
							await this.props.toggleDialog?.()
						} else {
							await this.props.setContent?.(
								<>
									<img
										alt=""
										src={this.props.feedItem.url_thumbnail}
										width="100%"
									/>
									<Typography
										variant="subtitle2"
										style={{ marginTop: "1rem" }}
									>
										{this.props.feedItem.description}
									</Typography>
								</>
							)
							await this.props.toggleDialog?.()
						}
					},
				}}
				description={
					<>
						<List dense={this.props.user?.denseMode}>
							{Object.keys(this.MappingMatrix).map(
								(key: string) => {
									return (
										<ListItem
											dense={this.props.user?.denseMode}
											key={this.props.feedItem.id + key}
										>
											<ListItemIcon>
												{this.MappingMatrix[key][0]}
											</ListItemIcon>
											<ListItemText>
												{(this.MappingMatrix[key][1] &&
													this.MappingMatrix[key][1](
														this.props.feedItem[key]
													)) ||
													this.props.feedItem[key] ||
													"N/A"}
											</ListItemText>
										</ListItem>
									)
								}
							)}
						</List>

						<ButtonGroup>
							<Tooltip
								title={
									<Typography variant="subtitle2">
										{!this.state.watchCountList?.includes(
											this.props.feedItem.id
										)
											? "Add item to watch count list"
											: "Remove item from watch count list"}
									</Typography>
								}
							>
								<Button
									color={
										this.state.watchCountList?.includes(
											this.props.feedItem.id
										)
											? "secondary"
											: "primary"
									}
									onClick={() => {
										this.ToggleWatchList(
											this.props.feedItem.id
										)
									}}
								>
									{!this.state.watchCountList?.includes(
										this.props.feedItem.id
									) ? (
										<AddIcon />
									) : (
										<RemoveIcon />
									)}
								</Button>
							</Tooltip>

							<Tooltip
								title={
									<Typography variant="subtitle2">
										{(this.props.feedItem.isRecommended &&
											"Video as already been recommended.") ||
											(!this.props.notify?.selectedItems.includes(
												this.props.feedItem.id
											) &&
												"Add video to notify list") ||
											"Remove video from notify list"}
									</Typography>
								}
							>
								<Button
									disabled={
										this.props.feedItem.isRecommended ??
										(this.state as any)[
											this.props.feedItem.id
										] ??
										false
									}
									color={
										this.props.notify?.selectedItems.includes(
											this.props.feedItem.id
										)
											? "secondary"
											: "primary"
									}
									onClick={() =>
										this.props.toggleItem?.(
											this.props.feedItem.id
										)
									}
								>
									<NotificationsActiveIcon />
								</Button>
							</Tooltip>

							<Tooltip
								title={
									<Typography variant="subtitle2">
										{this.props.feedItem.record_status === 1
											? "Delete video from app"
											: "Add video to app"}
									</Typography>
								}
							>
								<Button
									disabled={
										(this.state as any)[
											this.props.feedItem.id
										] ?? false
									}
									color={
										this.props.feedItem.record_status === 1
											? "secondary"
											: "primary"
									}
									onClick={async () => {
										await this.props.setContent?.(
											<Grid
												container
												justify="space-around"
											>
												<Grid item xs={12}>
													<Typography
														variant="h5"
														style={{
															textAlign: "center",
															marginBottom:
																"1rem",
														}}
													>
														Are you sure you want to
														delete this video?
													</Typography>
												</Grid>
												<Grid
													item
													xs={4}
													style={{
														textAlign: "center",
													}}
												>
													<Button
														color="primary"
														variant="contained"
														onClick={() => {
															this.props.OnDelete?.(
																this.props
																	.feedItem.id
															)

															this.ToggleVisibility(
																this.props
																	.feedItem.id
															)

															this.props.toggleDialog?.()
														}}
													>
														Yes
													</Button>
												</Grid>
												<Grid
													item
													xs={4}
													style={{
														textAlign: "center",
													}}
												>
													<Button
														color="primary"
														variant="contained"
														onClick={() => {
															this.props.OnDelete?.(
																this.props
																	.feedItem.id
															)

															this.ToggleVisibility(
																this.props
																	.feedItem
																	.id,
																true
															)

															this.props.toggleDialog?.()
														}}
													>
														Dry Run
													</Button>
												</Grid>
												<Grid
													item
													xs={4}
													style={{
														textAlign: "center",
													}}
												>
													<Button
														color="secondary"
														variant="contained"
														onClick={() =>
															this.props.toggleDialog?.()
														}
													>
														No
													</Button>
												</Grid>
											</Grid>
										)
										await this.props.toggleDialog?.()
									}}
								>
									{(this.state as any)[
										this.props.feedItem.id
									] ? (
										<CircularProgress
											size="2rem"
											color={
												this.props.feedItem
													.record_status === 1
													? "secondary"
													: "primary"
											}
										/>
									) : (
										<>
											{this.props.feedItem
												.record_status === 1 ? (
												<DeleteIcon />
											) : (
												<AddBoxIcon />
											)}
										</>
									)}
								</Button>
							</Tooltip>
						</ButtonGroup>
					</>
				}
			/>
		)
	}
}

function mapStateToProps(state: RootState, props: FeedItemProps): any {
	return {
		notify: state.notify,
		user: state.user,
	}
}

function mapDispatchToProps(dispatch: Dispatch): FeedItemActionProps {
	return bindActionCreators(
		{
			toggleItem,
			setContent,
			toggleDialog,
			getFeedItems,
			toggleVisibility,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedItem)
