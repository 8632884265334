import React, { useEffect, useState } from "react"
import { RootState } from "../../store"
import {
	Button,
	Checkbox,
	CircularProgress,
	Grid,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { getFeedItems, updateItems } from "../../store/feed/actions"
import TransferList from "../utils/transfer-list"
import { get, post } from "../../plugins/api"
import ReactHlsPlayer from "react-hls-player"

function ManageCategories() {
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [categories, setCategories] = useState<any[]>([])
	const [feedItemIds, setFeedItemIds] = useState<string[]>([])
	const [selectedCategories, setSelectedCategories] = useState<any[]>([])

	const feed: RootState["feed"] = useSelector(
		(state: RootState) => state.feed
	)

	async function GetFeedItems() {
		dispatch(
			getFeedItems({ feedItemIds: feedItemIds, reduceCategories: false })
		)
	}

	async function UpdateCategories() {
		post("/v1/feed/updateCategories", {
			feedItemIds,
			selectedCategories,
		})
	}

	useEffect(() => {
		if (categories.length === 0 && !loading) {
			setLoading(true)
			get("/v1/categories?reduce=false").then((results) => {
				setLoading(false)
				setCategories(results)
			})
		}
	}, [categories])

	useEffect(() => {
		setSelectedCategories(
			feed?.items.flatMap((item) => item.categories) ?? []
		)
	}, [feed])

	return (
		<Grid item xs={12} container>
			<Grid item xs={12}>
				<TextField
					fullWidth
					label="Feed Item ID's"
					onChange={(event) =>
						setFeedItemIds(event.target.value.split(",") ?? [])
					}
					value={feedItemIds.join(",")}
					InputProps={{
						endAdornment: (
							<>
								{feed?.loading ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : null}
								<Tooltip
									title={
										<Typography variant="subtitle2">
											Search
										</Typography>
									}
								>
									<Button onClick={GetFeedItems}>
										Search
									</Button>
								</Tooltip>
							</>
						),
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				{(categories && (
					<TransferList
						options={categories}
						onChange={(event: any) => {
							console.log(JSON.stringify(event.target.value))
							setSelectedCategories(event.target.value)
						}}
						getOptionLabel={(option: any) => option.title}
						value={selectedCategories}
					/>
				)) ||
					undefined}
			</Grid>
			<Grid item xs={12}>
				<Button onClick={UpdateCategories}>Test</Button>
			</Grid>
		</Grid>
	)
}

export default ManageCategories
