import { RootState } from '..';
import { get } from '../../plugins/api';
import { ThunkDispatch } from 'redux-thunk';
import { createNotification } from '../notification/actions';

export enum types {
    GET_RPOVIDERS = 'GET_RPOVIDERS',
    TOGGLE_LOADING = 'TOGGLE_LOADING',
    GET_RPOVIDER_URLS = 'GET_RPOVIDER_URLS',
}

export const getProviderList = () => (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    dispatch({
        type: types.TOGGLE_LOADING,
        payload: true,
    });

    get('/v1/aggregation/providers')
        .then((items) => {
            dispatch({
                type: types.TOGGLE_LOADING,
                payload: false,
            });
            dispatch({
                type: types.GET_RPOVIDERS,
                payload: items,
            });
        })
        .catch((message) => {
            dispatch({
                type: types.TOGGLE_LOADING,
                payload: false,
            });
            dispatch(
                createNotification({
                    message,
                    severity: 'error',
                })
            );
        });
};

export const getProviderUrls = () => (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    dispatch({
        type: types.TOGGLE_LOADING,
        payload: true,
    });

    get('/v1/aggregation/providers/urls')
        .then((items) => {
            dispatch({
                type: types.TOGGLE_LOADING,
                payload: false,
            });
            dispatch({
                type: types.GET_RPOVIDER_URLS,
                payload: items,
            });
        })
        .catch((message) => {
            dispatch({
                type: types.TOGGLE_LOADING,
                payload: false,
            });
            dispatch(
                createNotification({
                    message,
                    severity: 'error',
                })
            );
        });
};
