import Roles from './roles';
import { connect } from 'react-redux';
import UserGroups from './user-groups';
import Tab from '@material-ui/core/Tab';
import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import ResourceGroups from './resource-groups';
import { bindActionCreators, Dispatch } from 'redux';
import { Permissions } from '../../store/permissions/reducer';
import { getPermissions, setTab } from '../../store/permissions/actions';
import Users from './users';

interface AdminItemsProps {
    setTab?: typeof setTab;
    permissions?: Permissions;
    getPermissions?: typeof getPermissions;
}

interface AdminItemsActionProps {
    setTab?: typeof setTab;
    getPermissions?: typeof getPermissions;
}

export class AdminItems extends Component<
    AdminItemsProps,
    AdminItemsProps,
    AdminItemsActionProps
> {
    componentDidMount() {
        this.props.getPermissions?.();
    }

    GetTab = () => {
        switch (this.props.permissions?.tab) {
            case 'Roles':
                return <Roles />;
            case 'Resource Groups':
                return <ResourceGroups />;
            case 'User Groups':
                return <UserGroups />;
            case 'Users':
                return <Users />;
            default:
                return <Roles />;
        }
    };

    OnChange = (_event: any, tab: string | null = null) => {
        if (!tab) {
            return;
        }

        this.props.setTab?.(tab);
    };

    render() {
        return (
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Tabs
                        textColor="secondary"
                        variant="fullWidth"
                        style={{
                            display: 'flex',
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        }}
                        value={this.props.permissions?.tab ?? 'Roles'}
                        onChange={this.OnChange}
                        indicatorColor="secondary"
                    >
                        <Tab value="Roles" label="Roles" />

                        <Tab value="Resource Groups" label="Resource Groups" />

                        <Tab value="Users" label="Users" />
                        <Tab value="User Groups" label="User Groups" />
                    </Tabs>
                    {this.GetTab()}
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state: any): AdminItemsProps {
    return { permissions: state.permissions };
}

function mapDispatchToProps(dispatch: Dispatch): AdminItemsActionProps {
    return bindActionCreators(
        {
            setTab,
            getPermissions,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminItems);
