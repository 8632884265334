import { connect } from "react-redux"
import React, { Component } from "react"
import { addAccordion, toggleAccordion } from "../../../store/accordion/actions"
import { bindActionCreators, Dispatch } from "redux"
import { withStyles } from "@material-ui/core/styles"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import { Accordion as IAccordion } from "../../../store/accordion/reducer"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const MAccordion = withStyles({
	root: {
		border: "1px solid rgba(0, 0, 0, .125)",
		borderRight: "none",
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
		"&$expanded": {
			margin: 0,
		},
	},
	expanded: {
		margin: 0,
	},
})(MuiAccordion)

const AccordionSummary = withStyles({
	root: {
		backgroundColor: "rgba(0, 0, 0, .03)",
		borderBottom: "1px solid rgba(0, 0, 0, .125)",
		marginBottom: -1,
		minHeight: 56,
		display: "flex",
		justifyContent: "flex-start",
		"&$expanded": {
			minHeight: 56,
		},
	},
	content: {
		"&$expanded": {
			margin: "0",
		},
	},
})(MuiAccordionSummary)

const AccordionDetails = withStyles({
	root: {
		padding: 0,
	},
})(MuiAccordionDetails)

interface AccordionProps {
	id?: string
	style?: any
	classes?: any
	open?: boolean
	summary: JSX.Element
	children?: JSX.Element
	details?: boolean | JSX.Element
	addAccordion?: typeof addAccordion
	toggleAccordion?: typeof toggleAccordion
}

interface AccordionActionProps {
	addAccordion: typeof addAccordion
	toggleAccordion: typeof toggleAccordion
}

export class Accordion extends Component<
	AccordionProps,
	AccordionProps,
	AccordionActionProps
> {
	constructor(props: AccordionProps) {
		super(props)

		this.state = {
			...props,
			id: props.id ?? new Date().getTime().toString(),
		}
	}

	componentDidMount() {
		this.props.addAccordion?.({
			id: this.props.id ?? this.state.id,
			open: this.props.open !== undefined ? this.props.open : false,
		})
	}

	render() {
		return (
			<MAccordion
				square={true}
				expanded={this.props.open}
				style={this.props.style}
			>
				<AccordionSummary
					onClick={() =>
						this.props.toggleAccordion?.(
							this.props.id ?? this.state.id
						)
					}
					expandIcon={<ExpandMoreIcon />}
				>
					{this.props.summary}
				</AccordionSummary>
				{this.props.details ? (
					<AccordionDetails>{this.props.details}</AccordionDetails>
				) : null}
			</MAccordion>
		)
	}
}

function mapStateToProps(state: any, props: AccordionProps): any {
	return {
		...state.accordions.find((accordion: IAccordion) => {
			return accordion.id === props.id
		}),
	}
}

function mapDispatchToProps(dispatch: Dispatch): AccordionActionProps {
	return bindActionCreators({ addAccordion, toggleAccordion }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Accordion)
