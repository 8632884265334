import Filter from './filter';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { RootState } from '../../../store';
import Backdrop from '@material-ui/core/Backdrop';
import { bindActionCreators, Dispatch } from 'redux';
import { getWatchCountReport } from '../../../store/report/actions';
import CircularProgress from '@material-ui/core/CircularProgress';

interface WatchCountProps {
    data?: any;
    watchCount?: any;
    loading?: boolean;
    getWatchCountReport?: typeof getWatchCountReport;
}

interface WatchCountActionProps {
    getWatchCountReport?: typeof getWatchCountReport;
}

export class WatchCount extends Component<
    WatchCountProps,
    WatchCountProps,
    WatchCountActionProps
> {
    componentDidMount() {
        let filters = null;
        let watchCountList = sessionStorage.getItem('watchCountList');

        if (watchCountList && (watchCountList = JSON.parse(watchCountList))) {
            filters = {
                watchCountList,
            };

            sessionStorage.removeItem('watchCountList');
        }

        this.LoadData(filters);
    }

    LoadData = (filters: any = null) => {
        this.props.getWatchCountReport?.(filters);
    };

    GetDataSet = (data: any) => {
        return {
            label: data.label,
            fill: false,
            lineTension: 0.1,
            backgroundColor: data.color,
            borderColor: data.color,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: data.data.length > 10 ? 5 : 10,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: data.data.length > 10 ? 1 : 5,
            pointHitRadius: 10,
            data: data.data,
        };
    };

    GetWatchCountReport = () => {
        if (this.props.watchCount.data.length === 0) {
            return null;
        }

        return (
            <Line
                data={{
                    labels: this.props.watchCount.data[0].labels.map(
                        (label: string) => new Date(label).toDateString()
                    ),
                    datasets: [this.GetDataSet(this.props.watchCount.data[0])],
                }}
            />
        );
        // return this.props.watchCount.data.map((data: any) => (
        //     <Line
        //         data={{
        //             labels: data.labels.map((label: string) =>
        //                 new Date(label).toDateString()
        //             ),
        //             datasets: [this.GetDataSet(data)],
        //         }}
        //     />
        // ));
    };

    OnFilter = (filters: any) => {
        this.LoadData(filters);
    };

    render() {
        return (
            <Grid item xs={12}>
                <Backdrop
                    open={
                        this.props.loading !== undefined
                            ? this.props.loading
                            : true
                    }
                    style={{ zIndex: 12001 }}
                >
                    <CircularProgress color="primary" size="5rem" />
                </Backdrop>
                <Filter OnFilter={(filters: any) => this.OnFilter(filters)} />
                {this.GetWatchCountReport()}
            </Grid>
        );
    }
}

function mapStateToProps(state: RootState): WatchCountProps {
    return {
        loading: state.reports?.loading,
        watchCount: state.reports?.watchCount,
    };
}

function mapDispatchToProps(dispatch: Dispatch): WatchCountActionProps {
    return bindActionCreators({ getWatchCountReport }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchCount);
