import Copy from '../copy';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { bindActionCreators, Dispatch } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import { closeNotification } from '../../../store/notification/actions';
import { Notification as INotification } from '../../../store/notification/reducer';

interface NotificationProps {
    notifications?: Array<INotification>;
    copyDataRef?: any | null | undefined;
}

interface NotificationActionProps {
    closeNotification?: () => void;
}

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {};

export class Notification extends Component<Props> {
    OnClose = () => {
        this.props.closeNotification?.();
    };

    GetCopyData = (copyData: any) => {
        if (copyData) {
            return (
                <Copy
                    title={
                        <span
                            style={{
                                fontSize: '16px',
                            }}
                        >
                            Click to copy error data to clipboard.
                        </span>
                    }
                    data={JSON.stringify(copyData)}
                />
            );
        } else {
            return null;
        }
    };

    GetNotifications = () => {
        const notification = this.props.notifications?.[0];

        if (notification) {
            return (
                <Grid item>
                    <Snackbar
                        open={notification.open}
                        onClose={() => {
                            this.OnClose();
                        }}
                        ClickAwayListenerProps={{
                            mouseEvent: false,
                        }}
                        TransitionComponent={(props) => {
                            return <Slide {...props} direction="left" />;
                        }}
                        message={
                            (notification as any).message.message ??
                            notification.message
                        }
                        anchorOrigin={notification.anchor}
                        autoHideDuration={notification.autoHide}
                        action={
                            <>
                                {/* {this.GetCopyData(notification.copyData)} */}
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="primary"
                                    onClick={() => {
                                        this.OnClose();
                                    }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                    >
                        {notification.severity ? (
                            <MuiAlert
                                onClose={() => {
                                    this.OnClose();
                                }}
                                icon={notification.icon}
                                severity={notification.severity}
                                action={
                                    <>
                                        {this.GetCopyData(
                                            notification.copyData
                                        )}
                                        <IconButton
                                            color="inherit"
                                            onClick={() => {
                                                this.OnClose();
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </>
                                }
                            >
                                {(notification as any).message.message ??
                                    notification.message}
                            </MuiAlert>
                        ) : undefined}
                    </Snackbar>
                </Grid>
            );
        } else {
            return undefined;
        }
    };

    render() {
        if (
            !this.props ||
            !this.props.notifications ||
            this.props.notifications?.length < 1
        ) {
            return null;
        }

        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-end"
            >
                {this.GetNotifications()}
            </Grid>
        );
    }
}

function mapStateToProps(state: any): NotificationProps {
    return { notifications: state.notifications };
}

function mapDispatchToProps(dispatch: Dispatch): NotificationActionProps {
    return bindActionCreators({ closeNotification }, dispatch);
}

export default connect<NotificationProps, NotificationActionProps>(
    mapStateToProps,
    mapDispatchToProps
)(Notification);
