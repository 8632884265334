import { Action } from '..';
import { types as ACCORDION_TYPES } from './actions';

export interface Accordion {
    id: string | undefined;
    open: boolean;
}

const initalState: Array<Accordion> = JSON.parse(
    localStorage.getItem('accordions') ?? JSON.stringify([])
);

interface IAction extends Action {
    type: any;
    payload: any;
}

export default function reducer(state = initalState, action: IAction) {
    let newState: any = [];
    let found: any = false;
    switch (action.type) {
        case ACCORDION_TYPES.ADD_ACCORDION:
            newState = state.map((accordion) => {
                if (accordion.id === action.payload.id) {
                    found = true;
                }
                return accordion;
            });

            if (!found) {
                newState.push(action.payload);
            }

            localStorage.setItem('accordions', JSON.stringify(newState));
            return newState;
        case ACCORDION_TYPES.TOGGLE_ACCORDION:
            newState = state.map((accordion) => {
                if (accordion.id !== action.payload) {
                    return accordion;
                }

                found = true;

                return {
                    ...accordion,
                    open: !accordion.open,
                };
            });

            if (!found) {
                newState.push({
                    id: action.payload,
                    open: true,
                });
            }

            localStorage.setItem('accordions', JSON.stringify(newState));
            return newState;
        default:
            return state;
    }
}
