import { Action } from ".."
import { types as PROVIDERS } from "./actions"

export interface Channel {
	id?: number
	name: string
	marker: string
	follow: boolean
	maxDaysOld?: number
	feed_provider_id?: number
}

export interface Provider {
	id: number
	title: string
	description: string
	channels: Array<Channel>
	media_credit_logo_filename: string
	aggregation_user_parameters: any
	sort_index: number
	record_status: number
	created: Date
	created_by: number
	last_update_date: Date
	last_update_by: number
	ip_created: string
	ip_updated: string
}

export interface ProviderState {
	loading: boolean
	providers: Array<Provider> | null
}

const initalState: ProviderState = {
	providers: null,
	loading: false,
}

interface IAction extends Action {
	type: any
	payload: any
}

export default function reducer(state = initalState, action: IAction) {
	switch (action.type) {
		case PROVIDERS.CREATE_PROVIDER:
			let newProviders: Array<Provider> = state.providers ?? []

			return {
				...state,
				providers: [action.payload, ...newProviders].sort(
					(a, b) => a.id - b.id
				),
			}
		case PROVIDERS.DELETE_PROVIDER:
			if (!state.providers) {
				state.providers = []
			}

			return {
				...state,
				providers: state.providers
					.filter(
						(provider) =>
							provider.id?.toString() !==
							action.payload.toString()
					)
					.sort((a, b) => a.id - b.id),
			}
		case PROVIDERS.UPDATE_PROVIDER:
			if (!state.providers) {
				state.providers = []
			}

			return {
				...state,
				providers: state.providers
					.map((provider) => {
						return provider.id?.toString() ===
							action.payload.id?.toString()
							? action.payload
							: provider
					})
					.sort((a, b) => a.order - b.order),
			}
		case PROVIDERS.GET_PROVIDERS:
			return {
				...state,
				providers: action.payload.sort(
					(a: Provider, b: Provider) => a.id - b.id
				),
			}
		case PROVIDERS.MOVE_PROVIDER:
			let providers: any = state.providers

			// ;[
			// 	providers[action.payload.index].order,
			// 	providers[
			// 		(action.payload.goingDown ? 1 : -1) + action.payload.index
			// 	].order,
			// ] = [
			// 	providers[
			// 		(action.payload.goingDown ? 1 : -1) + action.payload.index
			// 	].order,
			// 	providers[action.payload.index].order,
			// ]

			return {
				...state,
				providers: providers.sort(
					(a: Provider, b: Provider) => a.id - b.id
				),
			}
		case PROVIDERS.TOGGLE_LOADING:
			return {
				...state,
				loading: action.payload,
			}
		default:
			return state
	}
}
