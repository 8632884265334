import "./style.scss"
import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"

import { connect } from "react-redux"
import { bindActionCreators, Dispatch } from "redux"
import {
	ListItem,
	FormControlLabel,
	Radio,
	ListItemSecondaryAction,
	IconButton,
	Collapse,
	ListItemText,
	GridListTile,
	GridList,
} from "@material-ui/core"
import { get } from "../../plugins/api"
import { RootState } from "../../store"
import List from "@material-ui/core/List"
import { User } from "../../store/user/reducer"
import { toggleDrawer } from "../../store/drawer/actions"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { createNotification } from "../../store/notification/actions"

interface CategoryListProps {
	user?: User
	loading?: boolean
	toggledStates?: any
	categories?: Array<any>
	toggleDrawer?: typeof toggleDrawer
	selected?: string | number | undefined
	sort?: { key: string | undefined; direction: "asc" | "desc" | undefined }
	createNotification?: typeof createNotification
}

interface CategoryListActionProps {
	toggleDrawer?: typeof toggleDrawer
	createNotification?: typeof createNotification
}

export class CategoryList extends Component<
	CategoryListProps,
	CategoryListProps,
	CategoryListActionProps
> {
	constructor(props: CategoryListProps) {
		super(props)

		this.state = {
			sort: undefined,
			toggledStates: {},
		}
	}

	componentDidMount() {
		get("/v1/categories")
			.then((categories: Array<any>) => {
				sessionStorage.setItem("categories", JSON.stringify(categories))

				this.setState({
					...this.state,
					categories,
					loading: false,
				})
			})
			.catch((e) => {
				this.props.createNotification?.({
					message: e.message ?? e,
					severity: "error",
				})

				// localStorage.setItem('redirect', window.location.pathname);
				// window.location.href = '/v1/auth/google';
			})
	}

	ToggleOptionMenu = (id: number) => {
		let toggledStates = this.state.toggledStates

		if (!toggledStates[id]) {
			toggledStates[id] = true
		} else {
			toggledStates[id] = !toggledStates[id]
		}

		this.setState({
			toggledStates: toggledStates,
		})
	}

	GetCategoryList = (
		categories: Array<any> | null = null,
		paddingMultiplier: number = 1
	) => {
		if (!this.state.categories) {
			return null
		}

		return (categories ?? this.state.categories).flatMap(
			(category: any) => {
				if (!category) {
					return undefined
				}

				return [
					<ListItem
						key={category.id}
						dense={this.props.user?.denseMode}
						button
						onClick={() => {
							this.ToggleOptionMenu(category.id)
						}}
						style={{
							paddingTop: "0.125rem",
							paddingBottom: "0.125rem",
							paddingLeft: `${paddingMultiplier * 1}rem`,
						}}
					>
						<ListItemText>
							{category.id}: {category.title}
						</ListItemText>
						{category.categories && category.categories.length > 0 && (
							<ListItemSecondaryAction
								style={{ display: "flex" }}
							>
								<Tooltip
									title={
										<Typography variant="subtitle2">
											Show
											{this.state.toggledStates &&
											this.state.toggledStates[
												category.id
											]
												? " less"
												: " more"}
										</Typography>
									}
								>
									<IconButton
										onClick={() => {
											this.ToggleOptionMenu(category.id)
										}}
									>
										{this.state.toggledStates &&
										this.state.toggledStates[
											category.id
										] ? (
											<ExpandLess fontSize="small" />
										) : (
											<ExpandMore fontSize="small" />
										)}
									</IconButton>
								</Tooltip>
							</ListItemSecondaryAction>
						)}
					</ListItem>,
					(category.categories && category.categories.length > 0 && (
						<Collapse
							in={
								this.state.toggledStates &&
								this.state.toggledStates[category.id]
							}
							timeout="auto"
							unmountOnExit
						>
							{this.GetCategoryList(
								category.categories,
								paddingMultiplier + 1
							)}
						</Collapse>
					)) ||
						undefined,
				]
			}
		)
	}

	OnChange = (event: any) => {
		this.setState({
			...this.state,
			[event.target.name]: event.target.value,
		})
	}

	render() {
		return (
			<Grid
				container
				alignContent="flex-start"
				style={{ height: "100%", width: "100%", overflowY: "scroll" }}
			>
				<List
					dense={this.props.user?.denseMode}
					style={{
						columns: "3 10vw",
						height: "100%",
						width: "100%",
						columnFill: "auto",
						overflowY: "scroll",
					}}
				>
					{this.GetCategoryList()}
				</List>
			</Grid>
		)
	}
}

function mapStateToProps(state: RootState): CategoryListProps {
	return {
		user: state.user,
		loading: state.reports?.loading,
	}
}

function mapDispatchToProps(dispatch: Dispatch): CategoryListActionProps {
	return bindActionCreators({ toggleDrawer, createNotification }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)
