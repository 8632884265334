import thunk from "redux-thunk"
import { combineReducers } from "redux"
import LogReducer, { Logs } from "./logs"
import SocketReducer from "./socket/reducer"
import UserReducer, { User } from "./user/reducer"
import FeedReducer, { Feed } from "./feed/reducer"
import ArticlesReducer, { ArticleState } from "./articles/reducer"
import ProvidersReducer, { ProviderState } from "./providers/reducer"
import { applyMiddleware, createStore } from "redux"
import AggregationItemsReducer, {
	AggregationItems,
} from "./aggregation/reducer"
import NotifyReducer, { Notify } from "./notify/reducer"
import DialogReducer, { Dialog } from "./dialog/reducer"
import DrawerReducer, { Drawer } from "./drawer/reducer"
import ReportReducer, { Report } from "./report/reducer"
import EnvironmentReducer, { Environment } from "./environment"
import AccordionReducer, { Accordion } from "./accordion/reducer"
import PermissionReducer, { Permissions } from "./permissions/reducer"
import NotificationReducer, { Notification } from "./notification/reducer"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"

const initialState = {}
const middleware = [thunk]

const store = createStore(
	combineReducers({
		logs: LogReducer,
		feed: FeedReducer,
		user: UserReducer,
		dialog: DialogReducer,
		notify: NotifyReducer,
		socket: SocketReducer,
		drawers: DrawerReducer,
		reports: ReportReducer,
		articles: ArticlesReducer,
		providers: ProvidersReducer,
		accordions: AccordionReducer,
		permissions: PermissionReducer,
		environment: EnvironmentReducer,
		notifications: NotificationReducer,
		aggregationItems: AggregationItemsReducer,
	}),
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
)

export interface Action {
	type: any
	payload: any
}

export interface RootState {
	feed?: Feed
	user?: User
	logs?: Logs
	dialog: Dialog
	notify?: Notify
	reports?: Report
	articles: ArticleState
	providers: ProviderState
	drawers?: Array<Drawer>
	environment?: Environment
	permissions?: Permissions
	notification?: Notification
	accordions?: Array<Accordion>
	socket?: SocketIOClient.Socket
	aggregationItems?: AggregationItems
}

export default store
