import { Action } from '..';
import { types as ARTICLES } from './actions';

export interface Article {
    date: Date;
    id: number;
    link: string;
    title: string;
    order: number;
    provider: string;
    thumbnail: string;
}

export interface ArticleState {
    loading: boolean;
    articles: Array<Article> | null;
}

const initalState: ArticleState = {
    articles: null,
    loading: false,
};

interface IAction extends Action {
    type: any;
    payload: any;
}

export default function reducer(state = initalState, action: IAction) {
    switch (action.type) {
        case ARTICLES.CREATE_ARTICLE:
            let newArticles: Array<Article> = state.articles ?? [];

            newArticles.forEach((article) => {
                article.order++;
                return article;
            });

            return {
                ...state,
                articles: [action.payload, ...newArticles].sort(
                    (a, b) => a.order - b.order
                ),
            };
        case ARTICLES.DELETE_ARTICLE:
            if (!state.articles) {
                state.articles = [];
            }

            return {
                ...state,
                articles: state.articles
                    .filter(
                        (article) =>
                            article.id?.toString() !== action.payload.toString()
                    )
                    .sort((a, b) => a.order - b.order),
            };
        case ARTICLES.UPDATE_ARTICLE:
            if (!state.articles) {
                state.articles = [];
            }

            return {
                ...state,
                articles: state.articles
                    .map((article) => {
                        return article.id?.toString() ===
                            action.payload.id?.toString()
                            ? action.payload
                            : article;
                    })
                    .sort((a, b) => a.order - b.order),
            };
        case ARTICLES.GET_ARTICLES:
            return {
                ...state,
                articles: action.payload.sort(
                    (a: Article, b: Article) => a.order - b.order
                ),
            };
        case ARTICLES.MOVE_ARTICLE:
            let articles: any = state.articles;

            [
                articles[action.payload.index].order,
                articles[
                    (action.payload.goingDown ? 1 : -1) + action.payload.index
                ].order,
            ] = [
                articles[
                    (action.payload.goingDown ? 1 : -1) + action.payload.index
                ].order,
                articles[action.payload.index].order,
            ];

            return {
                ...state,
                articles: articles.sort(
                    (a: Article, b: Article) => a.order - b.order
                ),
            };
        case ARTICLES.TOGGLE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
}
