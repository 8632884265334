import Drawer from "../utils/drawer"
import { connect } from "react-redux"
import { RootState } from "../../store"
import React, { Component } from "react"
import List from "@material-ui/core/List"
import Accordion from "../utils/accordion"
import Button from "@material-ui/core/Button"
import { User } from "../../store/user/reducer"
import Tooltip from "@material-ui/core/Tooltip"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import { RouteComponentProps } from "react-router"
import PeopleIcon from "@material-ui/icons/People"
import { Link, withRouter } from "react-router-dom"
import { bindActionCreators, Dispatch } from "redux"
import RssFeedIcon from "@material-ui/icons/RssFeed"
import StorageIcon from "@material-ui/icons/Storage"
import Typography from "@material-ui/core/Typography"
import CategoryIcon from "@material-ui/icons/Category"
import SettingsIcon from "@material-ui/icons/Settings"
import DashboardIcon from "@material-ui/icons/Dashboard"
import { toggleDrawer } from "../../store/drawer/actions"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import AssessmentIcon from "@material-ui/icons/Assessment"
import VisibilityIcon from "@material-ui/icons/Visibility"
import AssignmentIcon from "@material-ui/icons/Assignment"
import DescriptionIcon from "@material-ui/icons/Description"
import AccountTreeIcon from "@material-ui/icons/AccountTree"
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive"
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant"

interface CategoryProps {
	user?: User
	sort?: {
		field: string
		direction: "desc" | "asc"
	}
	report?: any
	loading?: boolean
	toggleDrawer?: typeof toggleDrawer
}

interface CategoryActionProps {
	toggleDrawer?: typeof toggleDrawer
}

export class Category extends Component<
	CategoryProps & RouteComponentProps,
	CategoryProps & RouteComponentProps,
	CategoryActionProps
> {
	render() {
		return (
			<Drawer id="menu">
				<ListItem
					button
					onClick={() => this.props.toggleDrawer?.("menu")}
					style={{
						top: 0,
						zIndex: 1,
						position: "sticky",
						backgroundColor: "inherit",
					}}
				>
					<img
						alt=""
						height="100%"
						src="https://www.snibblecorp.com/assets/brandassets/snibble_logo.svg"
					/>
				</ListItem>

				<Divider />
				<div
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					{this.props.user && (
						<>
							<Accordion
								id="reporting"
								summary={
									<>
										<ListItemIcon>
											<Tooltip
												title={
													<Typography variant="subtitle2">
														Reporting
													</Typography>
												}
											>
												<AssessmentIcon fontSize="large" />
											</Tooltip>
										</ListItemIcon>
										<ListItemText
											primary={
												<Typography variant="subtitle1">
													Reporting
												</Typography>
											}
										/>
									</>
								}
								details={
									<List
										dense={this.props.user.denseMode}
										style={{
											padding: 0,
											width: "100%",
										}}
									>
										{[
											{
												text: "Category",
												icon: (item: any) => (
													<CategoryIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/reports/categories",
											},
											{
												text: "Jobs",
												icon: (item: any) => (
													<AccountTreeIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/reports/jobs",
											},
											{
												text: "Notifications",
												icon: (item: any) => (
													<NotificationImportantIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/reports/notifications",
											},
											{
												text: "View Counts",
												icon: (item: any) => (
													<VisibilityIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/reports/watch-count",
											},
										].map((item, index) => (
											<ListItem
												button
												to={item.link}
												component={Link}
												key={item.text + Math.random()}
												onClick={() => {
													if (
														window.location
															.pathname ===
														item.link
													) {
														window.location.reload()
													}
												}}
												style={{
													paddingLeft: "2.5rem",
												}}
											>
												<ListItemIcon>
													<Tooltip
														title={
															<Typography variant="subtitle2">
																{item.text}
															</Typography>
														}
													>
														{item.icon(item)}
													</Tooltip>
												</ListItemIcon>
												<ListItemText
													primary={item.text}
													style={{
														color: "black",
													}}
												/>
											</ListItem>
										))}
									</List>
								}
							/>

							<Accordion
								id="dashboard"
								summary={
									<>
										<ListItemIcon>
											<Tooltip
												title={
													<Typography variant="subtitle2">
														Dashboard
													</Typography>
												}
											>
												<DashboardIcon fontSize="large" />
											</Tooltip>
										</ListItemIcon>
										<ListItemText
											primary={
												<Typography variant="subtitle1">
													Dashboard
												</Typography>
											}
										/>
									</>
								}
								details={
									<List
										dense={this.props.user.denseMode}
										style={{
											padding: 0,
											width: "100%",
										}}
									>
										{[
											{
												text: "Category List",
												icon: (item: any) => (
													<CategoryIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/category-list",
											},
											{
												text: "Video Descriptions",
												icon: (item: any) => (
													<DescriptionIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/custom-sources",
											},
											{
												text: "Feed",
												icon: (item: any) => (
													<RssFeedIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/feed",
											},
											{
												text: "Notify",
												icon: (item: any) => (
													<NotificationsActiveIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/notify",
											},
											{
												text: "Aggregation",
												icon: (item: any) => (
													<StorageIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/aggregation",
											},
										].map((item) => (
											<ListItem
												button
												to={item.link}
												component={Link}
												onClick={() => {
													if (
														window.location
															.pathname ===
														item.link
													) {
														window.location.reload()
													}
												}}
												key={item.text + Math.random()}
												style={{
													paddingLeft: "2.5rem",
												}}
											>
												<ListItemIcon>
													<Tooltip
														title={
															<Typography variant="subtitle2">
																{item.text}
															</Typography>
														}
													>
														{item.icon(item)}
													</Tooltip>
												</ListItemIcon>
												<ListItemText
													primary={item.text}
													style={{
														color: "black",
													}}
												/>
											</ListItem>
										))}
									</List>
								}
							/>

							<Accordion
								id="settings"
								summary={
									<>
										<ListItemIcon>
											<Tooltip
												title={
													<Typography variant="subtitle2">
														Settings
													</Typography>
												}
											>
												<SettingsIcon fontSize="large" />
											</Tooltip>
										</ListItemIcon>
										<ListItemText
											primary={
												<Typography variant="subtitle1">
													Settings
												</Typography>
											}
										/>
									</>
								}
								details={
									<List
										dense={this.props.user.denseMode}
										style={{
											padding: 0,
											width: "100%",
										}}
									>
										{[
											{
												text: "Articles",
												icon: (item: any) => (
													<AssignmentIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/articles",
											},
										/*	{
												text: "Manage Categories",
												icon: (item: any) => (
													<AssignmentIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/manage-categories",
											},*/
											{
												text: "Providers",
												icon: (item: any) => (
													<PeopleIcon
														color={
															window.location
																.pathname ===
															item.link
																? "primary"
																: undefined
														}
													/>
												),
												link: "/providers",
											},
										].map((item) => (
											<ListItem
												button
												to={item.link}
												component={Link}
												onClick={() => {
													if (
														window.location
															.pathname ===
														item.link
													) {
														window.location.reload()
													}
												}}
												key={item.text + Math.random()}
												style={{
													paddingLeft: "2.5rem",
												}}
											>
												<ListItemIcon>
													<Tooltip
														title={
															<Typography variant="subtitle2">
																{item.text}
															</Typography>
														}
													>
														{item.icon(item)}
													</Tooltip>
												</ListItemIcon>
												<ListItemText
													primary={item.text}
													style={{
														color: "black",
													}}
												/>
											</ListItem>
										))}
									</List>
								}
							/>
						</>
					)}

					<span style={{ margin: "auto 0" }} />

					{(this.props.user && (
						<Button
							variant="outlined"
							style={{
								margin: 0,
								padding: 0,
								height: "61px",
								border: "none",
								borderRadius: 0,
								color: "rgba(0, 0, 0, 0.54)",
								borderTop: "1px solid rgba(0, 0, 0, .125)",
							}}
						>
							<Tooltip
								title={
									<Typography variant="subtitle2">
										Profile
									</Typography>
								}
							>
								<AccountBoxIcon fontSize="large" />
							</Tooltip>
						</Button>
					)) || (
						<Button
							fullWidth
							color="primary"
							variant="contained"
							style={{
								margin: 0,
								padding: 0,
								height: "61px",
								borderRadius: 0,
							}}
						>
							<Tooltip
								title={
									<Typography variant="subtitle2">
										Login
									</Typography>
								}
							>
								<Typography variant="subtitle1">
									Login
								</Typography>
							</Tooltip>
						</Button>
					)}
				</div>
			</Drawer>
		)
	}
}

function mapStateToProps(state: RootState): CategoryProps {
	return {
		user: state.user,
		loading: state.reports?.loading,
		report: state.reports?.categories,
	}
}

function mapDispatchToProps(dispatch: Dispatch): CategoryActionProps {
	return bindActionCreators({ toggleDrawer }, dispatch)
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Category)
)
