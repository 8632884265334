import LazyLoad from "react-lazyload"
import Grid from "@material-ui/core/Grid"
import MCard from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import React, { Component, CSSProperties, ElementType } from "react"
import CardHeader from "@material-ui/core/CardHeader"
import Typography from "@material-ui/core/Typography"

type Variant =
	| "button"
	| "caption"
	| "h1"
	| "h2"
	| "h3"
	| "h4"
	| "h5"
	| "h6"
	| "inherit"
	| "subtitle1"
	| "subtitle2"
	| "body1"
	| "body2"
	| "overline"
	| "srOnly"
	| undefined

type Color =
	| "inherit"
	| "initial"
	| "primary"
	| "secondary"
	| "textSecondary"
	| "textPrimary"
	| "error"
	| undefined

interface Description {
	text: string
	textSettings?: TextSettings
}

interface Header {
	title: string
	avatar?: JSX.Element
	action?: ElementType<any>
	subheader: JSX.Element | string
	titleTextSettings?: TextSettings
	subheaderTextSettings?: TextSettings
}

interface Media {
	style?: any
	src?: string
	onClick?: any
	title: string
	image?: string
	override?: any
	offset?: number
	component?: ElementType<any>
}

interface TextSettings {
	color?: Color
	variant?: Variant
	component?: ElementType<any>
}

interface CardProps {
	media?: Media
	header?: Header
	subheader?: any
	visible?: boolean
	style?: CSSProperties
	description?: Description | any
}

interface CardActionProps {}

class Card extends Component<CardProps, CardProps, CardActionProps> {
	constructor(props: CardProps) {
		super(props)

		this.state = {
			visible: false,
		}
	}
	render() {
		return (
			<MCard>
				<Grid
					container
					style={{
						padding: "0.5rem",
						...this.props.style,
					}}
				>
					<Grid item xs={12} style={{ height: "fit-content" }}>
						<CardHeader
							title={this.props.header?.title}
							action={this.props.header?.action}
							avatar={this.props.header?.avatar}
							subheader={this.props.header?.subheader}
							subheaderTypographyProps={
								this.props.header?.subheaderTextSettings
							}
							titleTypographyProps={
								this.props.header?.titleTextSettings
							}
						/>
					</Grid>
					<Grid item xs={12} style={this.props.media?.style}>
						<LazyLoad
							height={200}
							scrollContainer="#App-body"
							offset={this.props.media?.offset ?? 200}
						>
							<img
								style={{
									width: "99%",
									maxHeight: "360px",
								}}
								src={this.props.media?.src}
								title={this.props.media?.title}
								onClick={this.props.media?.onClick}
							/>
						</LazyLoad>
					</Grid>
					{this.props.subheader && (
						<Grid item xs={12}>
							{this.props.subheader}
						</Grid>
					)}
					<Grid item xs={12}>
						{(this.props.description.text && (
							<Typography
								component={
									this.props.description.textSettings
										?.component ?? "p"
								}
								variant={
									this.props.description.textSettings
										?.variant ?? "body2"
								}
								color={
									this.props.description.textSettings
										?.color ?? "textSecondary"
								}
							>
								{this.props.description.text}
							</Typography>
						)) ??
							this.props.description}
					</Grid>
				</Grid>
			</MCard>
		)
	}
}

export default Card
