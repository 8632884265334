import { RootState } from '..';
import { ThunkDispatch } from 'redux-thunk';
import { del, get, post } from '../../plugins/api';
import { createNotification } from '../notification/actions';

export enum types {
    GET_ARTICLES = 'GET_ARTICLES',
    MOVE_ARTICLE = 'MOVE_ARTICLE',
    CREATE_ARTICLE = 'CREATE_ARTICLE',
    DELETE_ARTICLE = 'DELETE_ARTICLE',
    UPDATE_ARTICLE = 'UPDATE_ARTICLE',
    TOGGLE_LOADING = 'TOGGLE_LOADING',
}

export const getArticles = () => async (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    try {
        dispatch({
            type: types.TOGGLE_LOADING,
            payload: true,
        });

        const articles = await get('/v1/articles');

        setTimeout(() => {
            dispatch({
                type: types.TOGGLE_LOADING,
                payload: false,
            });

            dispatch({
                type: types.GET_ARTICLES,
                payload: articles,
            });
        }, 1000);
    } catch (error) {
        console.error(error);

        dispatch({
            type: types.TOGGLE_LOADING,
            payload: false,
        });

        dispatch(
            createNotification({
                message: 'Something went wrong, please try again later.',
                severity: 'error',
            })
        );
    }
};

export const createArticle = (article: any) => async (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    try {
        dispatch({
            type: types.TOGGLE_LOADING,
            payload: true,
        });

        article = await post('/v1/articles', article);

        setTimeout(() => {
            dispatch({
                type: types.TOGGLE_LOADING,
                payload: false,
            });

            dispatch({
                type: types.CREATE_ARTICLE,
                payload: article,
            });
        }, 1000);
    } catch (error) {
        console.error(error);

        dispatch({
            type: types.TOGGLE_LOADING,
            payload: false,
        });
        dispatch(
            createNotification({
                message: 'Something went wrong, please try again later.',
                severity: 'error',
            })
        );
    }
};

export const deleteArticle = (id: string | number) => async (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    try {
        dispatch({
            type: types.TOGGLE_LOADING,
            payload: true,
        });

        await del(`/v1/articles/${id}`);
        setTimeout(() => {
            dispatch({
                type: types.TOGGLE_LOADING,
                payload: false,
            });

            dispatch({
                type: types.DELETE_ARTICLE,
                payload: id,
            });
        }, 1000);
    } catch (error) {
        console.error(error);

        dispatch({
            type: types.TOGGLE_LOADING,
            payload: false,
        });
        dispatch(
            createNotification({
                message: error,
                severity: 'error',
            })
        );
    }
};

export const rearrangeArticles = (
    index: number,
    goingDown: boolean = false
) => (dispatch: ThunkDispatch<RootState, any, any>) => {
    try {
        dispatch({
            type: types.MOVE_ARTICLE,
            payload: {
                index,
                goingDown,
            },
        });
    } catch (error) {
        console.error(error);
        dispatch({
            type: types.TOGGLE_LOADING,
            payload: false,
        });
        dispatch(
            createNotification({
                message: error,
                severity: 'error',
            })
        );
    }
};

export const updateArticle = (article: any, toggle: boolean = true) => async (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    try {
        if (toggle) {
            dispatch({
                type: types.TOGGLE_LOADING,
                payload: true,
            });
        }

        article = await post(`/v1/articles/${article.id}`, article);

        setTimeout(() => {
            if (toggle) {
                dispatch({
                    type: types.TOGGLE_LOADING,
                    payload: false,
                });
            }

            dispatch({
                type: types.UPDATE_ARTICLE,
                payload: article,
            });
        }, 1000);
    } catch (error) {
        console.error(error);

        if (toggle) {
            dispatch({
                type: types.TOGGLE_LOADING,
                payload: true,
            });
        }

        dispatch(
            createNotification({
                message: 'Something went wrong, please try again later.',
                severity: 'error',
            })
        );
    }
};

export const setLoading = (isLoading: boolean = false) => async (
    dispatch: ThunkDispatch<RootState, any, any>
) => {
    try {
        dispatch({
            type: types.TOGGLE_LOADING,
            payload: isLoading,
        });
    } catch (error) {
        console.error(error);

        dispatch(
            createNotification({
                message: error,
                severity: 'error',
            })
        );
    }
};
