import { Action } from ".."
import { types as DIALOG_TYPES } from "./actions"

export interface Dialog {
	title: any
	content: any
	open: boolean
	cb?: {
		triggerState: boolean
		cb: any
	}
}

const initalState: Dialog = {
	open: false,
	title: null,
	content: null,
}

interface IAction extends Action {
	cb?: any
	type: any
	payload: any
}

export default function reducer(state = initalState, action: IAction) {
	switch (action.type) {
		case DIALOG_TYPES.TOGGLE_DIALOG:
			const newOpenState =
				action.payload !== null ? action.payload : !state.open

			let newState = {
				...state,
				open: newOpenState,
				cb:
					action.cb !== undefined
						? {
                            triggerState: state.open,
								cb: action.cb,
						  }
						: undefined,
			}

			console.log(
				state.cb && state.cb?.triggerState === newOpenState,
				state.cb && state.cb, newOpenState
			)
			if (state.cb && state.cb?.triggerState === newOpenState) {
				state.cb.cb()
				// delete newState.cb
			}

			return newState
		case DIALOG_TYPES.SET_CONTENT:
			return {
				...state,
				content: action.payload,
			}
		default:
			return state
	}
}
