import React, { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	getProviderList,
	getProviderUrls,
} from "../../store/aggregation/actions"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { RootState } from "../../store"
import Button from "@material-ui/core/Button"
import { post } from "../../plugins/api"
import {
	List,
	Tooltip,
	ListItem,
	Typography,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import ErrorIcon from "@material-ui/icons/Error"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"

function Aggregation() {
	const dispatch = useDispatch()

	const messageDiv = useRef(null)

	const [messages, setMessages] = useState<Array<string>>([])

	/*
        Provider Arrays
    */
	const [selectedProviders, setSelectedProviders] = useState<
		Array<any> | undefined
	>(undefined)
	const [
		selectedExcludedProviderURLs,
		setSelectedExcludedProviderURLs,
	] = useState<Array<any> | undefined>(undefined)
	const [selectedProviderURLs, setSelectedProviderURLs] = useState<
		Array<any> | undefined
	>(undefined)
	const [selectedExcludedProviders, setSelectedExcludedProviders] = useState<
		Array<any> | undefined
	>(undefined)

	/*
        Booleans
    */
	const [to, setTo] = useState<any>(null)
	const [from, setFrom] = useState<any>(null)
	const [aws, setAws] = useState<boolean>(false)
	const [cache, setCache] = useState<boolean>(false)
	const [dryRun, setDryRun] = useState<boolean>(false)
	const [replicate, setReplicate] = useState<boolean>(false)
	const [cleanCache, setCleanCache] = useState<boolean>(false)
	const [localFeedOnly, setLocalFeedOnly] = useState<boolean>(false)
	const [storeResponses, setStoreResponses] = useState<boolean>(false)
	const [aggregateThumbnails, setAggregateThumbnails] = useState<boolean>(
		false
	)
	const [simulateDurations, setSimulateDurations] = useState<boolean>(false)

	const { user, socket, isLoading, providers, providerUrls } = useSelector(
		(state: RootState) => ({
			user: state.user,
			socket: state.socket,
			isLoading: state.aggregationItems?.loading,
			providers: state.aggregationItems?.providers,
			providerUrls: state.aggregationItems?.providerUrls,
		})
	)

	useEffect(() => {
		socket?.on("aggregation-info", (msg: any) => {
			if (msg.message) {
				setMessages((oldMessages) => [...oldMessages, msg])
				;(messageDiv.current as any).scrollIntoView({
					behavior: "smooth",
				})
			}
		})

		if (!isLoading) {
			let funcs = []

			if (!providers) {
				funcs.push(dispatch(getProviderList()))
			}

			if (!providerUrls) {
				funcs.push(dispatch(getProviderUrls()))
			}

			if (funcs.length > 0) {
				Promise.all(funcs).then(() => console.log("sad"))
			}
		}
	}, [])

	const checkboxes = (
		<Grid item xs={12} container>
			<Grid item xs={12} md={2}>
				<Tooltip
					title={
						<Typography variant="subtitle2">
							If YES no changes will be committed to the database,
							if NO the aggregation will commit all changes to the
							database.
						</Typography>
					}
				>
					<FormControlLabel
						color="primary"
						label="Dry Run"
						name="dryRun"
						control={
							<Checkbox
								color="primary"
								name="dryRun"
								checked={dryRun}
								aria-label="Dry Run"
								onChange={() => setDryRun(!dryRun)}
							/>
						}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={12} md={2}>
				<Tooltip
					title={
						<Typography variant="subtitle2">
							If YES a random integer will be returned, if NO
							ffprobe will be run to get the duration.
						</Typography>
					}
				>
					<FormControlLabel
						color="primary"
						label="Local Feed Only"
						name="onlyLocalFeedItems"
						control={
							<Checkbox
								color="primary"
								name="localFeedOnly"
								checked={localFeedOnly}
								aria-label="Local Feed Only"
								onChange={() =>
									setLocalFeedOnly(!localFeedOnly)
								}
							/>
						}
					/>
				</Tooltip>{" "}
			</Grid>
			<Grid item xs={12} md={2}>
				<Tooltip
					title={
						<Typography variant="subtitle2">
							If YES aggregate the thumbnails from the provider as
							well, if NO skip aggregating the thumbnails.
						</Typography>
					}
				>
					<FormControlLabel
						color="primary"
						name="aggregateThumbnails"
						label="Aggregate Thumbnails"
						control={
							<Checkbox
								color="primary"
								name="aggregateThumbnails"
								checked={aggregateThumbnails}
								aria-label="Aggregate Thumbnails"
								onChange={() =>
									setAggregateThumbnails(!aggregateThumbnails)
								}
							/>
						}
					/>
				</Tooltip>{" "}
			</Grid>
			<Grid item xs={12} md={2}>
				<Tooltip
					title={
						<Typography variant="subtitle2">
							If YES and dryrun is NO download source MP4 files
							from providers and upload them for transcoding to S3
							bucket, if NO or dryrun is YES skip this process.
						</Typography>
					}
				>
					<FormControlLabel
						name="aws"
						color="primary"
						label="Upload files to AWS"
						control={
							<Checkbox
								name="aws"
								checked={aws}
								color="primary"
								aria-label="Upload files to AWS"
								onChange={() => setAws(!aws)}
							/>
						}
					/>
				</Tooltip>{" "}
			</Grid>
			<Grid item xs={12} md={2}>
				<Tooltip
					title={
						<Typography variant="subtitle2">
							If YES and dryrun is NO sync the current collection
							and indices with the live version, if NO or dryrun
							is YES skip this process.
						</Typography>
					}
				>
					<FormControlLabel
						color="primary"
						name="replicate"
						label="Replicate"
						control={
							<Checkbox
								name="aws"
								color="primary"
								checked={replicate}
								aria-label="Replicate"
								onChange={() => setReplicate(!replicate)}
							/>
						}
					/>
				</Tooltip>{" "}
			</Grid>
			<Grid item xs={12} md={2}>
				<Tooltip
					title={
						<Typography variant="subtitle2">
							If YES a random integer will be returned, if NO
							ffprobe will be run to get the duration.
						</Typography>
					}
				>
					<FormControlLabel
						color="primary"
						name="simulateDuration"
						label="Simluate Durations"
						control={
							<Checkbox
								color="primary"
								name="simulateDuration"
								checked={simulateDurations}
								aria-label="Simulate Durations"
								onChange={() =>
									setSimulateDurations(!simulateDurations)
								}
							/>
						}
					/>
				</Tooltip>{" "}
			</Grid>
			<Grid item xs={12} md={2}>
				<Tooltip
					title={
						<Typography variant="subtitle2">
							If YES store the raw API endpoint response, if NO
							skip this process.
						</Typography>
					}
				>
					<FormControlLabel
						color="primary"
						name="storeResponses"
						label="Store Responses"
						control={
							<Checkbox
								color="primary"
								name="storeResponses"
								checked={storeResponses}
								aria-label="Store Responses"
								onChange={() =>
									setStoreResponses(!storeResponses)
								}
							/>
						}
					/>
				</Tooltip>{" "}
			</Grid>
			<Grid item xs={12} md={2}>
				<Tooltip
					title={
						<Typography variant="subtitle2">
							If YES check for local cached data, and do not issue
							a new HTTP call if the file exists. Other wise a
							cache file will be created after the HTTP call. If
							NO the script will not look or check for any cached
							files.
						</Typography>
					}
				>
					<FormControlLabel
						color="primary"
						name="cache"
						label="Cache"
						control={
							<Checkbox
								color="primary"
								name="cache"
								checked={cache}
								aria-label="Cache"
								onChange={() => setCache(!cache)}
							/>
						}
					/>
				</Tooltip>{" "}
			</Grid>
			<Grid item xs={12} md={2}>
				<Tooltip
					title={
						<Typography variant="subtitle2">
							If YES purge the cache results of the fetchFeed()
							call.
						</Typography>
					}
				>
					<FormControlLabel
						color="primary"
						name="cleanCache"
						label="Clean Cache"
						control={
							<Checkbox
								color="primary"
								name="cleanCache"
								checked={cleanCache}
								aria-label="Clean Cache"
								onChange={() => setCleanCache(!cleanCache)}
							/>
						}
					/>
				</Tooltip>{" "}
			</Grid>
		</Grid>
	)

	const selects = (
		<Grid item xs={12} container>
			<Grid
				item
				xs={12}
				md={6}
				style={{ height: "fit-content", padding: "0.5rem" }}
			>
				<Autocomplete
					multiple
					fullWidth
					limitTags={3}
					ChipProps={{
						color: "primary",
					}}
					disableCloseOnSelect
					value={selectedProviders ?? []}
					options={
						(selectedExcludedProviders
							? providers?.filter(
									(provider) =>
										!selectedExcludedProviders
											?.map(
												(excludedProvider) =>
													excludedProvider.id
											)
											.includes(provider.id)
							  )
							: providers) ?? []
					}
					getOptionLabel={(option) => option.title}
					renderInput={(params) => (
						<TextField
							{...params}
							helperText="If provided, only aggregate the specified
									providers."
							label="Providers"
						/>
					)}
					onChange={(_event, value: Array<any>) =>
						setSelectedProviders(value)
					}
					getOptionSelected={(option, value) =>
						option.id === value.id
					}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				style={{ height: "fit-content", padding: "0.5rem" }}
			>
				<Autocomplete
					multiple
					fullWidth
					limitTags={3}
					ChipProps={{
						color: "secondary",
					}}
					disableCloseOnSelect
					value={selectedExcludedProviders ?? []}
					options={
						(selectedProviders
							? providers?.filter(
									(provider) =>
										!selectedProviders
											?.map(
												(selectedProvider) =>
													selectedProvider.id
											)
											.includes(provider.id)
							  )
							: providers) ?? []
					}
					getOptionLabel={(option) => option.title}
					renderInput={(params) => (
						<TextField
							{...params}
							helperText="If provided, exclude the specified providers from the aggregation."
							label="Excluded Providers"
						/>
					)}
					onChange={(_event, value: Array<any>) =>
						setSelectedExcludedProviders(value)
					}
					getOptionSelected={(option, value) =>
						option.id === value.id
					}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				style={{ height: "fit-content", padding: "0.5rem" }}
			>
				<Autocomplete
					multiple
					fullWidth
					limitTags={1}
					ChipProps={{
						color: "primary",
					}}
					disableCloseOnSelect
					value={selectedProviderURLs ?? []}
					options={
						providerUrls?.filter((providerUrl) => {
							if (
								selectedExcludedProviderURLs &&
								selectedExcludedProviderURLs.length > 0 &&
								selectedExcludedProviderURLs
									.map(
										(selectedExcludedProviderURL) =>
											selectedExcludedProviderURL.id
									)
									.includes(providerUrl.id)
							) {
								return false
							}

							if (
								selectedProviders &&
								selectedProviders.length > 0
							) {
								return selectedProviders
									.map(
										(selectedProvider) =>
											selectedProvider.id
									)
									.includes(providerUrl.feed_provider_id)
							} else if (
								selectedExcludedProviders &&
								selectedExcludedProviders.length > 0
							) {
								return !selectedExcludedProviders
									.map(
										(selectedExcludedProvider) =>
											selectedExcludedProvider.id
									)
									.includes(providerUrl.feed_provider_id)
							}

							return true
						}) ?? []
					}
					getOptionLabel={(option) => option.token}
					renderInput={(params) => (
						<TextField
							{...params}
							helperText="If provided, only aggregate the specified
									urls from the providers."
							label="Provider URLs"
						/>
					)}
					onChange={(_event, value: Array<any>) =>
						setSelectedProviderURLs(value)
					}
					getOptionSelected={(option, value) =>
						option.id === value.id
					}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				style={{ height: "fit-content", padding: "0.5rem" }}
			>
				<Autocomplete
					multiple
					fullWidth
					limitTags={1}
					ChipProps={{
						color: "secondary",
					}}
					disableCloseOnSelect
					value={selectedExcludedProviderURLs ?? []}
					options={
						providerUrls?.filter((providerUrl) => {
							if (
								selectedProviderURLs &&
								selectedProviderURLs.length > 0 &&
								selectedProviderURLs
									.map(
										(selectedProviderURL) =>
											selectedProviderURL.id
									)
									.includes(providerUrl.id)
							) {
								return false
							}

							if (
								selectedProviders &&
								selectedProviders.length > 0
							) {
								return selectedProviders
									.map(
										(selectedProvider) =>
											selectedProvider.id
									)
									.includes(providerUrl.feed_provider_id)
							} else if (
								selectedExcludedProviders &&
								selectedExcludedProviders.length > 0
							) {
								return !selectedExcludedProviders
									.map(
										(selectedExcludedProvider) =>
											selectedExcludedProvider.id
									)
									.includes(providerUrl.feed_provider_id)
							}

							return true
						}) ?? []
					}
					getOptionLabel={(option) => option.token}
					renderInput={(params) => (
						<TextField
							{...params}
							helperText="If provided, ignore the specified provider urls from the aggregation."
							label="Excluded Provider URLs"
						/>
					)}
					onChange={(_event, value: Array<any>) =>
						setSelectedExcludedProviderURLs(value)
					}
					getOptionSelected={(option, value) =>
						option.id === value.id
					}
				/>
			</Grid>
		</Grid>
	)

	return (
		<Grid item xs={12} container alignContent="flex-start" spacing={1}>
			{selects}
			<Grid item xs={12} container>
				<Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DateTimePicker
							fullWidth
							id="from"
							label="From"
							helperText="If provided, only aggregate data from after the specified date."
							value={from ?? null}
							onChange={(date) => setFrom(date)}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DateTimePicker
							fullWidth
							id="to"
							label="To"
							helperText="If provided, only aggregate data from before the specified date."
							value={to ?? null}
							onChange={(date) => setTo(date)}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
			</Grid>
			{checkboxes}
			<Grid item xs={12}>
				<Button
					onClick={async () => {
						const result = await post("/v1/aggregation", {
							aws,
							from,
							cache,
							dryRun,
							replicate,
							cleanCache,
							localFeedOnly,
							storeResponses,
							simulateDurations,
							aggregateThumbnails,
							providers: selectedProviders?.map(
								(selectedProvider) => selectedProvider.id
							),
							excludedProviders: selectedExcludedProviders?.map(
								(selectedExcludedProvider) =>
									selectedExcludedProvider.id
							),
							providerURLs: selectedProviderURLs?.map(
								(selectedProviderURL) => selectedProviderURL.id
							),
							excludedProviderURLs: selectedExcludedProviderURLs?.map(
								(selectedExcludedProviderURL) =>
									selectedExcludedProviderURL.id
							),
						})

						console.log(result)
					}}
				>
					Run Aggregation
				</Button>
			</Grid>
			<Grid item xs={12}>
				<List
					dense={user?.denseMode}
					style={{
						height: "60vh",
						overflow: "scroll",
						marginBottom: "1rem",
						backgroundColor: "white",
					}}
				>
					{messages.map((log: any) => (
						<ListItem>
							<ListItemIcon>
								{log.severity === "error" ? (
									<ErrorIcon
										style={{
											color: "#d32f2f",
										}}
									/>
								) : log.severity === "info" ? (
									<InfoIcon
										style={{
											color: "#2196f3",
										}}
									/>
								) : (
									<CheckCircleIcon
										style={{
											color: "#4caf50",
										}}
									/>
								)}
							</ListItemIcon>
							<ListItemText>
								<Typography
									variant="subtitle1"
									className="log-message"
								>
									{log.message}
								</Typography>
							</ListItemText>
						</ListItem>
					))}
					<span ref={messageDiv} />
				</List>
			</Grid>
		</Grid>
	)
}

export default Aggregation
