import { connect } from 'react-redux';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { bindActionCreators, Dispatch } from 'redux';
import Typography from '@material-ui/core/Typography';

interface LoginProps {}

interface LoginActionProps {}

export class Login extends Component<LoginProps, LoginProps, LoginActionProps> {
    render() {
        return (
            <Grid container justify="center" alignContent="center">
                <Grid item xs={12}>
                    <Tooltip
                        title={
                            <Typography variant="subtitle2">
                                Sign in with google
                            </Typography>
                        }
                    >
                        <Button href="/v1/auth/google">
                            <img
                                alt=""
                                style={{
                                    maxHeight: '52px',
                                }}
                                src="/assets/google_btn.png"
                            />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state: any, props: LoginProps): any {
    return {};
}

function mapDispatchToProps(dispatch: Dispatch): LoginActionProps {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
