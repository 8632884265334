import clsx from "clsx"
import FeedComponent from "../feed"
import { connect } from "react-redux"
import { RootState } from "../../store"
import Fab from "@material-ui/core/Fab"
import React, { Component } from "react"
import Step from "@material-ui/core/Step"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Radio from "@material-ui/core/Radio"
import { FixedSizeList } from "react-window"
import Button from "@material-ui/core/Button"
import { AutoSizer } from "react-virtualized"
import SendIcon from "@material-ui/icons/Send"
import Tooltip from "@material-ui/core/Tooltip"
import Stepper from "@material-ui/core/Stepper"
import { Feed } from "../../store/feed/reducer"
import ListItem from "@material-ui/core/ListItem"
import Checkbox from "@material-ui/core/Checkbox"
import StepLabel from "@material-ui/core/StepLabel"
import FormLabel from "@material-ui/core/FormLabel"
import TextField from "@material-ui/core/TextField"
import { bindActionCreators, Dispatch } from "redux"
import { withStyles } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import RadioGroup from "@material-ui/core/RadioGroup"
import SettingsIcon from "@material-ui/icons/Settings"
import FormControl from "@material-ui/core/FormControl"
import { getFeedItems } from "../../store/feed/actions"
import ListItemText from "@material-ui/core/ListItemText"
import VideoLabelIcon from "@material-ui/icons/VideoLabel"
import { StepIconProps } from "@material-ui/core/StepIcon"
import StepConnector from "@material-ui/core/StepConnector"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import FormHelperText from "@material-ui/core/FormHelperText"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { Notify as INotify } from "../../store/notify/reducer"
import { Dialog as IDialog } from "../../store/dialog/reducer"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import { setContent, toggleDialog } from "../../store/dialog/actions"
import {
	toggleItem,
	toggleTesting,
	sendNotification,
	OnChange as notifyOnChange,
	ResetNotify,
} from "../../store/notify/actions"
import FeedItem from "../feed/feedItem"
import { User } from "../../store/user/reducer"
import Autocomplete from "@material-ui/lab/Autocomplete"

class Row extends React.Component<any, any, any> {
	shouldComponentUpdate() {
		return true
	}

	render() {
		if (!this.props.feedItem) {
			return null
		}
		return (
			<ListItem
				dense={this.props.denseMode}
				button
				style={{ overflow: "hidden", ...this.props.style }}
				onClick={() => {
					this.props.onClick(this.props.feedItem.id)
					this.forceUpdate()
				}}
				key={this.props.feedItem.id}
			>
				<Checkbox
					value={this.props.feedItem.id}
					checked={
						this.props.data?.includes(this.props.feedItem.id) ??
						false
					}
				/>
				<ListItemText primary={this.props.feedItem.title} />
				<img
					width={145}
					alt=""
					src={this.props.feedItem.url_thumbnail}
				/>
			</ListItem>
		)
	}
}

interface NotifyProps {
	feed?: Feed
	user?: User
	step?: number
	notify?: INotify
	dialog?: IDialog
	selectedItems?: any
	notificationSent?: boolean
	socket?: SocketIOClient.Socket
	setContent?: typeof setContent
	toggleItem?: typeof toggleItem
	toggleDialog?: typeof toggleDialog
	getFeedItems?: typeof getFeedItems
	toggleTesting?: typeof toggleTesting
	notifyOnChange?: typeof notifyOnChange
	sendNotification?: typeof sendNotification
	ResetNotify?: typeof ResetNotify
}

interface NotifyActionProps {
	setContent?: typeof setContent
	toggleItem?: typeof toggleItem
	toggleDialog?: typeof toggleDialog
	getFeedItems?: typeof getFeedItems
	toggleTesting?: typeof toggleTesting
	notifyOnChange?: typeof notifyOnChange
	sendNotification?: typeof sendNotification
	ResetNotify?: typeof ResetNotify
}

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		"& $line": {
			backgroundImage:
				"linear-gradient(295.86deg, #2AEAFF -43.14%, #39CEFF 20.81%, #4DA9FE 105.71%)",
		},
	},
	completed: {
		"& $line": {
			backgroundImage:
				"linear-gradient(295.86deg, #2AEAFF -43.14%, #39CEFF 20.81%, #4DA9FE 105.71%)",
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderRadius: 1,
	},
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: "#ccc",
		zIndex: 1,
		color: "#fff",
		width: 50,
		height: 50,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	},
	active: {
		backgroundImage:
			"linear-gradient(295.86deg, #2AEAFF -43.14%, #39CEFF 20.81%, #4DA9FE 105.71%)",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	},
	completed: {
		cursor: "pointer",
		backgroundImage:
			"linear-gradient(295.86deg, #2AEAFF -43.14%, #39CEFF 20.81%, #4DA9FE 105.71%)",
	},
})

interface CustomStepIconProps extends StepIconProps {
	onClick?: any
}

function ColorlibStepIcon(props: CustomStepIconProps) {
	const classes = useColorlibStepIconStyles()
	const { active, completed } = props

	const icons: { [index: string]: React.ReactElement } = {
		1: <VideoLabelIcon />,
		2: <SettingsIcon />,
		3: <SendIcon />,
	}

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{icons[String(props.icon)]}
		</div>
	)
}

export class Notify extends Component<
	NotifyProps,
	NotifyProps,
	NotifyActionProps
> {
	constructor(props: NotifyProps) {
		super(props)

		this.state = {
			selectedItems: "",
			step: 0,
		}
	}

	componentDidMount() {
		this.props.socket?.on("notification-sent", (msg: string) => {
			this.setState({
				...this.state,
				notificationSent: true,
			})
		})

		if ((this.props.notify?.selectedItems ?? []).length > 0) {
			this.setState({
				selectedItems: this.props.notify?.selectedItems,
			})
		}
	}

	List = () => {
		return (
			<AutoSizer
				style={{
					width: "100%",
					height: "100%",
					minHeight: "500px",
				}}
			>
				{({ height, width }) => {
					return (
						<FixedSizeList
							width={width}
							itemSize={128}
							itemData={this.state.selectedItems}
							height={height}
							itemCount={this.props.feed?.items.length ?? 0}
						>
							{(props: any) => {
								return (
									<Row
										{...props}
										denseMode={this.props.user?.denseMode}
										feedItem={
											this.props.feed?.items[props.index]
										}
										onClick={this.ToggleOption}
									/>
								)
							}}
						</FixedSizeList>
					)
				}}
			</AutoSizer>
		)
	}

	OnChange = (event: any) => {
		this.setState({
			...this.state,
			[event.target.name]: event.target.value,
		})
	}

	ToggleOption = (feedItemId: any) => {
		feedItemId = feedItemId.toString()

		let selectedItems: any = null

		if (this.state.selectedItems.length !== 0) {
			selectedItems = this.state.selectedItems.split(",")
		} else if (this.state.selectedItems) {
			selectedItems = this.state.selectedItems
		} else {
			selectedItems = []
		}

		if (!selectedItems.includes(feedItemId)) {
			selectedItems.push(feedItemId)
		} else {
			selectedItems.splice(selectedItems.indexOf(feedItemId), 1)
		}

		this.setState({
			...this.state,
			selectedItems: selectedItems.join(","),
		})
	}

	Step0 = () => {
		return <FeedComponent hideLocalFeed={true} />
	}

	Step1 = () => {
		if (
			this.props.notify &&
			this.props.notify.sent !== undefined &&
			this.props.notify.sent
		) {
			this.props.ResetNotify?.()
		}

		return (
			<Grid item xs={12} container justify="space-evenly">
				<Grid item xs={12} container justify="space-evenly">
					<Paper style={{ padding: "1rem", width: "100%" }}>
						<Grid item xs={12} container justify="space-evenly">
							<Grid item xs={12} md={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">
										Is this a test run?
									</FormLabel>
									<RadioGroup
										row
										name="test"
										aria-label="Category"
										onChange={(_event) => {
											this.props.toggleTesting?.(
												_event?.target.value
											)
										}}
										value={this.props.notify?.test}
									>
										<FormControlLabel
											label="Yes With Image"
											value={"testWithImage"}
											control={
												<Radio name="testWithImage" />
											}
											checked={
												this.props.notify?.test ===
												"testWithImage"
											}
										/>
										<FormControlLabel
											label="Yes"
											value={true}
											control={<Radio name="test" />}
											checked={
												this.props.notify?.test ==
												"true"
											}
										/>
										<FormControlLabel
											label="No"
											value={false}
											control={<Radio name="test" />}
											checked={
												this.props.notify?.test ==
												"false"
											}
										/>
										<FormControlLabel
											label="Skip Notifications"
											value="skipNotifications"
											control={<Radio name="test" />}
											checked={
												this.props.notify?.test ===
												"skipNotifications"
											}
										/>
									</RadioGroup>
									<FormHelperText
										style={{
											maxWidth: "500px",
											textAlign: "center",
										}}
									>
										Choose "Yes" to send this notification
										to all Snibblers (TEST), or "No" to send
										notification to all registered users
										(LIVE).
									</FormHelperText>
								</FormControl>
							</Grid>

							{this.props.notify?.test !==
								"skipNotifications" && (
								<>
									<Grid item xs={12}>
										<Autocomplete
											multiple
											fullWidth
											limitTags={3}
											value={
												(this.props.notify
													?.topics as any) ?? []
											}
											options={[
												{
													label: "Snibble Recommends",
													value: "Snibble_Recommends",
												},
											]}
											getOptionLabel={(option) =>
												option.label || option
											}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Topics"
													helperText="In test mode '_Test' will automatically be appended
                                    to any of the selected topics, all Snibblers are
                                    automatically subscribed to these topics"
												/>
											)}
											onChange={(
												_event,
												value: Array<any>
											) =>
												this.props.notifyOnChange?.({
													target: {
														name: "topics",
														value: value.map(
															(val) =>
																val.val || val
														),
													},
												})
											}
											getOptionSelected={(
												option: any,
												value: any
											) =>
												option.value ===
												(value.value || value)
											}
										/>
									</Grid>

									<Grid item xs={12}>
										<TextField
											fullWidth
											name="title"
											value={this.props.notify?.title}
											onChange={this.props.notifyOnChange}
											label={
												<Typography variant="body1">
													Notification Title
												</Typography>
											}
										/>
									</Grid>

									<Grid item xs={12}>
										<TextField
											fullWidth
											multiline
											name="description"
											onChange={this.props.notifyOnChange}
											label={
												<Typography variant="body1">
													Notification Description
												</Typography>
											}
											value={
												this.props.notify?.description
											}
										/>
									</Grid>

									{this.props.notify?.test ===
										"testWithImage" && (
										<Grid item xs={12}>
											<TextField
												fullWidth
												multiline
												name="image"
												onChange={
													this.props.notifyOnChange
												}
												label={
													<Typography variant="body1">
														Notification Image
													</Typography>
												}
												value={this.props.notify?.image}
											/>
										</Grid>
									)}
								</>
							)}

							<Grid item xs={12}>
								<TextField
									fullWidth
									name="selectedItems"
									value={
										typeof this.props.notify
											?.selectedItems === "string"
											? this.props.notify?.selectedItems
											: this.props.notify?.selectedItems.join(
													","
											  )
									}
									onChange={this.props.notifyOnChange}
									label={
										<Typography variant="body1">
											Comma Separated Video Ids
										</Typography>
									}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid
					item
					xs={12}
					container
					justify="space-evenly"
					style={{ marginTop: "1rem" }}
				>
					{this.props.notify?.selectedItems &&
						(
							(((this.props.notify
								?.selectedItems as unknown) as string).split &&
								((this.props.notify
									?.selectedItems as unknown) as string).split(
									","
								)) ||
							this.props.notify?.selectedItems
						).map((selectedItem: any) => {
							const item = this.props.feed?.items.find(
								(item) => item.id === parseInt(selectedItem)
							)

							if (!item) return undefined

							return (
								<Grid
									key={item.id}
									item
									xs={12}
									md={4}
									container
									justify="space-evenly"
								>
									<FeedItem feedItem={item} />
								</Grid>
							)
						})}
				</Grid>
			</Grid>
		)
	}

	Step2 = () => {
		if (this.props.notify?.loading || this.props.notify?.sent) {
			return (
				<Grid container justify="space-around">
					<Grid item xs={12}>
						<div
							style={{
								width: "10rem",
								height: "10rem",
								position: "relative",
								display: "inline-block",
								transform: "translateX(-50%)",
							}}
						>
							{this.props.notify?.sent &&
								this.state.notificationSent && (
									<CheckCircleIcon
										color="primary"
										style={{
											top: "-2px",
											left: "49%",
											fontSize: "10.2rem",
											position: "absolute",
										}}
									/>
								)}
							<CircularProgress
								size="10rem"
								variant={
									this.props.notify?.sent &&
									this.state.notificationSent
										? "determinate"
										: "indeterminate"
								}
								value={100}
								style={{
									position: "absolute",
								}}
							/>
						</div>
					</Grid>
					{this.props.notify?.sent && this.state.notificationSent && (
						<Grid item xs={12}>
							<Typography variant="subtitle2">Sent!</Typography>
						</Grid>
					)}
				</Grid>
			)
		}

		return (
			<Tooltip
				title={
					<Typography variant="subtitle2">
						Send Notification
					</Typography>
				}
			>
				<span
					style={{
						top: "25%",
					}}
				>
					<Button
						color="primary"
						variant="contained"
						aria-label="Send Notification"
						disabled={this.props.notify?.loading}
						onClick={() => {
							this.props.sendNotification?.({
								...this.props.notify,
								selectedItems: this.state.selectedItems
									.split(",")
									.map((item: any) => {
										try {
											item = parseInt(item)

											if (isNaN(item)) {
												return undefined
											} else {
												return item
											}
										} catch (error) {
											console.error(error)
											return undefined
										}
									}),
							} as any)
						}}
					>
						<SendIcon
							fontSize="large"
							style={{ marginRight: "0.5rem" }}
						/>
						<Typography variant="h5">Send!</Typography>
					</Button>
				</span>
			</Tooltip>
		)
	}

	render() {
		return (
			<Grid item xs={12} container justify="center">
				<Grid item xs={10}>
					<Stepper
						id="stepper"
						style={{
							position: "static",
							backgroundColor: "transparent",
						}}
						alternativeLabel
						activeStep={this.state.step ?? 0}
						connector={<ColorlibConnector />}
					>
						{[
							"Select Videos",
							"Notification Settings",
							"Send Notification",
						].map((label, index) => (
							<Step key={label}>
								<StepLabel
									StepIconComponent={ColorlibStepIcon}
									onClick={() => {
										if (index < (this.state?.step ?? 0)) {
											this.setState({
												...this.state,
												step: index,
											})
										}
									}}
								>
									{label}
								</StepLabel>
							</Step>
						))}
					</Stepper>

					{(this as any)[`Step${this.state.step}`]()}

					<Tooltip
						title={
							<Typography variant="subtitle2">
								Previous Step
							</Typography>
						}
					>
						<span
							style={{
								position: "absolute",
								bottom: "1rem",
								left: "8.5rem",
							}}
						>
							<Fab
								disabled={this.state.step === 0}
								color="primary"
								aria-label="Previous Step"
								onClick={() => {
									if (
										this.state.step !== undefined &&
										this.state.step > 0
									) {
										if (this.props.notify?.sent) {
											this.props.ResetNotify?.()
										}

										this.setState({
											...this.state,
											step: this.state.step - 1,
										})
									} else {
										this.setState({
											...this.state,
											step: 0,
										})
									}
									document
										.getElementById("stepper")
										?.scrollIntoView()
								}}
							>
								<NavigateBeforeIcon />
							</Fab>
						</span>
					</Tooltip>
					<Tooltip
						title={
							<Typography variant="subtitle2">
								Next Step
							</Typography>
						}
					>
						<span
							style={{
								position: "absolute",
								bottom: "1rem",
								right: "1rem",
							}}
						>
							<Fab
								color="primary"
								aria-label="Next Step"
								disabled={
									this.state.step === 2 ||
									(this.state.step === 1 &&
										this.props.notify?.test !==
											"skipNotifications" &&
										(!this.props.notify?.title ||
											!this.props.notify?.description))
								}
								onClick={() => {
									if (
										this.state.step !== undefined &&
										this.state.step < 2
									) {
										this.setState({
											...this.state,
											step: this.state.step + 1,
											selectedItems:
												typeof this.props.notify
													?.selectedItems === "string"
													? this.props.notify
															?.selectedItems
													: this.props.notify?.selectedItems.join(
															","
													  ),
										})
									} else {
										this.setState({
											...this.state,
											step: 2,
										})
									}
									document
										.getElementById("stepper")
										?.scrollIntoView()
								}}
							>
								<NavigateNextIcon />
							</Fab>
						</span>
					</Tooltip>
				</Grid>
			</Grid>
		)
	}
}

function mapStateToProps(state: RootState, props: NotifyProps): NotifyProps {
	return {
		feed: state.feed,
		socket: state.socket,
		dialog: state.dialog,
		notify: state.notify,
		user: state.user,
	}
}

function mapDispatchToProps(dispatch: Dispatch): NotifyActionProps {
	return bindActionCreators(
		{
			setContent,
			toggleItem,
			ResetNotify,
			getFeedItems,
			toggleDialog,
			toggleTesting,
			notifyOnChange,
			sendNotification,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Notify)
