import axios from 'axios';
import Store, { RootState } from '../store';

function getOptions(options: any) {
    // Get the current state of the client
    let state: RootState = Store.getState();

    // Check if options already has headers,
    // if not then create a new object
    if (!options.headers) {
        options.headers = {};
    }

    // If we have a user anda session
    if (
        state.user &&
        Object.keys(state.user).length !== 0 &&
        Object.keys(state.user).length !== 0
    ) {
        // Set the user and token header for the server
        // options.headers['x-user-id'] = state.user.id;
        // options.headers['x-token'] = state.user.access_token;
    }

    options.headers['environment'] = state.environment ?? 'prod';

    if (options.withCredentials === undefined) {
        options.withCredentials = true;
    }

    // return the options
    return options;
}

function handleResponse(
    resolve: (value?: any) => void,
    reject: (reason?: any) => void,
    options: any,
    response: any
) {
    // Check if something on the server went wrong,
    // if something did go wrong then reject the promise
    // and return the message
    if (response.data.status === false) {
        if (response.data.login) {
            localStorage.setItem('redirect', window.location.pathname);
            window.location.href = '/v1/auth/google';
        }
        reject(response.data.message);
    }

    // If nothing went wrong,
    // check to see if the call wants the full response,
    // if so resolve the full response,
    if (options.full) {
        resolve(response);
    }
    // otherwise resolve only the data of the response
    else {
        try {
            resolve(JSON.parse(response.data));
        } catch (e) {
            resolve(response.data);
        }
    }
}

export const get = (url: string, options: any = {}): Promise<any> => {
    return new Promise(
        (resolve: (value?: any) => void, reject: (reason?: any) => void) => {
            options = getOptions(options);

            axios
                .get(url, options)
                .then((response: any) => {
                    handleResponse(resolve, reject, options, response);
                })
                .catch((error: any) => {
                    if (error.response && error.response.data) {
                        if (error.response.status === 401) {
                            reject(
                                'You do not have access to this resource. Please check and try again.'
                            );
                        }
                    }

                    reject('Something went wrong. Please try again.');
                });
        }
    );
};

export const post = (
    url: string,
    body: any,
    options: any = {}
): Promise<any> => {
    return new Promise((resolve, reject) => {
        options = getOptions(options);
        axios
            .post(url, body, options)
            .then((response: any) =>
                handleResponse(resolve, reject, options, response)
            )
            .catch((error: any) => {
                if (error.response && error.response.data) {
                    reject(error.response.data.message);
                } else {
                    reject('Something went wrong. Please try again.');
                }
            });
    });
};

export const put = (
    url: string,
    body: any,
    options: any = {}
): Promise<any> => {
    return new Promise((resolve, reject) => {
        options = getOptions(options);
        axios
            .put(url, body, options)
            .then((response: any) =>
                handleResponse(resolve, reject, options, response)
            )
            .catch((error: any) => {
                if (error.response && error.response.data) {
                    reject(error.response.data.message);
                } else {
                    reject('Something went wrong. Please try again.');
                }
            });
    });
};

export const del = (url: string, options: any = {}): Promise<any> => {
    return new Promise((resolve, reject) => {
        options = getOptions(options);
        axios
            .delete(url, options)
            .then((response: any) =>
                handleResponse(resolve, reject, options, response)
            )
            .catch((error: any) => {
                if (error.response && error.response.data) {
                    reject(error.response.data.message);
                } else {
                    reject('Something went wrong. Please try again.');
                }
            });
    });
};
