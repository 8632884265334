import "./app.scss"
import React from "react"
import { RootState } from "./store"
import Feed from "./components/feed"
import { connect } from "react-redux"
import Login from "./components/login"
import Notify from "./components/notify"
import Header from "./components/header"
import Grid from "@material-ui/core/Grid"
import { User } from "./store/user/reducer"
import SideNav from "./components/side-nav"
import Articles from "./components/articles"
import Jobs from "./components/reports/jobs"
import Items from "./components/reports/items"
import { createBrowserHistory } from "history"
import red from "@material-ui/core/colors/red"
import { loadUser } from "./store/user/actions"
import AdminItems from "./components/adminItems"
import { CssBaseline, ThemeProvider } from "@material-ui/core"
import green from "@material-ui/core/colors/green"
import Aggregation from "./components/aggregation"
import { bindActionCreators, Dispatch } from "redux"
import Categories from "./components/reports/categories"
import { Router, Switch, Route } from "react-router-dom"
import WatchCount from "./components/reports/watch-count"
import { createMuiTheme } from "@material-ui/core/styles"
import Notification from "./components/utils/notification"
import Notifications from "./components/reports/notifications"
import { createNotification } from "./store/notification/actions"

import Sources from "./components/sources"
import { toggleDialog } from "./store/dialog/actions"
import { Dialog as IDialog } from "./store/dialog/reducer"
import Providers from "./components/providers"
import { Dialog } from "./components/utils/dialog"
import CategoryList from "./components/category-list"
import ManageCategories from "./components/manage-categories"

const history = createBrowserHistory()

const theme = createMuiTheme({
	typography: {
		htmlFontSize: 10,
	},
	palette: {
		primary: {
			main: "#00BCE0",
			contrastText: "#fff",
		},
		secondary: {
			main: red[700],
			contrastText: "#000",
		},
		success: {
			main: green[700],
		},
	},
} as any)

interface AppProps {
	user?: User
	dialog?: IDialog
	toggleDialog?: typeof toggleDialog
	loadUser?: typeof loadUser
	createNotification?: typeof createNotification
}

interface AppActionProps {
	loadUser?: typeof loadUser
	toggleDialog?: typeof toggleDialog
	createNotification?: typeof createNotification
}

class App extends React.Component<AppProps, AppProps, AppActionProps> {
	componentDidMount() {
		this.props.loadUser?.()

		// const path = localStorage.getItem('redirect');
		// if (path && path !== '') {
		//     window.location.pathname = path;
		//     localStorage.removeItem('redirect');
		// }
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router history={history}>
					<div
						style={{
							height: "100%",
							display: "flex",
						}}
					>
						<Dialog
							fullWidth
							onClose={this.props.toggleDialog}
							open={this.props.dialog?.open}
							title={this.props.dialog?.title}
							content={this.props.dialog?.content}
						/>
						<SideNav />
						<div className="App">
							<Header />

							<Notification />

							<Grid
								id="App-body"
								className="App-body"
								container
								justify="center"
								style={{
									overflowX: "hidden",
									overflowY: "scroll",
								}}
							>
								<Switch>
									{this.props.user && (
										<>
											<Route
												exact
												path="/"
												component={Categories}
											/>

											<Route
												exact
												component={Categories}
												path="/reports/categories"
											/>

											<Route
												exact
												component={Articles}
												path="/articles/:tab?/:id?"
											/>

											<Route
												exact
												component={Providers}
												path="/providers/:tab?/:id?"
											/>

											<Route
												exact
												component={Jobs}
												path="/reports/jobs"
											/>

											<Route
												exact
												component={Items}
												path="/reports/items"
											/>

											<Route
												exact
												component={Notifications}
												path="/reports/notifications"
											/>

											<Route
												exact
												path="/reports/watch-count"
												component={WatchCount}
											/>

											<Route
												exact
												path="/aggregation"
												component={Aggregation}
											/>

											<Route
												exact
												path="/custom-sources"
												component={Sources}
											/>

											<Route
												exact
												path="/category-list"
												component={CategoryList}
											/>

											<Route
												exact
												path="/manage-categories"
												component={ManageCategories}
											/>

											<Route
												exact
												path="/permissions"
												component={AdminItems}
											/>

											<Route
												exact
												path="/feed"
												component={Feed}
											/>

											<Route
												exact
												path="/notify"
												component={Notify}
											/>
										</>
									)}

									<Route path="*" component={Login} />
								</Switch>
							</Grid>
						</div>
					</div>
				</Router>
			</ThemeProvider>
		)
	}
}

function mapStateToProps(state: RootState): AppProps {
	return {
		dialog: state.dialog,
		user: state.user,
	}
}

function mapDispatchToProps(dispatch: Dispatch): AppActionProps {
	return bindActionCreators(
		{ loadUser, createNotification, toggleDialog },
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
