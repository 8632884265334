import { Action } from '..';
import { types as AGGREGATION_TYPES } from './actions';

export interface AggregationItems {
    loading: boolean;
    providers: Array<any> | null;
    providerUrls: Array<any> | null;
}

const initalState: AggregationItems = {
    loading: false,
    providers: null,
    providerUrls: null,
};

interface IAction extends Action {
    type: any;
    payload: any;
}

export default function reducer(state = initalState, action: IAction) {
    switch (action.type) {
        case AGGREGATION_TYPES.TOGGLE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case AGGREGATION_TYPES.GET_RPOVIDERS:
            return {
                ...state,
                providers: action.payload,
            };
        case AGGREGATION_TYPES.GET_RPOVIDER_URLS:
            return {
                ...state,
                providerUrls: action.payload,
            };
        default:
            return state;
    }
}
