import { Dispatch } from "redux"

export enum types {
	TOGGLE_DIALOG = "TOGGLE_DIALOG",
	SET_CONTENT = "SET_CONTENT",
}

export const toggleDialog = (
	state: boolean | null = null,
	cb: any = undefined
) => (dispatch: Dispatch) => {
	dispatch({
		type: types.TOGGLE_DIALOG,
		payload: state,
		cb,
	})
}

export const setContent = (content: any) => (dispatch: Dispatch) => {
	dispatch({
		type: types.SET_CONTENT,
		payload: content,
	})
}
