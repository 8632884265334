import { Line } from "react-chartjs-2"
import React, { useEffect } from "react"
import { RootState } from "../../../store"
import { useDispatch, useSelector } from "react-redux"
import { Backdrop, CircularProgress, Grid } from "@material-ui/core"
import { getNotificationCountReport } from "../../../store/report/actions"
import { Filter } from "./filter"

interface NotificationsProps {
	notificationCount?: any
	getNotificationCountReport?: typeof getNotificationCountReport
}

interface NotificationsActionProps {
	notificationCount?: any
	getNotificationCountReport?: typeof getNotificationCountReport
}

export default function Notifications() {
	const dispatch = useDispatch()
	const { isLoading, notificationCount } = useSelector((state: RootState) => {
		return {
			isLoading: state.reports?.loading,
			notificationCount: state.reports?.notificationCount,
		}
	})

	useEffect(() => {
		if (
			!isLoading &&
			(!notificationCount.labels ||
				notificationCount.labels?.length === 0) &&
			(!notificationCount.dataset ||
				notificationCount.dataset?.length === 0)
		) {
			LoadData()
		}
	}, [isLoading, dispatch])

	function LoadData(filters: any = null) {
		dispatch(getNotificationCountReport(filters))
	}

	return (
		<Grid container>
			{isLoading && (
				<Backdrop open={isLoading} style={{ zIndex: 12001 }}>
					<CircularProgress color="primary" size="5rem" />
				</Backdrop>
			)}
			<Grid item xs={12}>
				<Filter OnFilter={LoadData} />
				<Line data={notificationCount} />
			</Grid>
		</Grid>
	)
}
