import { Action } from '..';
import { types as NOTICATION_TYPES } from './actions';
import { SnackbarOrigin } from '@material-ui/core/Snackbar/Snackbar';

export interface Notification {
    icon: string;
    message: { message?: string } | string;
    open: boolean;
    copyData?: any | null | undefined;
    anchor: SnackbarOrigin;
    autoHide: number | null;
    severity?: 'success' | 'info' | 'warning' | 'error' | undefined;
}

const initalState: Array<Notification> = [];

interface IAction extends Action {
    type: any;
    payload: any;
}

export default function reducer(state = initalState, action: IAction) {
    switch (action.type) {
        case NOTICATION_TYPES.DELETE_NOTIFICATION:
            state.splice(0, 1);

            return [...state];
        case NOTICATION_TYPES.ADD_NOTIFICATION:
            return [...state, action.payload];
        case NOTICATION_TYPES.GET_NOTIFICATION:
        case NOTICATION_TYPES.UPDATE_NOTIFICATION:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}
