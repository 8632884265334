import clsx from 'clsx';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import MDrawer from '@material-ui/core/Drawer';
import { bindActionCreators, Dispatch } from 'redux';
import { Drawer as IDrawer } from '../../../store/drawer/reducer';
import { addDrawer, toggleDrawer } from '../../../store/drawer/actions';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

const drawerWidth = 320;

const useStyles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    });

interface DrawerProps {
    id?: string;
    style?: any;
    classes?: any;
    open?: boolean;
    addDrawer?: typeof addDrawer;
    toggleDrawer?: typeof toggleDrawer;
    children?: JSX.Element | Array<JSX.Element>;
    anchor?: 'bottom' | 'left' | 'right' | 'top' | undefined;
    variant?: 'permanent' | 'persistent' | 'temporary' | undefined;
}

interface DrawerActionProps {
    addDrawer: typeof addDrawer;
    toggleDrawer: typeof toggleDrawer;
}

export class Drawer extends Component<
    DrawerProps,
    DrawerProps,
    DrawerActionProps
> {
    constructor(props: DrawerProps) {
        super(props);

        this.state = {
            id: props.id ?? new Date().getTime().toString(),
        };
    }

    componentDidMount() {
        this.props.addDrawer?.({
            id: this.props.id ?? this.state.id,
            open: this.props.open !== undefined ? this.props.open : false,
        });
    }

    render() {
        return (
            <MDrawer
                open={this.props.open ?? false}
                anchor={this.props.anchor ?? 'left'}
                variant={this.props.variant ?? 'permanent'}
                onClose={() => this.props.toggleDrawer?.(this.state.id)}
                className={clsx(this.props.classes.drawer, {
                    [this.props.classes.drawerOpen]: this.props.open,
                    [this.props.classes.drawerClose]: !this.props.open,
                })}
                classes={{
                    paper: clsx({
                        [this.props.classes.drawerOpen]: this.props.open,
                        [this.props.classes.drawerClose]: !this.props.open,
                    }),
                }}
                PaperProps={{
                    style: this.props.style
                }}
            >
                {this.props.children}
            </MDrawer>
        );
    }
}

function mapStateToProps(state: any, props: DrawerProps): any {
    return {
        ...state.drawers.find((drawer: IDrawer) => {
            return drawer.id === props.id;
        }),
    };
}

function mapDispatchToProps(dispatch: Dispatch): DrawerActionProps {
    return bindActionCreators({ addDrawer, toggleDrawer }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(Drawer));
