import { Action } from '..';
import { types as DRAWER_TYPES } from './actions';

export interface Drawer {
    id: string | undefined;
    open: boolean;
}

const initalState: Array<Drawer> = JSON.parse(
    localStorage.getItem('drawers') ?? JSON.stringify([])
);

interface IAction extends Action {
    type: any;
    payload: any;
}

export default function reducer(state = initalState, action: IAction) {
    let newState: any = [];
    let found: any = false;
    switch (action.type) {
        case DRAWER_TYPES.ADD_DRAWER:
            newState = state.map((drawer) => {
                if (drawer.id === action.payload.id) {
                    found = true;
                }

                return drawer;
            });

            if (!found) {
                newState.push(action.payload);
            }

            localStorage.setItem('drawers', JSON.stringify(newState));
            return newState;
        case DRAWER_TYPES.TOGGLE_DRAWER:
            newState = state.map((drawer) => {
                if (drawer.id !== action.payload) {
                    return drawer;
                }

                found = true;

                return {
                    ...drawer,
                    open: !drawer.open,
                };
            });

            if (!found) {
                newState.push({
                    id: action.payload,
                    open: true,
                });
            }

            localStorage.setItem('drawers', JSON.stringify(newState));
            return newState;
        default:
            return state;
    }
}
