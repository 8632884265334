import { ThunkDispatch } from 'redux-thunk';
import { Action, RootState } from '..';

export enum types {
    SET_LOG = 'SET_LOG',
    ADD_LOG_MESSAGE = 'ADD_LOG_MESSAGE',
}

export interface Log {
    name: string;
    logs: Array<string>;
}

export type Logs = Array<Log>;

const initialState: Logs = [];

export const setLog = (log: Log) => async (
    dispatch: ThunkDispatch<RootState, Logs, any>
) => {
    dispatch({
        type: types.SET_LOG,
        payload: log,
    });
};

export const addLogMessage = (message: any) => async (
    dispatch: ThunkDispatch<RootState, Logs, any>
) => {
    dispatch({
        type: types.ADD_LOG_MESSAGE,
        payload: message,
    });
};

export default function reducer(state = initialState, action: Action) {
    switch (action.type) {
        case types.SET_LOG:
            return action.payload;
        case types.ADD_LOG_MESSAGE:
            let found = false;
            let newState = state.map((log: Log) => {
                if (log.name === action.payload.name) {
                    found = true;
                    return {
                        name: log.name,
                        logs: [...log.logs, action.payload.message],
                    };
                } else {
                    return log;
                }
            });

            if (!found) {
                newState.push({
                    name: action.payload.name,
                    logs: [action.payload.message],
                });
            }

            return newState;
        default:
            return state;
    }
}
