import './style.scss';
import moment from 'moment';
import Filter from './filter';
import Fab from '@material-ui/core/Fab';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { bindActionCreators, Dispatch } from 'redux';
import { DateColor } from '../../../store/report/reducer';
import { changeColor } from '../../../store/report/actions';
import { toggleDrawer } from '../../../store/drawer/actions';
import { getCategoryReport } from '../../../store/report/actions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { User } from '../../../store/user/reducer';

interface CategoryProps {
    user?: User;
    report?: any;
    loading?: boolean;
    selectedColor?: number;
    colors?: Array<DateColor>;
    changeColor?: typeof changeColor;
    sort?: { key: string | undefined; direction: 'asc' | 'desc' | undefined };
    toggleDrawer?: typeof toggleDrawer;
    getCategoryReport?: typeof getCategoryReport;
}

interface CategoryActionProps {
    changeColor?: typeof changeColor;
    toggleDrawer?: typeof toggleDrawer;
    getCategoryReport?: typeof getCategoryReport;
}

export class Category extends Component<
    CategoryProps,
    CategoryProps,
    CategoryActionProps
> {
    constructor(props: CategoryProps) {
        super(props);

        this.state = {
            sort: undefined,
            selectedColor: -1,
        };
    }

    componentDidMount() {
        this.LoadData();
    }

    LoadData = (filters: any = null) => {
        this.props.getCategoryReport?.(filters);
    };

    GetCategoryReport = () => {
        if (this.props.loading) {
            return null;
        }

        if (!this.props.report || this.props.report.length === 0) {
            return (
                <div
                    style={{
                        position: 'sticky',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Typography
                        variant="h4"
                        style={{
                            top: '50%',
                            position: 'relative',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        No results found for selected filters.
                    </Typography>
                </div>
            );
        }

        let columns = Object.keys(this.props.report[0]);

        let rows = Object.assign([], this.props.report);

        if (this.state.sort?.direction) {
            rows = rows.sort((a: any, b: any) => {
                let A = a[this.state.sort?.key ?? ''];
                let B = b[this.state.sort?.key ?? ''];

                A = new Date(A);

                if (A.toString() !== 'Invalid Date') {
                    A = A.getTime();
                    B = new Date(B).getTime();
                } else {
                    A = a[this.state.sort?.key ?? ''];
                }

                if (typeof A === 'number') {
                    if (this.state.sort?.direction === 'desc') {
                        return A - B;
                    } else if (this.state.sort?.key) {
                        return B - A;
                    }
                } else {
                    if (this.state.sort?.direction === 'desc') {
                        return A.localeCompare(B);
                    } else if (this.state.sort?.key) {
                        return B.localeCompare(A);
                    }
                }

                return 0;
            });
        }

        rows = rows.map((item: any) => {
            return (
                <TableRow key={Math.random() * new Date().getTime() + 'top'}>
                    {columns.map((key: string) => {
                        return (
                            <TableCell
                                key={
                                    Math.random() * new Date().getTime() + 'sub'
                                }
                                style={{
                                    backgroundColor:
                                        (key === 'Feed Item Published At' &&
                                            this.GetCellColour(item, key)) ||
                                        'inital',
                                }}
                            >
                                {(key === 'Feed Item Published At' && (
                                    <Tooltip
                                        title={
                                            <Typography variant="subtitle2">
                                                {item[key]}
                                            </Typography>
                                        }
                                    >
                                        <span>
                                            {moment.utc(item[key]).fromNow()}
                                        </span>
                                    </Tooltip>
                                )) ||
                                    item[key]}
                            </TableCell>
                        );
                    })}
                </TableRow>
            );
        });

        return (
            <TableContainer
                style={{
                    backgroundColor: 'white',
                    margin: '0.5rem',
                    padding: '0.25rem',
                }}
            >
                <Table size={this.props.user?.denseMode ? 'small' : undefined}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                let props: any = {
                                    key:
                                        Math.random() * new Date().getTime() +
                                        'sub',
                                    style: {
                                        width: index !== 0 ? '500px' : '125px',
                                    },
                                };

                                return (
                                    <TableCell {...props}>
                                        <TableSortLabel
                                            active={
                                                this.state.sort?.key === column
                                            }
                                            direction={
                                                this.state.sort?.direction
                                            }
                                            onClick={() => {
                                                let direction:
                                                    | 'asc'
                                                    | 'desc'
                                                    | undefined = this.state
                                                    .sort?.direction;

                                                if (
                                                    !this.state.sort?.direction
                                                ) {
                                                    direction = 'desc';
                                                } else if (
                                                    this.state.sort
                                                        ?.direction === 'desc'
                                                ) {
                                                    direction = 'asc';
                                                } else {
                                                    direction = undefined;
                                                }

                                                this.setState({
                                                    ...this.state,
                                                    sort: {
                                                        direction,
                                                        key:
                                                            (direction &&
                                                                column) ??
                                                            undefined,
                                                    },
                                                });
                                            }}
                                        >
                                            {column}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    GetCellColour = (item: any, key: string): string => {
        if (
            key !== 'Feed Item Published At' ||
            !item[key] ||
            !this.props.colors
        ) {
            return 'inital';
        }

        for (let index = 0; index < this.props.colors?.length; index++) {
            if (
                moment(item[key]).isBefore(
                    moment().subtract(this.props.colors[index].daysAgo, 'days')
                )
            ) {
                return this.props.colors[index].color;
            }
        }

        return 'inital';
    };

    render() {
        return (
            <Grid container alignContent="flex-start">
                <Filter OnFilter={this.LoadData} />

                <Backdrop
                    open={
                        this.props.loading !== undefined
                            ? this.props.loading
                            : true
                    }
                    style={{ zIndex: 12001 }}
                >
                    <CircularProgress color="primary" size="5rem" />
                </Backdrop>

                <Grid
                    container
                    justify="space-around"
                    style={{
                        marginTop: '0.5rem',
                    }}
                >
                    {this.props.colors?.map((color: DateColor) => {
                        return (
                            <Grid item>
                                <div className="swatch">
                                    <div
                                        className="color"
                                        style={{ backgroundColor: color.color }}
                                    ></div>
                                </div>
                                <Typography className="swatch-label">
                                    {color.daysAgo}{' '}
                                    {color.daysAgo !== 1 ? 'Days' : 'Day'} ago
                                    or more
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>

                {this.GetCategoryReport()}

                <Tooltip
                    title={
                        <Typography variant="subtitle2">
                            Toggle filter
                        </Typography>
                    }
                >
                    <Fab
                        style={{
                            position: 'absolute',
                            bottom: '1rem',
                            right: '1rem',
                        }}
                        color="primary"
                        aria-label="Toggle filter"
                        onClick={() => {
                            this.props.toggleDrawer?.('category-filter');
                        }}
                    >
                        <FilterListIcon />
                    </Fab>
                </Tooltip>
            </Grid>
        );
    }
}

function mapStateToProps(state: RootState): CategoryProps {
    return {
        user: state.user,
        colors: state.reports?.colors,
        loading: state.reports?.loading,
        report: state.reports?.categories,
    };
}

function mapDispatchToProps(dispatch: Dispatch): CategoryActionProps {
    return bindActionCreators(
        { getCategoryReport, toggleDrawer, changeColor },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);
