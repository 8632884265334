import Fab from '@material-ui/core/Fab';
import Dialog from '../../utils/dialog';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import AddIcon from '@material-ui/icons/Add';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Role } from '../../../store/permissions/reducer';
import { addRole, toggleEditing } from '../../../store/permissions/actions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

interface RolesProps {
    newRole?: any;
    permissions?: any;
    addRole?: typeof addRole;
    toggleEditing?: typeof toggleEditing;
}

interface RolesActionProps {
    addRole?: typeof addRole;
    toggleEditing?: typeof toggleEditing;
}

const defaultRole = {
    id: null,
    name: null,
    description: null,
    role: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
    deleted_by: null,
};

export class Roles extends Component<RolesProps, RolesProps, RolesActionProps> {
    constructor(props: RolesProps) {
        super(props);

        this.state = {
            newRole: defaultRole,
        };
    }

    GetEditButtons = (role: Role) => {
        return (
            <>
                <IconButton
                    onClick={() => {
                        // this.props.saveRole?.(role);
                    }}
                >
                    <SaveIcon />
                </IconButton>
                <IconButton>
                    <DeleteIcon />
                </IconButton>
            </>
        );
    };

    GetNewRoleForm = () => {
        return (
            <Grid container justify="space-evenly" spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        onChange={this.OnChange}
                        value={this.state.newRole?.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        fullWidth
                        name="description"
                        label="Description"
                        onChange={this.OnChange}
                        value={this.state.newRole?.description}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            fullWidth
                            id="role"
                            name="role"
                            labelId="role-label"
                            onChange={this.OnChange}
                            value={this.state.newRole.role}
                        >
                            <MenuItem value="read">Read</MenuItem>
                            <MenuItem value="write">Write</MenuItem>
                            <MenuItem value="update">Update</MenuItem>
                            <MenuItem value="delete">Delete</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    };

    GetRoles = () => {
        let headers = [
            { key: 'id', label: 'ID' },
            { key: 'name', label: 'Name' },
            { key: 'description', label: 'Description' },
            { key: 'role', label: 'Role' },
            {
                key: 'created_at',
                label: 'Date Created',
                pipe: (value: any) => new Date(value).toLocaleString(),
            },
        ];
        
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((header: any) => {
                            return (
                                <TableCell key={header.label ?? header}>
                                    {header.label ?? header}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.permissions?.roles?.map((role: Role) => {
                        return (
                            <TableRow key={role.id}>
                                {headers.map((header: any) => {
                                    return (
                                        <TableCell
                                            key={
                                                role.id + (header.key ?? header)
                                            }
                                        >
                                            {(header.pipe &&
                                                header.pipe(
                                                    role[header.key ?? header]
                                                )) ??
                                                role[header.key ?? header]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    };

    OnCancel = () => {
        this.setState(
            {
                newRole: defaultRole,
            },
            this.props.toggleEditing
        );
    };

    OnChange = (event: any) => {
        this.setState({
            ...this.state,
            newRole: {
                ...this.state.newRole,
                [event.target.name]: event.target.value,
            },
        });
    };

    OnSave = () => {
        this.props.addRole?.(this.state.newRole);
        this.OnCancel();
    };

    render() {
        return (
            <>
                <Dialog
                    fullWidth
                    open={this.props.permissions?.editing}
                    title="New Role"
                    content={this.GetNewRoleForm()}
                    actions={
                        <>
                            <Button
                                size="small"
                                color="default"
                                variant="contained"
                                onClick={this.OnCancel}
                                startIcon={<CancelIcon />}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={this.OnSave}
                                startIcon={<SaveIcon />}
                            >
                                Save
                            </Button>
                        </>
                    }
                />
                {this.GetRoles()}
                <Tooltip
                    title={
                        <Typography variant="subtitle2">
                            Add new role
                        </Typography>
                    }
                >
                    <Fab
                        style={{
                            position: 'absolute',
                            bottom: '1rem',
                            right: '1rem',
                        }}
                        color="primary"
                        aria-label="Add new role"
                        onClick={() => {
                            this.props.toggleEditing?.();
                        }}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </>
        );
    }
}

function mapStateToProps(state: any): RolesProps {
    return { permissions: state.permissions };
}

function mapDispatchToProps(dispatch: Dispatch): RolesActionProps {
    return bindActionCreators(
        {
            addRole,
            toggleEditing,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
