import moment from "moment"
import Fab from "@material-ui/core/Fab"
import Grid from "@material-ui/core/Grid"
import Backdrop from "@material-ui/core/Backdrop"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import CircularProgress from "@material-ui/core/CircularProgress"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"

import FeedItem from "./feedItem"
import Dialog from "../utils/dialog"
import { connect } from "react-redux"
import { get } from "../../plugins/api"
import { RootState } from "../../store"
import React, { Component } from "react"
import Filter from "../reports/items/filter"
import { Notify } from "../../store/notify/reducer"
import { bindActionCreators, Dispatch } from "redux"
import { toggleItem } from "../../store/notify/actions"
import { Dialog as IDialog } from "../../store/dialog/reducer"
import { Feed as IFeed, Item } from "../../store/feed/reducer"
import { setContent, toggleDialog } from "../../store/dialog/actions"
import { createNotification } from "../../store/notification/actions"
import { getFeedItems, toggleVisibility } from "../../store/feed/actions"
import { addLogMessage } from "../../store/logs"

import ReactHlsPlayer from "react-hls-player"

interface FeedProps {
	style?: any
	feed?: IFeed
	page?: number
	filters?: any
	notify?: Notify
	perPage?: number
	dialog?: IDialog
	markers?: Array<any>
	deletedItemId?: number
	hideLocalFeed?: boolean
	socket?: SocketIOClient.Socket
	watchCountList?: Array<number>
	toggleItem?: typeof toggleItem
	setContent?: typeof setContent
	toggleDialog?: typeof toggleDialog
	getFeedItems?: typeof getFeedItems
	addLogMessage?: typeof addLogMessage
	toggleVisibility?: typeof toggleVisibility
	createNotification?: typeof createNotification
}

interface FeedActionProps {
	toggleItem?: typeof toggleItem
	setContent?: typeof setContent
	toggleDialog?: typeof toggleDialog
	getFeedItems?: typeof getFeedItems
	addLogMessage?: typeof addLogMessage
	toggleVisibility?: typeof toggleVisibility
	createNotification?: typeof createNotification
}

export class Feed extends Component<any, any, FeedActionProps> {
	constructor(props: FeedProps) {
		super(props)

		this.state = {
			page: 1,
			perPage: 50,
			watchCountList: [],
			hideLocalFeed: props.hideLocalFeed,
		}
	}

	componentDidMount() {
		if (
			!this.props.feed?.loadedAt ||
			this.props.feed?.loadedAt <
				moment(this.props.feed?.loadedAt).subtract(5, "minutes")
		) {
			this.props.getFeedItems?.(this.state)
		}

		get("/v1/feed/aggregation_channel_markers?reduce=true").then(
			(markers: Array<any>) => {
				sessionStorage.setItem("markers", JSON.stringify(markers))
				this.setState({
					...this.state,
					markers: markers,
				})
			}
		)
	}

	GetFeed = () => {
		return this.props.feed?.items?.map((feedItem: Item, index: number) => {
			return (
				<GridListTile key={feedItem.id}>
					<FeedItem
						key={feedItem.id}
						feedItem={feedItem}
						OnDelete={this.OnDelete}
						markers={this.state.markers}
					/>
				</GridListTile>
			)
		})
	}

	GetPageButtons = () => {
		if (!this.props.feed || !this.props.feed?.pageCount) {
			return null
		}

		let buttons = []

		buttons.push(
			<Fab
				key="KeyboardArrowLeftIcon"
				size="small"
				color="primary"
				aria-label="add"
				style={{ margin: "0.25rem" }}
				disabled={
					this.props.feed.loading || (this.state.page ?? 0) - 1 <= 0
				}
				onClick={() => this.OnSetPage((this.state.page ?? 0) - 1)}
			>
				<KeyboardArrowLeftIcon />
			</Fab>
		)

		if (this.props.feed?.pageCount <= 10) {
			for (let i = 0; i < this.props.feed?.pageCount; i++) {
				buttons.push(
					<Fab
						key={i + 1}
						size="small"
						color="primary"
						aria-label="add"
						style={{
							color:
								!this.props.feed?.loading &&
								i === this.state.page
									? "white"
									: undefined,
							margin: "0.25rem",
							backgroundColor:
								!this.props.feed?.loading &&
								i === this.state.page
									? "rgba(100, 100, 100, .9)"
									: undefined,
						}}
						disabled={this.props.feed.loading}
						onClick={() => this.OnSetPage(i)}
					>
						{i + 1}
					</Fab>
				)
			}
		} else {
			if (this.state.page && this.state.page > 1) {
				for (
					let i = (this.state.page > 4 && this.state.page - 4) || 1;
					i < this.state.page;
					i++
				) {
					buttons.push(
						<Fab
							key={i}
							style={{ margin: "0.25rem" }}
							size="small"
							color="primary"
							aria-label="add"
							disabled={this.props.feed.loading}
							onClick={() => this.OnSetPage(i)}
						>
							{i}
						</Fab>
					)
				}
			}

			buttons.push(
				<Fab
					key={this.state.page ?? 1}
					size="small"
					aria-label="add"
					color="secondary"
					style={{
						color: !this.props.feed?.loading ? "white" : undefined,
						margin: "0.25rem",
						backgroundColor: !this.props.feed?.loading
							? "rgba(100, 100, 100, .9)"
							: undefined,
					}}
					disabled={true}
					onClick={() => this.OnSetPage(this.state.page ?? 0)}
				>
					{this.state.page ?? 1}
				</Fab>
			)

			for (let i = 1; i < 4; i++) {
				buttons.push(
					<Fab
						key={(this.state.page ?? 1) + i}
						style={{ margin: "0.25rem" }}
						size="small"
						color="primary"
						aria-label="add"
						disabled={this.props.feed.loading}
						onClick={() =>
							this.OnSetPage((this.state.page ?? 0) + i)
						}
					>
						{(this.state.page ?? 1) + i}
					</Fab>
				)
			}
		}

		buttons.push(
			<Fab
				key="KeyboardArrowRightIcon"
				size="small"
				color="primary"
				aria-label="add"
				style={{ margin: "0.25rem" }}
				onClick={() => this.OnSetPage((this.state.page ?? 0) + 1)}
				disabled={
					this.props.feed.loading ||
					this.state.page === this.props.feed.pageCount
				}
			>
				<KeyboardArrowRightIcon />
			</Fab>
		)

		return buttons
	}

	OnDelete = (deletedItemId: number) => {
		this.props.socket?.on(
			`delete-item-${deletedItemId}`,
			(message: { message: string; severity: string }) => {
				this.props.addLogMessage?.({
					name: `delete-item-${deletedItemId}`,
					message: message,
				})

				if (["error", "success"].includes(message.severity)) {
					this.props.createNotification?.({
						...message,
						autoHide: message.severity !== "error" ? 1000 : null,
					})
				}
			}
		)
	}

	OnFilter = (filters: any) => {
		if (filters && filters.onlyNotifyItems) {
			filters.onlyNotifyItems =
				typeof this.props.notify?.selectedItems === "string"
					? (this.props.notify?.selectedItems as string).split(",")
					: this.props.notify?.selectedItems
		}

		if (
			filters &&
			filters.onlyWatchListItems &&
			sessionStorage.getItem("watchCountList")
		) {
			filters.onlyWatchListItems = JSON.parse(
				sessionStorage.getItem("watchCountList") ?? "[]"
			)
		}

		this.setState(
			{
				...this.state,
				filters,
			},
			() =>
				this.props.getFeedItems?.({
					...this.state,
					...this.state.filters,
				})
		)
	}

	OnSetPage = (page: number) => {
		this.setState(
			{
				...this.state,
				page,
			},
			() => {
				this.props.getFeedItems?.({
					...this.state,
					...this.state.filters,
				})
				setTimeout(() => {
					if (document.getElementById("scrollTo")) {
						;(document.getElementById(
							"scrollTo"
						) as any).scrollIntoView({
							behavior: "smooth",
						})
					}
				}, 2000)
			}
		)
	}

	render() {
		return (
			<Grid
				container
				justify="space-evenly"
				alignContent="flex-start"
				style={this.props.style}
			>
				<Filter
					hideLocalFeed={this.props.hideLocalFeed}
					OnFilter={(filters: any) =>
						this.setState({ ...this.state, page: 0 }, () =>
							this.OnFilter(filters)
						)
					}
					style={{
						top: 0,
						zIndex: 2,
						marginTop: "-4px",
						position: "sticky",
					}}
				/>

				<Backdrop
					open={
						this.props.feed?.loading !== undefined
							? this.props.feed?.loading
							: true
					}
					style={{ zIndex: 12001 }}
				>
					<CircularProgress color="primary" size="5rem" />
				</Backdrop>

				<div id="scrollTo" style={{ width: 0, height: 0 }} />
				<GridList
					cols={3}
					id="feedItemList"
					cellHeight="auto"
					style={{
						overflow: "hidden",
						justifyContent: "space-evenly",
						padding: "2.5rem 1.25rem",
					}}
				>
					{this.GetFeed()}
				</GridList>

				<Grid
					item
					xs={12}
					style={{
						bottom: 0,
						zIndex: 2,
						position: "sticky",
					}}
				>
					{this.GetPageButtons()}
				</Grid>
			</Grid>
		)
	}
}

function mapStateToProps(state: RootState): FeedProps {
	return {
		feed: state.feed,
		dialog: state.dialog,
		notify: state.notify,
		socket: state.socket,
	}
}

function mapDispatchToProps(dispatch: Dispatch): FeedActionProps {
	return bindActionCreators(
		{
			setContent,
			toggleItem,
			toggleDialog,
			getFeedItems,
			addLogMessage,
			toggleVisibility,
			createNotification,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Feed)
