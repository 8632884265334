import { Action } from '..';
import { types as USER_TYPES } from './actions';

export interface User {
    denseMode: boolean;
}

const initialState: User | undefined = JSON.parse(
    localStorage.getItem('user') ??
        JSON.stringify({
            denseMode: false,
        })
);

export default function reducer(state = initialState, action: Action) {
    let newState = state;

    switch (action.type) {
        case USER_TYPES.TOGGLE_DENSE_MODE:
            newState = {
                ...newState,
                denseMode: !newState?.denseMode,
            };

            break;
        default:
            return state;
    }

    localStorage.setItem('user', JSON.stringify(newState));
    return newState;
}
