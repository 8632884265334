import { Action } from '..';

const Socket = require('socket.io-client');

export interface DateColor {
    color: string;
    daysAgo: number;
}

const initalState: SocketIOClient.Socket = Socket({
    transports: ['websocket'],
    rejectUnauthorized: false,
});

interface IAction extends Action {
    type: any;
    payload: any;
}

export default function reducer(state = initalState, action: IAction) {
    switch (action.type) {
        default:
            return state;
    }
}
