import Filter from './filter';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Backdrop from '@material-ui/core/Backdrop';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';

import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { User } from '../../../store/user/reducer';
import { bindActionCreators, Dispatch } from 'redux';
import { getJobReport } from '../../../store/report/actions';
import CircularProgress from '@material-ui/core/CircularProgress';

interface JobProps {
    user?: User;
    report?: any;
    loading?: boolean;
    statusLabel?: boolean;
    selectedColor?: number;
    sort?: 'asc' | 'desc' | undefined;
    getJobReport?: typeof getJobReport;
}

interface JobActionProps {
    getJobReport?: typeof getJobReport;
}

export class Job extends Component<JobProps, JobProps, JobActionProps> {
    constructor(props: JobProps) {
        super(props);

        this.state = {
            sort: undefined,
            selectedColor: -1,
            statusLabel: true,
        };
    }

    componentDidMount() {
        this.LoadData();
    }

    LoadData = (filters: any = null) => {
        if (filters && filters.jobStatuses.length === 1) {
            this.setState({
                ...this.state,
                statusLabel: false,
            });
        } else if (!this.state.statusLabel) {
            this.setState({
                ...this.state,
                statusLabel: true,
            });
        }

        this.props.getJobReport?.(filters);
    };

    GetJobReport = () => {
        if (this.props.loading) {
            return null;
        }

        if (!this.props.report || this.props.report.length === 0) {
            return (
                <div
                    style={{
                        position: 'sticky',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Typography
                        variant="h4"
                        style={{
                            top: '50%',
                            position: 'relative',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        No results found for selected filters.
                    </Typography>
                </div>
            );
        }

        let columns: Array<string> = [];

        if (!this.state.statusLabel) {
            columns = ['id', 'title', 'ProviderId', 'token', 'published_at'];
        } else {
            columns = [
                'id',
                'JobStatus',
                'title',
                'ProviderId',
                'token',
                'published_at',
            ];
        }

        let rows = Object.assign([], this.props.report);

        rows = rows.map((item: any) => {
            return (
                <TableRow key={Math.random() * new Date().getTime() + 'top'}>
                    {columns.map((key: string) => {
                        if (key !== 'title') {
                            return (
                                <TableCell
                                    key={
                                        Math.random() * new Date().getTime() +
                                        'sub'
                                    }
                                >
                                    {item[key]}
                                </TableCell>
                            );
                        } else {
                            return (
                                <TableCell
                                    key={
                                        Math.random() * new Date().getTime() +
                                        'sub'
                                    }
                                >
                                    <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            color: 'black',
                                            cursor: 'pointer',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        {item[key]}
                                    </a>
                                </TableCell>
                            );
                        }
                    })}
                </TableRow>
            );
        });

        return (
            <TableContainer
                style={{
                    backgroundColor: 'white',
                    margin: '0.5rem',
                    padding: '0.25rem',
                }}
            >
                <Table size={this.props.user?.denseMode ? 'small' : undefined}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                let props: any = {
                                    key:
                                        Math.random() * new Date().getTime() +
                                        'sub',
                                    style: {
                                        width: index !== 0 ? '500px' : '50px',
                                    },
                                };

                                return (
                                    <TableCell {...props}>
                                        <Typography variant="subtitle2">
                                            {column}
                                        </Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    render() {
        return (
            <Grid container alignContent="flex-start">
                <Backdrop
                    open={
                        this.props.loading !== undefined
                            ? this.props.loading
                            : true
                    }
                    style={{ zIndex: 12001 }}
                >
                    <CircularProgress color="primary" size="5rem" />
                </Backdrop>
                <Filter OnFilter={this.LoadData} />
                {this.GetJobReport()}
            </Grid>
        );
    }
}

function mapStateToProps(state: RootState): JobProps {
    return {
        report: state.reports?.jobs,
        loading: state.reports?.loading,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): JobActionProps {
    return bindActionCreators({ getJobReport }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Job);
