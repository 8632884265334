import "./style.scss"
import Tab from "@material-ui/core/Tab"
import React, { Component } from "react"
import Tabs from "@material-ui/core/Tabs"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import Drawer from "../../../utils/drawer"
import Radio from "@material-ui/core/Radio"
import { FixedSizeList } from "react-window"
import Tooltip from "@material-ui/core/Tooltip"
import CloseIcon from "@material-ui/icons/Close"
import ListItem from "@material-ui/core/ListItem"
import AutoSizer from "react-virtualized-auto-sizer"
import Button from "@material-ui/core/Button/Button"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse/Collapse"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction"

import { connect } from "react-redux"
import { get } from "../../../../plugins/api"
import { RootState } from "../../../../store"
import { bindActionCreators, Dispatch } from "redux"
import { toggleDrawer } from "../../../../store/drawer/actions"
import { createNotification } from "../../../../store/notification/actions"
import { User } from "../../../../store/user/reducer"

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
	style?: any
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{
				width: "100%",
				overflowX: "scroll",
				...other.style,
			}}
		>
			{value === index && children}
		</div>
	)
}

interface FilterProps {
	tab?: any
	user?: User
	OnFilter?: any
	loading?: boolean
	feedTitle?: string
	toggledStates?: any
	markers?: Array<any>
	categoryTitle?: string
	categories?: Array<any>
	lastPublishedBefore?: Date
	selected?: string | number | undefined
	toggleDrawer?: typeof toggleDrawer
	createNotification?: typeof createNotification
}

interface FilterActionProps {
	OnFilter?: any
	toggleDrawer?: typeof toggleDrawer
	createNotification?: typeof createNotification
}

export class Filter extends Component<
	FilterProps,
	FilterProps,
	FilterActionProps
> {
	constructor(props: FilterProps) {
		super(props)

		this.state = {
			loading: true,
			toggledStates: {},
			selected: -1,
			tab: 0,
		}
	}

	componentDidMount() {
		if (
			sessionStorage.getItem("categories") &&
			sessionStorage.getItem("markers")
		) {
			this.setState({
				...this.state,
				loading: false,
				categories: JSON.parse(
					sessionStorage.getItem("categories") ?? JSON.stringify([])
				),
				markers: JSON.parse(
					sessionStorage.getItem("markers") ?? JSON.stringify([])
				),
			})
		} else {
			Promise.all([
				get("/v1/categories"),
				get("/v1/feed/aggregation_channel_markers"),
			])
				.then(([categories, markers]: [Array<any>, Array<any>]) => {
					sessionStorage.setItem(
						"categories",
						JSON.stringify(categories)
					)

					sessionStorage.setItem("markers", JSON.stringify(markers))

					this.setState({
						...this.state,
						markers,
						categories,
						loading: false,
					})
				})
				.catch((e) => {
					this.props.createNotification?.({
						message: e.message ?? e,
						severity: "error",
					})

					// localStorage.setItem('redirect', window.location.pathname);
					// window.location.href = '/v1/auth/google';
				})
		}
	}

	ToggleOptionMenu = (id: number) => {
		let toggledStates = this.state.toggledStates

		if (!toggledStates[id]) {
			toggledStates[id] = true
		} else {
			toggledStates[id] = !toggledStates[id]
		}

		this.setState({
			toggledStates: toggledStates,
		})
	}

	GetCategoryList = (
		categories: Array<any> | null = null,
		paddingMultiplier: number = 1
	) => {
		if (!this.state.categories) {
			return null
		}

		return (categories ?? this.state.categories).map((category: any) => {
			if (!category) {
				return undefined
			}

			let value = `WHERE Cat.lft >= ${category.lft} AND Cat.rgt <= ${category.rgt}`
			return (
				<React.Fragment key={category.id}>
					<ListItem
						dense={this.props.user?.denseMode}
						button
						onClick={() => {
							this.ToggleOptionMenu(category.id)
						}}
						style={{
							paddingTop: "0.125rem",
							paddingBottom: "0.125rem",
							paddingLeft: `${paddingMultiplier * 1}rem`,
						}}
					>
						<FormControlLabel
							value={value}
							checked={this.state.selected === value}
							control={
								<Radio
									name="selected"
									onClick={() => {
										this.OnChange({
											target: {
												name: "selected",
												value: value,
											},
										})
									}}
								/>
							}
							label={category.title}
						/>
						{category.categories && category.categories.length > 0 && (
							<ListItemSecondaryAction>
								<Tooltip
									title={
										<Typography variant="subtitle2">
											Show
											{this.state.toggledStates &&
											this.state.toggledStates[
												category.id
											]
												? " less"
												: " more"}
										</Typography>
									}
								>
									<IconButton
										onClick={() => {
											this.ToggleOptionMenu(category.id)
										}}
									>
										{this.state.toggledStates &&
										this.state.toggledStates[
											category.id
										] ? (
											<ExpandLess fontSize="small" />
										) : (
											<ExpandMore fontSize="small" />
										)}
									</IconButton>
								</Tooltip>
							</ListItemSecondaryAction>
						)}
					</ListItem>
					{category.categories && category.categories.length > 0 && (
						<Collapse
							in={
								this.state.toggledStates &&
								this.state.toggledStates[category.id]
							}
							timeout="auto"
							unmountOnExit
						>
							<List dense={this.props.user?.denseMode}>
								{this.GetCategoryList(
									category.categories,
									paddingMultiplier + 1
								)}
							</List>
						</Collapse>
					)}
				</React.Fragment>
			)
		})
	}

	GetMarkerList = (
		markers: Array<any> | null = null,
		paddingMultiplier: number = 1
	) => {
		if (!this.state.markers) {
			return null
		}

		return (markers ?? this.state.markers).map((marker: any) => {
			if (!marker) {
				return undefined
			}

			let value = `WHERE \`FeedItem\`.\`aggregation_channel_marker\` = "${marker.marker}"`
			return (
				<ListItem
					key={marker.marker}
					dense={this.props.user?.denseMode}
					button
					style={{
						paddingTop: "0.125rem",
						paddingBottom: "0.125rem",
						paddingLeft: `${paddingMultiplier * 1}rem`,
					}}
				>
					<FormControlLabel
						value={value}
						checked={this.state.selected === value}
						control={
							<Radio
								name="selected"
								onClick={() => {
									this.OnChange({
										target: {
											name: "selected",
											value: value,
										},
									})
								}}
							/>
						}
						label={marker.name}
					/>
				</ListItem>
			)
		})
	}

	OnChange = (event: any) => {
		this.setState(
			{
				...this.state,
				[event.target.name]: event.target.value,
			},
			() => this.props.OnFilter?.(this.state)
		)
	}

	row = (props: any) => {
		let value = `WHERE \`FeedItem\`.\`aggregation_channel_marker\` = "${
			props.data[props.index]
		}"`

		return (
			<ListItem
				dense={this.props.user?.denseMode}
				button
				style={props.style}
				key={props.data[props.index]}
			>
				<FormControlLabel
					value={value}
					checked={this.state.selected === value}
					control={
						<Radio
							name="selected"
							onClick={() => {
								this.OnChange({
									target: {
										name: "selected",
										value: value,
									},
								})
							}}
						/>
					}
					label={this.state.markers?.[props.data[props.index]]}
				/>
			</ListItem>
		)
	}

	render() {
		return (
			<>
				<Drawer
					id="category-filter"
					anchor="right"
					variant="persistent"
					style={{
						boxShadow: "0px 2px 10px 0px rgba(0,0,0,0.2)",
						overflow: "hidden",
					}}
				>
					<Grid
						container
						justify={
							!this.state.loading ? "flex-start" : "space-between"
						}
						alignContent={
							!this.state.loading ? "flex-start" : "center"
						}
						style={{
							height: "100%",
							padding: "0 0 0 0",
						}}
					>
						<>
							<div
								style={{
									height: `calc(100% - ${
										!this.state.selected ||
										this.state.selected < 1
											? "32px"
											: "64px"
									})`,
									overflowY: "scroll",
								}}
							>
								<Tabs
									value={this.state.tab}
									onChange={(event, number) => {
										this.setState({
											...this.state,
											tab: number,
										})
									}}
									style={{
										position: "sticky",
										top: 0,
										backgroundColor: "white",
										zIndex: 2,
									}}
								>
									<Tab
										label={
											<Typography variant="subtitle2">
												Categories
											</Typography>
										}
									/>
									<Tab
										label={
											<Typography variant="subtitle2">
												Markers
											</Typography>
										}
									/>
								</Tabs>
								<TabPanel value={this.state.tab} index={0}>
									<Grid item xs={12}>
										{(
											<RadioGroup
												aria-label="Category"
												name="selected"
												value={this.state.selected}
											>
												<List
													dense={
														this.props.user
															?.denseMode
													}
												>
													{this.GetCategoryList()}
												</List>
											</RadioGroup>
										) ?? <CircularProgress />}
									</Grid>
								</TabPanel>
								<TabPanel
									value={this.state.tab}
									index={1}
									style={{
										height: "100%",
										width: "100%",
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											height: "100%",
											width: "100%",
										}}
									>
										<AutoSizer
											style={{
												height: "100%",
												width: "100%",
											}}
										>
											{({ height, width }) => {
												return (
													<RadioGroup
														aria-label="Category"
														name="selected"
														value={
															this.state.selected
														}
													>
														<FixedSizeList
															height={height}
															width={width}
															itemSize={46}
															itemData={Object.keys(
																this.state
																	.markers ??
																	{}
															).sort(
																(
																	a: any,
																	b: any
																) =>
																	this.state.markers?.[
																		a
																	].localeCompare(
																		this
																			.state
																			.markers?.[
																			b
																		]
																	)
															)}
															itemCount={
																Object.keys(
																	this.state
																		.markers ??
																		{}
																).length ?? 0
															}
														>
															{this.row}
														</FixedSizeList>
													</RadioGroup>
												)
											}}
										</AutoSizer>
									</Grid>
								</TabPanel>
							</div>
							{this.state.loading || (
								<Button
									variant="contained"
									className={`button ${
										(!this.state.selected ||
											this.state.selected < 1) &&
										"hidden"
									}`}
									style={{
										bottom: "32px",
									}}
									onClick={() => {
										this.OnChange({
											target: {
												name: "selected",
												value: -1,
											},
										})
									}}
								>
									<Tooltip
										title={
											<Typography variant="subtitle2">
												Clear filters
											</Typography>
										}
									>
										<CloseIcon />
									</Tooltip>
								</Button>
							)}
							<Button
								color="primary"
								variant="contained"
								className="button hidden"
								onClick={() =>
									this.props.toggleDrawer?.("category-filter")
								}
							>
								Close
							</Button>
						</>
					</Grid>
				</Drawer>
			</>
		)
	}
}

function mapStateToProps(state: RootState, props: any): FilterProps {
	return {
		OnFilter: props.OnFilter,
		user: state.user,
	}
}

function mapDispatchToProps(dispatch: Dispatch): FilterActionProps {
	return bindActionCreators({ createNotification, toggleDrawer }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
