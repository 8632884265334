import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface TypeAheadProps {
    name?: string;
    label: string;
    labelKey?: string;
    options: Array<any>;
    selectedKey?: string;
    labelKeys?: Array<string>;
    defaultValue?: Array<any>;
    limit?: number | undefined;
    OnChange?: (value: any) => void;
    varient?: 'standard' | 'filled' | 'outlined' | undefined;
}

interface TypeAheadActionProps {}

export class TypeAhead extends Component<
    TypeAheadProps,
    TypeAheadProps,
    TypeAheadActionProps
> {
    OnChange = (_event: any, value: any) => {
        this.props.OnChange?.({
            target: {
                name: this.props.name,
                value,
            },
        });
    };

    render() {
        return (
            <Autocomplete
                multiple
                limitTags={this.props.limit}
                disableCloseOnSelect
                onChange={this.OnChange}
                options={this.props.options}
                getOptionSelected={(option, value) =>
                    option[this.props.selectedKey ?? 'id'] ===
                    value[this.props.selectedKey ?? 'id']
                }
                getOptionLabel={(option) =>
                    (
                        this.props.labelKeys &&
                        this.props.labelKeys.map((key) => {
                            return option[key];
                        })
                    )?.join('-') ??
                    (this.props.labelKey && option[this.props.labelKey]) ??
                    option.toString()
                }
                value={this.props.defaultValue ?? []}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name={this.props.name}
                        label={this.props.label}
                    />
                )}
            />
        );
    }
}

export default TypeAhead;
