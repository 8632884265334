import { RootState } from ".."
import { ThunkDispatch } from "redux-thunk"
import { get, post } from "../../plugins/api"
import { createNotification } from "../notification/actions"

export enum types {
	UPDATE_COLOR = "UPDATE_COLOR",
	TOGGLE_DRAWER = "TOGGLE_DRAWER",
	TOGGLE_LOADING = "TOGGLE_LOADING",
	GET_JOB_REPORT = "GET_JOB_REPORT",
	GET_ITEM_REPORT = "GET_ITEM_REPORT",
	GET_CATEGORY_REPORT = "GET_CATEGORY_REPORT",
	GET_WATCH_COUNT_REPORT = "GET_WATCH_COUNT_REPORT",
	GET_NOTIFICATION_REPORT = "GET_NOTIFICATION_REPORT",
}

export const changeColor = (color: any) => (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	dispatch({
		type: types.UPDATE_COLOR,
		payload: color,
	})
}

export const toggleDrawer = (id: string | undefined) => (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	dispatch({
		type: types.TOGGLE_DRAWER,
		payload: id,
	})
}

export const toggleLoading = () => (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	dispatch({
		type: types.TOGGLE_LOADING,
	})
}

export const getCategoryReport = (filters: any = null) => (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	dispatch({
		type: types.TOGGLE_LOADING,
	})

	if (!filters) {
		get("/v1/reports/categories")
			.then((report: string) => {
				dispatch({
					type: types.GET_CATEGORY_REPORT,
					payload: report,
				})
				dispatch({
					type: types.TOGGLE_LOADING,
				})
			})
			.catch((message) => {
				dispatch(
					createNotification({
						message,
						severity: "error",
					})
				)

				dispatch({
					type: types.TOGGLE_LOADING,
				})

				localStorage.setItem("redirect", window.location.pathname)
				window.location.href = "/v1/auth/google"
			})
	} else {
		post("/v1/reports/categories", filters)
			.then((report: string) => {
				dispatch({
					type: types.GET_CATEGORY_REPORT,
					payload: report,
				})
				dispatch({
					type: types.TOGGLE_LOADING,
				})
			})
			.catch((message) => {
				dispatch(
					createNotification({
						message,
						severity: "error",
					})
				)

				dispatch({
					type: types.TOGGLE_LOADING,
				})

				localStorage.setItem("redirect", window.location.pathname)
				window.location.href = "/v1/auth/google"
			})
	}
}

export const getJobReport = (filters: any = null) => async (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	try {
		dispatch({
			type: types.TOGGLE_LOADING,
		})
		let results = null

		if (!filters) {
			results = await get("/v1/reports/jobs")
		} else {
			results = await post("/v1/reports/jobs", filters)
		}
		dispatch({
			type: types.GET_JOB_REPORT,
			payload: results,
		})
		dispatch({
			type: types.TOGGLE_LOADING,
		})
	} catch (e) {
		console.error(e)

		dispatch(
			createNotification({
				message: e.toString(),
				severity: "error",
			})
		)
	}
}

export const getItemReport = (filters: any = null) => (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	dispatch({
		type: types.TOGGLE_LOADING,
	})

	try {
		if (!filters) {
			get("/v1/reports/items").then((report: string) => {
				dispatch({
					type: types.GET_ITEM_REPORT,
					payload: report,
				})
			})
			dispatch({
				type: types.TOGGLE_LOADING,
			})
		} else {
			post("/v1/reports/items", filters).then((report: string) => {
				dispatch({
					type: types.GET_ITEM_REPORT,
					payload: report,
				})
			})
			dispatch({
				type: types.TOGGLE_LOADING,
			})
		}
	} catch (message) {
		dispatch(
			createNotification({
				message,
				severity: "error",
			})
		)
		dispatch({
			type: types.TOGGLE_LOADING,
		})
	}
}

export const getNotificationCountReport = (filters: any = null) => async (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	try {
		dispatch({
			type: types.TOGGLE_LOADING,
		})
		let results = null

		if (!filters) {
			results = await get("/v1/reports/notification-count")
		} else {
			results = await post("/v1/reports/notification-count", filters)
		}
		dispatch({
			type: types.GET_NOTIFICATION_REPORT,
			payload: results,
		})
		dispatch({
			type: types.TOGGLE_LOADING,
		})
	} catch (e) {
		console.error(e)

		dispatch(
			createNotification({
				message: e.toString(),
				severity: "error",
			})
		)
	}
}

export const getWatchCountReport = (filters: any = null) => (
	dispatch: ThunkDispatch<RootState, any, any>
) => {
	dispatch({
		type: types.TOGGLE_LOADING,
	})

	try {
		if (!filters) {
			get("/v1/reports/watch-count").then((report: string) => {
				dispatch({
					type: types.GET_WATCH_COUNT_REPORT,
					payload: report,
				})

				dispatch({
					type: types.TOGGLE_LOADING,
				})
			})
		} else {
			post("/v1/reports/watch-count", filters).then((report: string) => {
				dispatch({
					type: types.GET_WATCH_COUNT_REPORT,
					payload: report,
				})

				dispatch({
					type: types.TOGGLE_LOADING,
				})
			})
		}
	} catch (message) {
		dispatch(
			createNotification({
				message,
				severity: "error",
			})
		)
		dispatch({
			type: types.TOGGLE_LOADING,
		})
	}
}
