import { RootState } from ".."
import { Feed } from "./reducer"
import { ThunkDispatch } from "redux-thunk"
import { del, get, post } from "../../plugins/api"
import { createNotification } from "../notification/actions"

export enum types {
	GET_ITEMS = "GET_ITEMS",
	SET_LOADING = "SET_LOADING",
	UPDATE_ITEMS = "UPDATE_ITEMS",
	GET_PROVIDERS = "GET_PROVIDERS",
	TOGGLE_LOADING = "TOGGLE_LOADING",
	TOGGLE_VISIBILITY = "TOGGLE_VISIBILITY",
}

export const getFeedItems = (filters: any = null) => (
	dispatch: ThunkDispatch<RootState, Feed, any>
) => {
	dispatch({
		type: types.SET_LOADING,
		payload: true,
	})

	if (!filters) {
		get("/v1/feed/items")
			.then((items) => {
				dispatch({
					type: types.GET_ITEMS,
					payload: items,
				})

				dispatch({
					type: types.SET_LOADING,
					payload: false,
				})
			})
			.catch((message) => {
				dispatch(
					createNotification({
						message,
						severity: "error",
					})
				)
			})
	} else {
		post("/v1/feed/items", filters)
			.then((items) => {
				dispatch({
					type: types.GET_ITEMS,
					payload: items,
				})

				dispatch({
					type: types.SET_LOADING,
					payload: false,
				})
			})
			.catch((message) => {
				dispatch(
					createNotification({
						message,
						severity: "error",
					})
				)
			})
	}
}

export const updateItems = (field: string, value: any) => async (
	dispatch: ThunkDispatch<RootState, Feed, any>
) => {
	dispatch({
		type: types.UPDATE_ITEMS,
		payload: {
			field,
			value,
		},
	})
}

export const getFeedProviders = () => async (
	dispatch: ThunkDispatch<RootState, Feed, any>
) => {
	get("/v1/feed/providers")
		.then((providers) => {
			dispatch({
				type: types.GET_PROVIDERS,
				payload: providers,
			})
		})
		.catch((message) => {
			dispatch(
				createNotification({
					message,
					severity: "error",
				})
			)
		})
}

export const toggleVisibility = (
	feedItemId: number,
	test: boolean = false
) => async (dispatch: ThunkDispatch<RootState, Feed, any>) => {
	try {
		await del(
			`/v1/feed/items/${feedItemId}${test ? "?test=true" : ""}`
		).then((result) => {
			if (result.status !== undefined && !result.status) {
				dispatch(
					createNotification({
						message: result.message,
						severity: "error",
					})
				)

				return
			}

			dispatch({
				type: types.TOGGLE_VISIBILITY,
				payload: feedItemId,
			})

			dispatch(
				createNotification({
					message: result.message,
					severity: "success",
				})
			)
		})
	} catch (e) {
		console.error(e)

		dispatch(
			createNotification({
				message: "Something went wrong, please try again later.",
				severity: "error",
			})
		)
	}
}
